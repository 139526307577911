import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logoutImg from "../../../assets/images/logout-modal.svg";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { useCategoryFlag } from "../../../pageComponents/categoryComponents/general/hooks";
import { usePeopleManagementAccess } from "../../../pageComponents/peopleManagementComponents/logics/hooks";
import { useVaAccess } from "../../../pageComponents/virtualAccountComponents/common/hooks";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import { eventsTracker } from "../../../universalFunctions/events";
import { AlertAttentionContext } from "../../AlertAttentionContext";
import {
  getUserButtonBooleans,
  getUserRole,
  useGetAuth,
  VA_SIDEBAR_VALUE,
} from "../../AuthContext";
import { TaskContext } from "../../TaskContext/parent";
import { useLayoutSidebars } from "../logics";
import { SidebarComponent } from "./component/parent";

export const Sidebar = ({ onClick = () => {} }) => {
  const { canViewApiDisbursement, canViewWorkflow, canCustomReceipt } =
    getUserButtonBooleans();
  const { t: tSettings } = useTranslation("settings-jack");
  const { isAllowed: canViewPeopleManagement } = usePeopleManagementAccess();
  const { isAllowed: canViewVaPage } = useVaAccess();
  const { totalTask } = useContext(TaskContext);
  const { alertsAttentionsTotalData } = useContext(AlertAttentionContext);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openDropdownButton, setOpenDropdownButton] = useState(null);

  const { user } = useGetAuth();
  const {
    card_business: cardBusiness,
    get_active_bill: billingPayment,
    all_flags = [],
  } = user?.partner || {};
  const { purposes, state: cardBusinessState } = cardBusiness || {};
  const { card_count } = cardBusiness?.detailed_data || {};
  const { physical, virtual, virtual_prepaid } = card_count || {};
  const hasCard = physical + virtual_prepaid + virtual > 0;

  const isFinishedOnboarding = cardBusinessState === "finished";
  // const isOnReviewOnboarding = cardBusinessState === "on_review";
  const isWaitingForCardPayment = !isEmpty(billingPayment);
  // const isRedirectToCardOnboarding =
  //   !isEmpty(purposes) && !isFinishedOnboarding && !isOnReviewOnboarding;
  const { isAdminOrSuperAdmin, isEmployee } = getUserRole();
  const { canViewCards } = getUserButtonBooleans();
  const canViewPlans = isAdminOrSuperAdmin;
  const companyCanIntegration = all_flags?.includes("ODOO_MODULE");
  const canSeeIntegrations = companyCanIntegration && isAdminOrSuperAdmin;
  const canViewDevTools = false;

  const { push } = useRouter();

  const { isUltimate, loadingSubscriptionUsage } = useSubscriptionUsage();

  // const canAccessVaPocketPage = !isEmployee && !loadingSubscriptionUsage;
  const canAccessVaPocketPage = !isEmployee;
  const canAccessPrimaryBankPage = canAccessVaPocketPage && isUltimate;

  const canAccessLedgersPage =
    canAccessVaPocketPage || canAccessPrimaryBankPage;

  const {
    categoryFlags,
    hasSeenCategory,
    addCategoryFlag,
    // hasSeenUserAndTeams,
    userInvitationFlags,
  } = useCategoryFlag();

  const takeOffDate = new Date("2024-04-22");
  const today = new Date();
  const showNewBadgeUserInv = today <= takeOffDate;

  const { isSetting, settingPathnames } = useLayoutSidebars();

  // const { isActiveVA } = useActiveModules();
  const { t } = useTranslation("sidebar/sidebar");

  // TEMP
  const canViewCompanyActivity = !isEmployee;
  const canViewApiPage = canViewApiDisbursement;
  // TEMP

  const danamonLastFourDigit = localStorage.getItem("danamonLastFourDigits");

  const array = isSetting
    ? [
        {
          title: t("Account"),
          array: [
            {
              iconName: "settings_applications",
              text: t("General"),
              value: "/settings-jack",
            },
            {
              iconName: "account_balance",
              text: t("Bank Accounts"),
              value: "/bank-account",
            },
            {
              iconName: "BellOutline",
              text: t("Notifications"),
              value: "/notifications",
            },
          ],
        },
        {
          title: "General",
          array: [
            canSeeIntegrations && {
              iconName: "api2",
              text: t("Accounting Software Integration"),
              value: "/integrations",
            },
            canViewPlans && {
              iconName: "logogram",
              text: t("Plans"),
              value: "/plans",
            },
            true && {
              iconName: "category-outline",
              text: t("Category"),
              value: "/category",
            },
            {
              iconName: "team management",
              text: t("Users & Teams"),
              value: "/people-management",
            },
            canViewWorkflow && {
              iconName: "Workflow",
              text: t("Workflow"),
              value: "/workflow",
            },
            canCustomReceipt && {
              iconName: "customizedReciept",
              text: t("Customize Receipt"),
              value: "/customize-receipt",
            },
            canViewDevTools && {
              iconName: "code-outline",
              text: t("Developer Tools"),
              value: "/dev-tools",
            },
            canViewPlans && {
              iconName: "payment_cycleV2",
              text: t("Payment Cycle"),
              value: "/payment-cycle",
            },
          ],
        },
      ]
    : [
        {
          title: t("For You"),
          array: [
            {
              iconName: "task",
              text: t("Task"),
              number: totalTask,
              isOrange: true,
              value: "/task",
            },
            {
              iconName: "attention",
              text: t("Alerts & Attention"),
              number: alertsAttentionsTotalData,
              value: "/alerts-and-attention",
            },
            {
              iconName: "Transaction",
              text: t("My Activity"),
              value: "/my-activity",
            },
          ],
        },

        {
          title: t("Company"),
          array: [
            {
              iconName: "Dashboard",
              text: t("Dashboard"),
              value: "/dashboard",
            },
            canViewCards && {
              iconName: "Corporate card",
              text: t("Corporate Card"),
              value: "/cards",
              ...(isWaitingForCardPayment && isAdminOrSuperAdmin
                ? {
                    number: "!",
                    isOrange: true,
                    counterStyle: {
                      minWidth: "20px",
                    },
                  }
                : {}),
            },
            // {
            //   iconName: "Transaction",
            //   text: t("Transaction"),
            //   value: "/transactions",
            // },
            // {
            //   iconName: "Transaction",
            //   text: t("Transaction"),
            //   value: "/transactions",
            // },
            // ===============================================
            // COMPANY ACTIVITY DROPDOWN BELOW
            !isEmployee && {
              iconName: "activity",
              text: t("Company Activity"),
              value: canViewApiPage ? "company" : "/company-transaction",
              isDropdownButton: canViewApiPage ? true : false,
              openDropdownButton,
            },
            canViewCompanyActivity &&
              openDropdownButton === "company" && {
                iconName: "",
                // text: VA_SIDEBAR_VALUE,
                text: t("Company Transaction"),
                value: "/company-transaction",
                // value: isActiveVA ? "/virtual-account" : "disabled",
                // tag: isActiveVA ? "new" : "soon",
                // isDisabled: !isActiveVA,
              },
            canViewApiPage &&
              openDropdownButton === "company" && {
                iconName: "",
                text: t("API Transaction"),
                value: "/api-transaction",
              },
            // COMPANY ACTIVITY DROPDOWN ABOVE
            // =============================================
            // LEDGERS DROPDOWN BELOW
            canAccessLedgersPage && {
              iconName: "bank",
              text: t("Accounts & Funds"),
              value: canAccessPrimaryBankPage ? "ledgers" : "/va-pocket",
              isDropdownButton: canAccessPrimaryBankPage ? true : false,
              openDropdownButton,
            },
            canAccessPrimaryBankPage &&
              openDropdownButton === "ledgers" && {
                iconName: "",
                // text: t("Primary Bank Account"),
                text: `Giro Danamon **${danamonLastFourDigit}`,
                value: "/primary-bank-account",
                // tag: "new",
                // customTextComponent: (
                //   <SubTextComponent
                //     text={"Primary Bank Account"}
                //     subText={danamonBalanceString}
                //     divStyle={{
                //       display: "flex",
                //       flexDirection: "column",
                //       gap: 4,
                //     }}
                //     customTextStyle={font12}
                //     customSubStyle={font12}
                //   />
                // ),
                customOptionStyle: { padding: 8, height: "fit-content" },
              },
            canAccessVaPocketPage &&
              openDropdownButton === "ledgers" && {
                iconName: "",
                text: t("VA Pocket"),
                value: "/va-pocket",
                // tag: "soon",
                // customTextComponent: (
                //   <SubTextComponent
                //     text={"VA Pocket"}
                //     subText={vaPocketBalanceString}
                //     divStyle={{
                //       display: "flex",
                //       flexDirection: "column",
                //       gap: 4,
                //     }}
                //     customTextStyle={font12}
                //     customSubStyle={font12}
                //   />
                // ),
                customOptionStyle: { padding: 8, height: "fit-content" },
              },
            // LEDGERS DROPDOWN ABOVE
            // ======================================
            // COLLECTION DROPDOWN BELOW
            canViewVaPage && {
              iconName: "coin",
              // text: t("Collect Payment"),
              text: t("Money In"),
              value: "collection",
              isDropdownButton: true,
              openDropdownButton,
            },
            canViewVaPage &&
              openDropdownButton === "collection" && {
                iconName: "",
                text: VA_SIDEBAR_VALUE,
                value: "/virtual-account",
                // tag: "new",
                // value: isActiveVA ? "/virtual-account" : "disabled",
                // tag: isActiveVA ? "new" : "soon",
                // isDisabled: !isActiveVA,
              },
            canViewVaPage &&
              openDropdownButton === "collection" && {
                iconName: "",
                text: t("Payment Link"),
                value: "disabled",
                tag: "soon",
              },
            canViewVaPage &&
              openDropdownButton === "collection" && {
                iconName: "",
                text: t("Generate Invoice"),
                value: "disabled",
                tag: "soon",
              },
            // COLLECTION DROPDOWN ABOVE
          ],
        },
      ];

  const accountArray = [
    {
      array: [
        // canViewApiDisbursement && {
        //   iconName: "api",
        //   text: "API History",
        //   value: "/histories-api",
        // },
        canSeeIntegrations && {
          iconName: "api2",
          text: t("Accounting Software Integration"),
          value: "/integrations",
        },
        true && {
          iconName: "category-outline",
          text: tSettings("Category"),
          value: "/category",
          // isNew: !hasSeenCategory,
          // onNavigate: () => addCategoryFlag(categoryFlags.hasSeenCategory),
        },
        canViewWorkflow && {
          iconName: "Workflow",
          text: tSettings("Workflow"),
          value: "/workflow",
        },
        {
          iconName: "people-outline",
          text: t("Users & Teams"),
          value: "/people-management",
          isNew: showNewBadgeUserInv,
          onNavigate: () =>
            addCategoryFlag(userInvitationFlags.hasSeenUserAndTeams),
        },
        canViewPlans && {
          iconName: "logogram",
          text: tSettings("Plans"),
          value: "/plans",
        },
        {
          iconName: "settings-outline",
          text: tSettings("Settings"),
          value: "/settings-jack",
        },
      ],
    },
    {
      array: [
        { iconName: "log-out", text: tSettings("Log Out"), value: "logout" },
      ],
    },
  ];

  const { unauthorize } = useGetAuth();

  const isClosePathnames = ["/histories-api"];

  // const noRecordPathname =

  const handleClick = (value) => {
    const isLogout = value == "logout";
    const isCollection = value == "collection";
    const isDisabled = value == "disabled";
    const isNotifSettings = value == "/notifications";
    const isLedgers = value === "ledgers";
    const isCompany = value === "company";
    const closeDropdown = value === "closeDropdown";

    if (isDisabled) return null;

    if (isLogout) {
      eventsTracker("logout");
      return setOpenConfirmationModal(true);
    }

    if (closeDropdown) return setOpenDropdownButton(null);

    if (isLedgers) return setOpenDropdownButton("ledgers");

    if (isCollection) return setOpenDropdownButton("collection");

    if (isCompany) return setOpenDropdownButton("company");

    if (isNotifSettings) {
      eventsTracker("click_notif_settings", { page_title: "settings" });
    }

    const toPush = (value) => {
      eventsTracker("sidebar", { url: value });
      push(value);
    };

    if (isClosePathnames.includes(value)) return toPush(value);

    // setsLastObj
    ![...settingPathnames, isClosePathnames].includes(value) && onClick(value);
    // setsLastObj

    toPush(value);
  };
  const handleLogout = () => {
    unauthorize();
    setTimeout(() => push("/login"), 400);
  };

  return (
    <>
      <SidebarComponent
        array={array}
        onClick={handleClick}
        accountArray={accountArray}
      />
      <ConfirmationModalJack
        modal={openConfirmationModal}
        isCentered={true}
        img={logoutImg}
        title={tSettings("Log out from Jack account?")}
        text=""
        buttonTextLeft={tSettings("Cancel ")}
        buttonTextRight={tSettings("Log Out ")}
        toggle={() => setOpenConfirmationModal(false)}
        onClick={() => handleLogout()}
      />
    </>
  );
};
