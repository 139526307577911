import { RecipientCardSmall } from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/trf-detail/components";
import { Trans, useTranslation } from "react-i18next";
import { jackColors } from "../../../assets/colors";
import CrossborderAvatar from "../../../assets/images/crossborder-single-avatar.svg";
import { Avatar } from "../../../components/Avatar";
import { GothamRegular } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useAlertDecider } from "../../../pageComponents/crossBorderSingleConfirmation";
import { billPaymentDetailDecider } from "../../../pageComponents/invoiceComponents/data/formatter";
import JackTransferIcon from "../../../pageComponents/jackTransferComponents/components/JackTransferIcon";
import {
  ArrayAfterReleaseBanking,
  ArrayReleaseDanamon,
} from "./DanamonReleasePayment/arraySummary";
import { MaskingBankingPayload } from "./DanamonReleasePayment/logic";
import { useLanguage } from "public/locales/translationFunctions";
import { ArrayRejectFunction } from "./arrayReject";

export const useCrossBorderSingleWorkflowApproverLogics = (transaction) => {
  const { user } = useGetAuth();
  const { approval_tasks, workflow_approval_tasks } = transaction || {};

  const currentTaskId = (
    workflow_approval_tasks ||
    approval_tasks ||
    []
  ).filter(
    ({ user_id, state, current_order }) =>
      state == "requested" && user_id == user?.id && current_order
  )[0]?.id;

  const releaserTaskId = (
    workflow_approval_tasks ||
    approval_tasks ||
    []
  ).filter(
    ({ is_last_order, approved_at }) => is_last_order && !!approved_at
  )[0]?.id;

  const canApproveDecider = () => {
    if (!approval_tasks) return true;

    approval_tasks.sort((prev, curr) => {
      const roleOrderDecider = (item) => item.role.split("-")[2];

      const prevRoleOrder = roleOrderDecider(prev);
      const currRoleOrder = roleOrderDecider(curr);

      if (Number(prevRoleOrder) > Number(currRoleOrder)) return 1;
      if (Number(prevRoleOrder) < Number(currRoleOrder)) return -1;
      return 0;
    });

    // find currentOrder

    // create orderObj
    const orderObj = {};

    approval_tasks.forEach((approval_task) => {
      const { role } = approval_task || {};
      if (!orderObj[role]) return (orderObj[role] = [approval_task]);
      orderObj[role].push(approval_task);
    });
    // create orderObj

    let currentOrder = "";

    const orderObjKeys = Object.keys(orderObj);

    orderObjKeys.forEach((key) => {
      if (currentOrder) return;

      const array = orderObj[key];

      const requestedArr = [];

      array.forEach((approval_task) => {
        if (approval_task.state !== "requested") return;
        requestedArr.push(approval_task.state);
      });

      if (requestedArr.length !== array.length) return;

      currentOrder = key;
    });
    // find currentOrder

    const currentApprovalTasks = approval_tasks.filter(
      ({ role }) => role === currentOrder
    );

    const foundApprover = currentApprovalTasks.find(
      ({ user_id }) => user_id === user?.id
    );

    if (foundApprover) return true;
    return false;
  };

  const canApprove = currentTaskId && canApproveDecider();

  // due to async func, there will be delay on the approval process. during this time cancel button should be hidden
  const case1 = (approval_tasks || workflow_approval_tasks)?.some(
    ({ is_last_order, state }) => is_last_order && state === "approved"
  );

  const case2 = (approval_tasks || workflow_approval_tasks)?.some(
    ({ is_on_process }) => !!is_on_process
  );

  const hideCancelButton = case1 || case2;

  return {
    canApprove,
    taskId: currentTaskId,
    releaserTaskId,
    hideCancelButton,
  };
};

export const getBeneficiaryInternational = (data) => {
  const {
    receiving_registered_name,
    receiving_first_name,
    receiving_last_name,
    registered_name,
    firstname,
    lastname,
    bank_name,
    bank_account_number,
    recipient_email,
    iban,
    swift_bic_code,
    receiving_business_name,
  } = data?.beneficiary || {};

  const beneficiaryName =
    receiving_registered_name ||
    registered_name ||
    receiving_business_name ||
    `${receiving_first_name || firstname || ""} ${
      receiving_last_name || lastname || ""
    }` ||
    recipient_email;

  const beneficiaryBank =
    bank_account_number || `${iban} (Iban)` || `${swift_bic_code} (Swift Code)`;

  return { beneficiaryName, beneficiaryBank };
};

export const useDataInternationalSingle = ({ data, global_title }) => {
  const { t } = useTranslation("international/details");
  const { isBahasa } = useLanguage();
  const {
    created_at,
    beneficiary,
    reference_id,
    destination_country,
    destination_currency,
    destination_amount,
    source_currency: source_currencyRaw,
    sent_amount,
    bank_fee,
    fee,
    rate,
    state,
    status,
    total_trx_amount,
    payment_channel,
    payment_method,
    approved_by_id,
    user,
    vat,
    total_fee,
    total_amount,
    total_batch_amount,
  } = data || {};

  const { name: nameDrafter } = user || {};
  const { bank_name } = beneficiary || {};

  const { payment_method: method, payment_channel: channel } =
    MaskingBankingPayload(data);
  const { stateAfterPayments } = ArrayAfterReleaseBanking({
    data,
  });

  //  =========================================

  const source_currency = source_currencyRaw || "IDR";

  const { beneficiaryName, beneficiaryBank } =
    getBeneficiaryInternational(data);
  const transfezFee = Number(fee) - Number(bank_fee);
  const deliveryMethod = useAlertDecider({ data: payment_channel });
  const statusForLabel = state || status;

  //  =========================================

  const headerData = {
    title: global_title,
    value: `IDR ${formatCurrencyNoDecimal(
      total_trx_amount || total_batch_amount || total_amount
    )}`,
    icon: <img src={CrossborderAvatar} />,
    status: statusForLabel,
    details: `${t("Created by")} ${nameDrafter}`,
  };

  const arrayDetails = [
    { title: t("Created at"), value: customDateFormatter(created_at || "") },
    // { title: t("Transfer service (international)"), value: channel },
    ...(stateAfterPayments
      ? [
          {
            title: t("Source of account"),
            value: method,
          },
        ]
      : []),
    {
      title: t("Trasaction ID"),
      value: reference_id,
    },
    {
      title: t("Recipient"),
      value: (
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-2" style={{ maxWidth: 200 }}>
            <GothamRegular
              className="font12"
              style={{
                wordBreak: "break-word",
              }}
            >
              {beneficiaryName}
            </GothamRegular>
            <GothamRegular
              className="font10"
              style={{ color: jackColors.neutral700 }}
            >
              {bank_name} {bank_name && beneficiaryBank && "-"}{" "}
              {beneficiaryBank}
            </GothamRegular>
          </div>
          <div style={{ width: 24 }}>
            <Avatar name={beneficiaryName} size="small" />
          </div>
        </div>
      ),
    },
  ];

  const arrayDetailsAmount = [
    {
      title: t("You pay"),
      value: `${source_currency} ${formatCurrencyNoDecimal(+total_trx_amount)}`,
    },
    {
      title: t("Transfer service (international)"),
      value: deliveryMethod,
    },
    {
      title: t("Handling fee"),
      value: `${source_currency} ${formatCurrencyNoDecimal(transfezFee)}`,
      littleDot: true,
    },
    {
      title: t("Bank fee"),
      value: `${source_currency} ${formatCurrencyNoDecimal(bank_fee)}`,
      littleDot: true,
    },
    ...(vat
      ? [
          {
            title: t("VAT on fees"),
            value: `${source_currency} ${formatCurrencyNoDecimal(vat)}`,
            littleDot: true,
          },
        ]
      : [{ dummy: true }]),
    {
      spacerNotFull: true,
      styleSpacer: { borderTop: `1px dashed ${jackColors.neutral500}` },
    },
    {
      title: t("Total fee"),
      value: `${source_currency} ${formatCurrencyNoDecimal(total_fee || fee)}`,
      customIcon: "minus-circle",
      fillCustomIcon: jackColors.neutral600,
    },
    {
      title: t("Exchange rate"),
      value: `${destination_currency} 1 = ${source_currency} ${formatCurrency(
        rate
      )}`,
      customIcon: "divide-circle",
      fillCustomIcon: jackColors.neutral600,
    },
    {
      spacer: true,
    },
    {
      title: t("Recipient gets"),
      value: `${destination_currency} ${formatCurrency(destination_amount)}`,
    },
  ];

  return { arrayDetails, headerData, arrayDetailsAmount };
};

export const useDetailsExtractor = ({
  transactionDetails,
  isReleaser,
  canApprove,
  isUserJackUltimate,
  propsTransferService,
  loadingCalculateFee,
  isSchedulePayment,
}) => {
  const { t } = useTranslation("common");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const { isBahasa } = useLanguage();
  const isUserReleaser = isReleaser && canApprove;
  const {
    type,
    recipient_name,
    name,
    amount,
    total_trx_amount,
    destination_business,
    local_transactions = [],
  } = transactionDetails || {};

  const { name: businessName } = destination_business || {};
  const { beneficiaryName } = getBeneficiaryInternational(transactionDetails);

  const {
    arrayLocal,
    arrayPayroll,
    arrayInternational,
    arrayBillPayment,
    arrayReimbursement,
    totalAmountLocal,
    totalAmountPayroll,
    totalAmountBillPayment,
    totalAmountReimbursement,
    arrayBalanceTransfer,
    arrayWithdrawal,
  } = ArrayReleaseDanamon({
    transaction: transactionDetails,
    propsTransferService,
  });

  const {
    arrayRejectLocal,
    arrayRejectInternational,
    arrayRejectPayroll,
    arrayRejectPayInvoice,
  } = ArrayRejectFunction({
    transaction: transactionDetails,
    propsTransferService,
  });

  // single/quick local transfer"
  const isSingleTransfer = local_transactions.length === 1;
  const singleTransferRecipient =
    local_transactions?.[0]?.local_recipient || {};
  const singleTransferRecipientName = singleTransferRecipient?.name || "";

  const TotalPaymentRawDecider = (total_trx) =>
    isSchedulePayment ? 0 : total_trx;

  const defaultValue = {
    iconName: "invoice_payment",
    title: "default title",
    recipient_name: "John Wick",
    customHeader: true,
    totalPaymentRaw: 100000,
    array: [
      { spacer: true },
      { title: "left", value: "right" },
      { spacer: true },
      { title: "left", value: "right", customIcon: "plus_circle" },
    ],
  };

  const detailLibrary = {
    INVOICE: {
      customHeader: true,
      iconName: "invoice_payment",
      title: "Bill Payment",
      recipient_name: recipient_name,
      totalPaymentRaw: TotalPaymentRawDecider(totalAmountBillPayment),
      array: billPaymentDetailDecider(
        transactionDetails,
        isReleaser,
        false,
        arrayBillPayment
      ),
      originator: "InvoiceTransaction",
      loading: loadingCalculateFee,
    },
    INVOICE_PAYMENT: {
      customHeader: true,
      iconName: "invoice_payment",
      title: tLocalTransfer("Bill Payment"),
      recipient_name: recipient_name,
      totalPaymentRaw: TotalPaymentRawDecider(totalAmountBillPayment),
      array: billPaymentDetailDecider(
        transactionDetails,
        isReleaser,
        false,
        arrayBillPayment
      ),
      arrayReject: arrayRejectPayInvoice,
      originator: "InvoiceTransaction",
      loading: loadingCalculateFee,
    },
    REIMBURSEMENT: {
      customHeader: true,
      iconName: "reimbursement",
      title: "Reimbursement",
      totalPaymentRaw: TotalPaymentRawDecider(totalAmountReimbursement),
      recipient_name: name,
      array: arrayReimbursement,
      originator: "Reimbursement",
      loading: loadingCalculateFee,
    },
    LOCAL_TRANSFER: {
      customHeader: true,
      iconName: "send-money",
      title: tLocalTransfer("Local Transfer"),
      customDesc: isSingleTransfer ? (
        `Transfer to : ${singleTransferRecipientName}`
      ) : (
        <Trans
          i18nKey={tLocalTransfer(`dynamic.dynamic_text_13`, { dupNum: name })}
        />
      ),
      totalPaymentRaw: TotalPaymentRawDecider(totalAmountLocal),
      recipient_name: name,
      array: arrayLocal,
      arrayReject: arrayRejectLocal,
      originator: "LocalTransfer",
      loading: loadingCalculateFee,
    },
    PAYROLL: {
      customHeader: true,
      iconName: "payroll",
      title: "Payroll",
      customDesc: `Payroll: ${name}`,
      totalPaymentRaw: TotalPaymentRawDecider(totalAmountPayroll),
      recipient_name: name,
      array: arrayPayroll,
      arrayReject: arrayRejectPayroll,
      originator: "Payroll",
      loading: loadingCalculateFee,
    },
    INTERNATIONAL_TRANSFER: {
      customHeader: true,
      customIcon: <img src={CrossborderAvatar} className="mr-2" />,
      title: t("International Transfer"),
      customDesc: `${t("Transfer to")} ${beneficiaryName}`,
      totalPaymentRaw: +total_trx_amount,
      recipient_name: name,
      array: arrayInternational,
      arrayReject: arrayRejectInternational,
    },
    BALANCE_TRANSFER: {
      customHeader: true,
      customIcon: <JackTransferIcon style={{ marginRight: "8px" }} />,
      title: isBahasa ? "Transfer antar Jack" : "Transfer Between Jack",
      totalPaymentRaw: TotalPaymentRawDecider(+amount || 0),
      customDesc: `${isBahasa ? "" : "To"} ${businessName || "-"}`,
      array: arrayBalanceTransfer,
    },
    WITHDRAW: {
      customHeader: true,
      customIcon: (
        <JackTransferIcon name="withdraw" style={{ marginRight: "8px" }} />
      ),
      title: "Withdrawal",
      totalPaymentRaw: TotalPaymentRawDecider(
        Number(transactionDetails?.amount || 0)
      ),
      customDesc: "Main balance withdrawal",
      array: arrayWithdrawal,
    },
  };

  const upperCasedType = type?.toUpperCase();
  const data = upperCasedType ? detailLibrary[upperCasedType] : defaultValue;
  return data;
};
