import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import {
  useMissingReceipt,
  useWaitingForPayment,
} from "../../pageComponents/taskComponents/logics/hooks";
import { fetch, useMutation } from "../../tools/api";
import { useGetAuth } from "../AuthContext";

const contextTemplate = {
  loading: false,
  totalTask: 0,
  data: [],
  is_last_order: [],
  is_not_last_order: [],
  refetch: async () => {},
};

export const TaskContext = React.createContext(contextTemplate);

const TaskProvider = ({ children }) => {
  const [helperLoading, setHelperLoading] = useState(false);
  const { user } = useGetAuth();
  const { pathname } = useRouter();

  const {
    data: totalIds,
    loading,
    refetch,
  } = fetch({
    woInit: true,
    url: "/approval_tasks/requested_count?q[originator_type_not_eq]=WalletTransaction",
    defaultValue: 0,
    noToaster: true,
    afterSuccess: () => setHelperLoading(false),
    formatter: (res) => res.total_count || 0,
  });

  const { totalMissingReceipts, loadingMissingReceipts, seeLess } =
    useMissingReceipt({
      isContext: true,
    });

  const {
    data: invRequests,
    loading: loadingInvRequest,
    refetch: fetchInvitation,
  } = fetch({
    url: "/business_users/invitation_requests?per_page=1",
    woInit: true,
  });

  const { totalData } = useWaitingForPayment({
    isContext: true,
  });
  const { total_data: totalInvRequests = 0 } = invRequests || {};

  const {
    mutation,
    loading: loadingMutation,
    result: eddCount,
  } = useMutation({
    url: "/activity_search",
    method: "post",
    defaultValue: 0,
    // resultFormatter: (res, prev) => transactionsFormatter(res, prev, false),
    resultFormatter: (res) => {
      const count = res?.data?.count;
      return count;
    },
  });

  const payloadMutation = {
    keyword: "",
    filters: {
      page: 1,
      per_page: 1,
      category_not_eq: [
        "local_single",
        "business_billing_statement",
        "allocation_request",
        "return_request",
        "wallet_return",
        "wallet_allocation",
        "wallet_allocation_refund",
        "incoming_payment",
      ],
      status_not_eq: ["draft"],
      category: ["single_cross_border"],
      "details.need_edd": true,
      "details.edd_document_status": "created",
    },
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      setHelperLoading(true);
      const timeout = setTimeout(() => {
        refetch();
        seeLess();
        fetchInvitation();
        mutation(payloadMutation);
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [!isEmpty(user), pathname]);

  const totalTask =
    totalIds +
    (totalMissingReceipts ?? 0) +
    totalInvRequests +
    (eddCount ?? 0) +
    (totalData ?? 0);

  const taskData = {
    loading:
      loading ||
      loadingMissingReceipts ||
      loadingInvRequest ||
      helperLoading ||
      loadingMutation,
    refetch,
    invRequests,
    totalTask,
  };

  const value = {
    ...taskData,
  };

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export default TaskProvider;
