import { useEffect, useState } from "react";
import { useModalHook } from "../../../../components/Modals";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useMutation } from "../../../../tools/api";
import { TransferServiceModal } from "../transferServiceModal/parent";
import { useBooleanDanamonProduct } from "./boolean";
import {
  useArraySourceOfPayment,
  CardPayment,
  SourceOfPaymentModal,
} from "./sourceOfPayment";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

export const SourceOfPaymentParent = ({
  data, // optional, object (single) or array (batch)
  detailData, // payload => { totalPaymentRaw }
  isMoveBalance,
  isChoosingSchedule,
  ...propsTransferService // payload => { activeTransferBalance, setActiveTransferBalance }
}) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  // dataDetails => { totalPaymentRaw }

  // props banking
  const { activeTransferBalance } = propsTransferService || {};
  const balance = activeTransferBalance?.balance;
  const title = isMoveBalance
    ? "Balance Destination"
    : tLocalTransfer("Pay With");
  const emptyDesc = isMoveBalance
    ? "Select balance destination"
    : tLocalTransfer("Select a payment method");

  const { totalPaymentRaw } = detailData ?? {};
  const remainingBalance = +balance - totalPaymentRaw; // totalPaymentRaw;
  const isInsufficientWithFee = remainingBalance < 0;

  // toggle
  const { isOpen: isOpenSourcePayment, toggle: toggleSourcePayment } =
    useModalHook();

  const {
    array: arrayOption,
    loadingBalance,
    refetchBalance,
  } = useArraySourceOfPayment({
    data,
    detailData,
    propsTransferService,
    tLocalTransfer: tLocalTransfer,
  });

  const { isBankTransfer, isSelectedBalance, isDirectPayment } =
    useBooleanDanamonProduct(activeTransferBalance?.value);

  const SourcePaymentDescDecider = () => {
    if (isSelectedBalance) {
      if (isInsufficientWithFee && !isMoveBalance)
        return `${activeTransferBalance?.title} : Insufficient`;
      if (isBankTransfer) return activeTransferBalance?.title;
      if (isDirectPayment)
        return `${activeTransferBalance?.desc} - ${activeTransferBalance?.item?.bankName}`;
      if (isBankTransfer) return activeTransferBalance?.title;
      return `${activeTransferBalance?.title}: IDR ${formatCurrencyNoDecimal(
        activeTransferBalance?.balance
      )}`;
    }
  };

  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      <div id="task-first">
        <CardPayment
          id="task-first-button"
          title={title}
          emptyDesc={emptyDesc}
          desc={SourcePaymentDescDecider()}
          icon={activeTransferBalance?.icon}
          styleContainer={{
            marginBottom: 32,
            position: "relative",
            zIndex: 0,
          }}
          onClick={toggleSourcePayment}
          isMoveBalance={isMoveBalance}
          isWarningDesc={isInsufficientWithFee}
        />
      </div>
      <SourceOfPaymentModal
        isChoosingScheduleProps={isChoosingSchedule}
        isMoveBalance={isMoveBalance}
        data={data}
        totalAmount={totalPaymentRaw}
        array={arrayOption}
        loadingBalance={loadingBalance}
        refetchBalance={refetchBalance}
        toggle={toggleSourcePayment}
        isOpen={isOpenSourcePayment}
        t={tLocalTransfer}
        {...propsTransferService}
      />
    </div>
  );
};

export const TransferServiceParent = ({
  data,
  isMultiRelease = false,
  customBanner = false,
  transferServiceRules,
  setTransferServiceRules = () => {},
  ...propsTransferService
}) => {
  // Props Banking
  const { taskId, activeTransferService } = propsTransferService || {};
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  // Modal
  const { isOpen: isOpenTransferService, toggle: toggleTransferService } =
    useModalHook();

  const {
    replace,
    query: { showTransferService: _, ...query },
  } = useRouter();

  const { guidedTour } = query;

  // Fetch Option Rules
  const { mutation, loading } = useMutation({
    url: "/danamon_transactions/transfer_services",
    afterSuccess: (res) => {
      setTransferServiceRules(res?.data);
      if (!guidedTour) return;
      replace({ query: { ...query, showTransferService: true } });
    },
  });

  useEffect(() => {
    !!taskId?.length && mutation({ ids: taskId });
  }, []);

  const { t } = useTranslation("local-transfer/local-transfer");
  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      {!loading && (
        <CardPayment
          id="task-third-button"
          data={data}
          title={t("Transfer Service (Domestic)")}
          emptyDesc={t("Select Transfer Service")}
          emptyIcon="globe-outline"
          desc={activeTransferService?.title}
          icon={activeTransferService?.icon}
          onClick={toggleTransferService}
          customBanner={customBanner}
          {...propsTransferService}
        />
      )}
      <TransferServiceModal
        data={data}
        isMultiRelease={isMultiRelease}
        optionRules={transferServiceRules}
        toggle={toggleTransferService}
        isOpen={isOpenTransferService}
        {...propsTransferService}
      />
    </div>
  );
};
