import React, { useEffect, useState } from "react";
import { colors, jackColors } from "../../../../assets/colors";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../../components/Text";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import { BackButton, SpanMedium } from "../transferServiceModal/components";
import BannerSourceImg from "../../../../assets/jackImages/banner-source-payment.svg";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { TopUpModal, useModalHook } from "../../../../components/Modals";
import ImgSkipModal from "../../../../assets/images/ignore-validation.svg";
import ImgEmptyPocket from "../../../../assets/jackImages/empty-pocket.png";
import { useBooleanDanamonProduct, useBooleanMultipleProduct } from "./boolean";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { chooseSchedulePaymentDecider, useMyCompanyBalance } from "./logic";
import Skeleton from "react-loading-skeleton";
import { SmartActivityModal } from "../../parent";
import { Banner } from "../../../../components/Banner";
import { eventsTracker } from "../../../../universalFunctions/events";
import CustomTooltip from "../../../../components/Tooltip";
import AddFundsModal from "modals/smartActivityModal/jackDanamon/addFunds";
import {
  DANAMON_BALANCE_VALUE,
  MAIN_BALANCE_VALUE,
} from "pageComponents/jackTransferComponents/components/SourceOfFundsSelection";
import { Trans, useTranslation } from "react-i18next";
import bankIconDecider from "constants/bankIconDecider";
import dynamic from "next/dynamic";
import { title } from "process";
import BottomContainer from "pageComponents/cardPageComponents/components/CardModal/components/BottomContainer";
import zIndex from "@material-ui/core/styles/zIndex";
import { fetch } from "tools/api";

export const useArraySourceOfPayment = ({
  data,
  detailData,
  propsTransferService,
  tLocalTransfer = (item) => item,
}) => {
  const { isUserJackUltimate, schedulePaymentState } =
    propsTransferService || {};
  const isChoosingSchedule = chooseSchedulePaymentDecider(schedulePaymentState);

  const {
    isDanamonFailedFetch,
    balanceDanamon,
    balancePocket,
    refetchBalance,
    loadingBalance,
    isNullBalanceDanamon,
    isNullBalancePocket,
    lastFourDigitsDanamonAccount,
  } = useMyCompanyBalance();

  // single release
  const { type } = data || {};
  const { includeWithdraw, includeCard } = useBooleanMultipleProduct(
    data || []
  );
  const { isInternationalTransfer, isWithdraw, isCard } =
    useBooleanDanamonProduct(type);

  const withdraw = isWithdraw || includeWithdraw;
  const card = isCard || includeCard;

  const { totalPaymentRaw } = detailData || {};

  const objDanamonAccount = {
    type: "Account",
    icon: "bank",
    title: `Giro Danamon ${
      lastFourDigitsDanamonAccount ? `** ${lastFourDigitsDanamonAccount}` : ""
    }`,
    value: "danamon_balance",

    balance: +balanceDanamon,
    isNotEnoughBalance: +balanceDanamon < +totalPaymentRaw,
    isDanamonFailedFetch,
    isDisabled:
      !isUserJackUltimate ||
      isNullBalanceDanamon ||
      withdraw ||
      isChoosingSchedule,
    desc: !(
      !isUserJackUltimate ||
      isNullBalanceDanamon ||
      withdraw ||
      isChoosingSchedule
    ) && (
      <Trans
        i18nKey={tLocalTransfer(
          +balanceDanamon < +totalPaymentRaw
            ? "dynamic.dynamic_text_16"
            : "dynamic.dynamic_text_14",
          {
            dupNum: formatCurrencyNoDecimal(+balanceDanamon),
          }
        )}
      />
    ),
    isDanamonFailedFetch,
    refetchBalance: () => refetchBalance(),
    loading: loadingBalance,
    titleTooltip: isUserJackUltimate && (
      <CustomTooltip
        text={tLocalTransfer(
          "This is the available balance that you can use for transaction."
        )}
        popperClassName="white-arrow"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          backgroundColor: "white",
          color: jackColors.neutral800,
          maxWidth: 280,
          textAlign: "left",
        }}
      >
        <JackIcons
          name="info"
          fill={jackColors.neutral700}
          style={{ width: 14, height: 14, marginLeft: 4 }}
        />
      </CustomTooltip>
    ),
  };

  const objVaPocket = {
    type: "Account",
    icon: "account_balance_wallet",
    title: isUserJackUltimate
      ? tLocalTransfer("VA Pocket")
      : tLocalTransfer("Main Balance"),
    value: "indonesian_wallet",
    desc: (
      <Trans
        i18nKey={tLocalTransfer(
          +balancePocket < +totalPaymentRaw || isNullBalancePocket
            ? "dynamic.dynamic_text_16"
            : "dynamic.dynamic_text_14",
          {
            dupNum: formatCurrencyNoDecimal(+balancePocket),
          }
        )}
      />
    ),
    balance: +balancePocket,
    isNotEnoughBalance:
      +balancePocket < +totalPaymentRaw || isNullBalancePocket, //+totalPaymentRaw || isNullBalancePocket,
    loading: loadingBalance,
  };
  const objBankTransfer = {
    type: "bank_transfer",
    icon: "directTransfer",
    title: tLocalTransfer("Manual Transfer (receipt required)"), // with space
    value: "bank_transfer",
    // desc: tLocalTransfer("Manual Verification"),
    isDisabled: isChoosingSchedule,
  };
  const objDirectPayment = {
    type: "direct_payment",
    icon: "virtualAccount",
    value: "va_transfer",
    title: "Virtual Account",
    desc: tLocalTransfer("Virtual Account"),
    isDisabled: isChoosingSchedule,
  };

  const arrayMainSource = [objDanamonAccount, objVaPocket, objDirectPayment];
  const arrayInternational = [
    ...arrayMainSource,
    objDirectPayment,
    objBankTransfer,
  ];

  const array = isInternationalTransfer ? arrayInternational : arrayMainSource;

  return {
    array,
    objDanamonAccount,
    objVaPocket,
    loadingBalance,
    refetchBalance,
    isInternationalTransfer,
  };
};

export const SourceOfPaymentModal = ({
  data, // object (single) or array (batch)
  isOpen,
  array,
  loadingBalance: loading,
  toggle,
  totalAmount,
  isUserJackUltimate,
  activeTransferService,
  activeTransferBalance,
  setActiveTransferBalance,
  refetchBalance,
  setActiveService,
  isMoveBalance,
  isChoosingScheduleProps,
  t = (item) => item,
  schedulePaymentState,
}) => {
  const [listBankVA, setListBankVa] = useState([]);

  const { data: listBank } = fetch({
    url: "/collection_va_banks",
    afterSuccess: (res) => {
      const { data } = res || {};
      const formattedBanks = data
        .filter((item) => item.is_va_transfer)
        .map((item) => ({
          bankName: item.name,
        }));

      setListBankVa(formattedBanks);
    },
  });

  const [temporarySelected, setTemporarySelected] = useState({});
  const isVAPocket = temporarySelected?.value == "indonesian_wallet";
  const { isInternationalTransfer } = useArraySourceOfPayment({
    data,
  });
  const { type } = data || {};
  const isChoosingSchedule = chooseSchedulePaymentDecider(schedulePaymentState);
  const vaPocketObj = array?.find(({ value }) => value === "indonesian_wallet");

  // Modal Change Balance
  const { toToggle, toListener } = useSmoothRightModal();
  const {
    isOpen: isOpenChangeBalanceToVaPocket,
    toggle: toggleChangeBalanceToVaPocket,
  } = useModalHook();
  const {
    isOpen: isOpenVAPocketUnavailableModal,
    toggle: toggleVAPocketUnavailableModal,
  } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const { isOpen: isOpenTopUpDanamon, toggle: toggleTopUpModalDanamon } =
    useModalHook();
  const { isOpen: isOpenEmptyPocket, toggle: toggleEmptyPocket } =
    useModalHook();
  const { isOpen: isOpenAddFunds, toggle: toggleAddFunds } = useModalHook();

  const { includeWithdraw } = useBooleanMultipleProduct(data || []);
  const { isWithdraw, isCard, isLocalTransfer } =
    useBooleanDanamonProduct(type);

  const VAPocketUnavailableModal = dynamic(() =>
    import("../VAPocketUnavailableModal/VAPocketUnavailable")
  );

  const productDisabledPrimaryAccount = includeWithdraw || isWithdraw;

  const isDisabledPrimaryAccount =
    isUserJackUltimate && productDisabledPrimaryAccount;

  const isShowBannerinsufficient =
    isUserJackUltimate &&
    !loading &&
    array.some(({ balance }) => balance < totalAmount);
  const isAlreadyChooseService = !!activeTransferService.value;

  const handleActiveTransferBalance = (
    item,
    bypass = false,
    options = { isNoToggle: false }
  ) => {
    const { isNoToggle } = options ?? {};
    const { value, isNotEnoughBalance } = item || {};
    const isCollectionPocket = value == "indonesian_wallet";
    const isDanamonBalanceTemporary =
      temporarySelected?.value == "danamon_balance";

    setTemporarySelected(item);

    if (isNotEnoughBalance && !isMoveBalance) {
      eventsTracker("open_modal_low_balance_va_pocket");
      return toggleAddFunds();
    }

    if (
      isAlreadyChooseService &&
      isDanamonBalanceTemporary &&
      isCollectionPocket &&
      !bypass
    ) {
      return toggleChangeBalanceToVaPocket();
    }

    eventsTracker("choose_source_balance_release_payment");
    setActiveTransferBalance(item);
    if (isNoToggle) return;
    toToggle();
  };

  useEffect(() => {
    if (isChoosingSchedule) return setActiveTransferBalance(vaPocketObj);
    return setActiveTransferBalance({});
  }, [isChoosingSchedule]);

  useEffect(() => {
    if (
      isUserJackUltimate &&
      data?.type === "balance_transfer" &&
      !!data?.source_account &&
      data?.source_account?.length > 0 &&
      array?.length > 0 &&
      array[0]?.balance > 0
    ) {
      if (data?.source_account === DANAMON_BALANCE_VALUE) {
        handleActiveTransferBalance(
          array?.find(({ value }) => value === "danamon_balance") ?? {},
          false,
          { isNoToggle: true }
        );
        return;
      }
      if (data?.source_account === MAIN_BALANCE_VALUE) {
        handleActiveTransferBalance(
          array?.find(({ value }) => value === "indonesian_wallet") ?? {},
          false,
          { isNoToggle: true }
        );
        return;
      }
    }
  }, [isUserJackUltimate, data?.type, array?.length, array?.[0]?.balance]);

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        noPadding={true}
        width={[420]}
        listener={toListener}
        array={[
          <div style={{ padding: 16, display: "relative" }}>
            <GothamMedium className="font20" style={{ marginBottom: 32 }}>
              {isMoveBalance
                ? t("Select balance destination")
                : t("Select a payment method ")}
            </GothamMedium>
            {/* {!loading &&
              (isShowBannerinsufficient || isDisabledPrimaryAccount) && (
                <CustomBannerBanking
                  msg={
                    isShowBannerinsufficient
                      ? t("Choose an account with enough balance to pay now")
                      : t(
                          "Primary Bank Account is not available for this transactions"
                        )
                  }
                />
              )} */}
            <Banner
              msg={
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral800 }}
                >
                  <Trans
                    i18nKey={t("dynamic.dynamic_text_30")}
                    components={[
                      <b
                        style={{
                          width: "fit-content",
                          paddingBottom: "0.2px",
                          fontWeight: 900,
                          borderBottom: `1px solid${jackColors.neutral900}`,
                          color: jackColors.neutral900,
                          cursor: "pointer",
                        }}
                        onClick={toggleVAPocketUnavailableModal}
                      />,
                    ]}
                  />
                </GothamRegular>
              }
              style={{ marginBottom: 32 }}
            />
            <div id="task-second" style={{ marginBottom: 96 }}>
              <CardSourceOfPayment
                array={array}
                isMoveBalance={isMoveBalance}
                isUserJackUltimate={isUserJackUltimate}
                isChoosingSchedule={
                  isChoosingScheduleProps
                    ? isChoosingScheduleProps
                    : isChoosingSchedule
                }
                isInternationalTransfer={isInternationalTransfer}
                activeTransferBalance={activeTransferBalance}
                setActiveTransferBalance={handleActiveTransferBalance}
                t={t}
                bankList={listBankVA}
              />

              <BackButton onClick={() => toToggle()} />
            </div>
          </div>,
        ]}
      />
      <VAPocketUnavailableModal
        isOpen={isOpenVAPocketUnavailableModal}
        toggleProps={toggleVAPocketUnavailableModal}
      />
      <TopUpModal
        modal={isOpenTopUpModal}
        toggle={toggleTopUpModal}
        dummyPaidButton
        paid
        isLocalTransfer={isLocalTransfer}
      />
      <AddFundsModal
        isOpen={isOpenAddFunds}
        toggle={() => {
          refetchBalance();
          toggleAddFunds();
        }}
        defaultVA={isVAPocket}
        t={t}
        isLocalTransfer={isLocalTransfer}
      />
      <ConfirmationModalJack
        modal={!isMoveBalance && isOpenEmptyPocket}
        toggle={toggleEmptyPocket}
        image={
          <img
            src={ImgEmptyPocket}
            style={{ width: "100%", marginBottom: 20 }}
          />
        }
        title="VA Pocket balance is insufficient"
        text="You can add funds by receiving transfers on any Jack Virtual Account you own."
        isOnlyOneButton
        buttonTextLeft="Ok, got it"
        woCloseButton
      />
      <SmartActivityModal
        isOpen={isOpenTopUpDanamon}
        toggle={toggleTopUpModalDanamon}
        data={{
          type: "jack-danamon",
        }}
      />
      <ConfirmationModalJack
        modal={isOpenChangeBalanceToVaPocket}
        toggle={toggleChangeBalanceToVaPocket}
        onClick={() => handleActiveTransferBalance(temporarySelected, true)}
        buttonTextRight="Yes, change"
        buttonTextLeft="Cancel"
        img={ImgSkipModal}
        text={t(
          "The selected transfer service won't apply and we'll process your transaction under Jack's system."
        )}
        title={t("Change to VA Pocket?")}
        customContentClassName="modal-border-radius4"
      />
    </>
  );
};

const TitleComp = ({ title, desc, t }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <GothamMedium className="font14">{t(title)}</GothamMedium>
      {desc && (
        <GothamRegular
          className="font12"
          style={{ color: jackColors.neutral700 }}
        >
          {t(desc)}
        </GothamRegular>
      )}
    </div>
  );
};

const ArrComp = ({
  array,
  isMoveBalance,
  isUserJackUltimate,
  isChoosingSchedule,
  activeTransferBalance,
  setActiveTransferBalance,
  hoverValue,
  setHoverValue,
  title,
  desc,
  t,
}) => {
  if (!array.length) return null;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <TitleComp title={title} desc={desc} t={t} />
      <div>
        {array?.map((item, index) => {
          const isFirst = index === 0;
          const {
            title,
            value,
            icon,
            desc,
            isNotEnoughBalance,
            isDisabled,
            isDanamonFailedFetch,
            refetchBalance,
            loading,
            titleTooltip,
          } = item || {};

          const isDanamonAccount = value === "danamon_balance";
          const isCollectionPocket = value == "indonesian_wallet";
          const isActive = activeTransferBalance?.value === value;
          const isHover = hoverValue === value;
          const titleBanner = isDanamonAccount
            ? "Giro Danamon"
            : "Bank Transfer";

          const isSufficientBalance = isMoveBalance
            ? false
            : !isNotEnoughBalance;
          const isInsufficientNonUltimate =
            !isUserJackUltimate && isDanamonAccount && isNotEnoughBalance;

          const moveBalanceRules = () => {
            if (isMoveBalance) {
              if (value === "indonesian_wallet") return true;
              if (value === "danamon_balance") {
                if (isUserJackUltimate) return true;
                else return false;
              }
            }
          };
          const rulesMoveBalance = moveBalanceRules();
          const bgColorDecider = () => {
            if (
              (isDisabled && !isMoveBalance) ||
              (isDisabled && isMoveBalance && isDanamonAccount)
            )
              return jackColors.neutral400;
            if (!isNotEnoughBalance && isHover) return jackColors.neutral400;
            return "white";
          };
          // const { t } = useTranslation("ledgers/ledgers");

          if (loading)
            return (
              <Skeleton
                key={index}
                width={"100%"}
                height={60}
                className="mt-3"
              />
            );

          return (
            <div
              key={index}
              onMouseEnter={() => setHoverValue(value)}
              onMouseLeave={() => setHoverValue(false)}
            >
              <div
                style={{
                  border: `1px solid ${
                    isActive ? jackColors.neutral900 : jackColors.neutral500
                  }`,
                  borderRadius:
                    isFirst && isDanamonAccount && !isChoosingSchedule
                      ? "8px 8px 0px 0px"
                      : "8px",
                  padding: 12,
                  marginTop: !isFirst && 16,
                  cursor: "pointer",
                  backgroundColor: bgColorDecider(),
                  position: "relative",
                  height: 64,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }}
                className="d-flex align-items-center justify-content-between"
                onClick={() => {
                  if ((!isNotEnoughBalance && !isDisabled) || rulesMoveBalance)
                    setActiveTransferBalance(item);
                }}
              >
                <div className="d-flex align-items-center" style={{ gap: 12 }}>
                  <JackIcons
                    name={icon}
                    style={{ width: 24, height: 24 }}
                    fill={
                      isDisabled || isInsufficientNonUltimate
                        ? jackColors.neutral600
                        : jackColors.neutral900
                    }
                  />

                  <div>
                    <div className="d-flex">
                      <GothamMedium
                        className={
                          desc || (isDanamonFailedFetch && isDanamonAccount)
                            ? "font12 mb-1"
                            : "font12"
                        }
                        style={{
                          color:
                            isDisabled || isInsufficientNonUltimate
                              ? jackColors.neutral600
                              : jackColors.neutral900,
                        }}
                      >
                        {title}
                      </GothamMedium>
                      {titleTooltip && titleTooltip}
                    </div>
                    {isDanamonFailedFetch && isDanamonAccount ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          refetchBalance();
                        }}
                      >
                        <GothamRegular
                          className="font12"
                          style={{ color: jackColors.neutral700 }}
                        >
                          {t("Failed to update your balance")}{" "}
                          <SpanMedium
                            style={{
                              textDecoration: "underline",
                              color: jackColors.neutral900,
                            }}
                          >
                            {t("Refresh")}
                          </SpanMedium>
                        </GothamRegular>
                      </div>
                    ) : (
                      desc && (
                        <GothamRegular
                          className="font12"
                          style={{
                            color:
                              isInsufficientNonUltimate && !isMoveBalance
                                ? jackColors.neutral700
                                : isNotEnoughBalance && !isMoveBalance
                                ? jackColors.redE7
                                : jackColors.neutral700,
                          }}
                        >
                          {desc}
                        </GothamRegular>
                      )
                    )}
                  </div>
                </div>
                <div>
                  {isMoveBalance ? (
                    <JackIcons
                      name={
                        isActive
                          ? "checkmark-circle-2"
                          : "checkbox-circle-empty"
                      }
                      fill={
                        isActive ? jackColors.neutral900 : jackColors.neutral500
                      }
                    />
                  ) : (
                    <>
                      {isSufficientBalance || isDisabled ? (
                        <JackIcons
                          style={{ width: 24, height: 24 }}
                          name={
                            isActive
                              ? "checkmark-circle-2"
                              : "checkbox-circle-empty"
                          }
                          fill={
                            isActive
                              ? jackColors.neutral900
                              : jackColors.neutral500
                          }
                        />
                      ) : (
                        <AddFundsButton
                          onClick={() => setActiveTransferBalance(item)}
                          t={t}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>

              {isDanamonAccount && !isChoosingSchedule && (
                <div
                  style={{
                    position: "relative",
                    marginBottom: 12,
                    zIndex: 1,
                    width: "100%",
                    height: 28,
                  }}
                >
                  <GothamMedium
                    className="font12"
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "16px",
                      color: "white",
                    }}
                  >
                    {t("Enjoy free transfer fee for your transactions!")}
                  </GothamMedium>
                  <img src={BannerSourceImg} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DirectComp = ({
  arr,
  title,
  desc,
  setActiveTransferBalance,
  activeTransferBalance,
  bankList,
  setHoverValue,
  hoverValue,
  t,
}) => {
  const [isExpand, setIsExpand] = useState(false);

  const directPaymentValue = arr[0];
  const { isDisabled, icon } = directPaymentValue || [];

  const itemsToDisplay = isExpand ? bankList : bankList.slice(0, 2);

  itemsToDisplay.sort((a, b) => {
    const isActiveA = activeTransferBalance?.item?.bankName === a.bankName;
    const isActiveB = activeTransferBalance?.item?.bankName === b.bankName;
    return isActiveB - isActiveA;
  });

  const [sortedBanks, setSortedBanks] = useState([]);

  useEffect(() => {
    const sortedItems = [...bankList].sort((a, b) => {
      const isActiveA = activeTransferBalance?.item?.bankName === a.bankName;
      const isActiveB = activeTransferBalance?.item?.bankName === b.bankName;
      return isActiveB - isActiveA;
    });

    setSortedBanks(isExpand ? sortedItems : sortedItems.slice(0, 2));
  }, [bankList, activeTransferBalance, isExpand]);

  if (!arr.length) return null;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TitleComp title={title} desc={desc} t={t} />
        <div
          style={{
            display: "flex",
            backgroundColor: colors.primaryStabilowB9,
            padding: "4px 8px",
            gap: "4px",
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <JackIcons name="lightning" fill="#FCB037" />
          <GothamMedium className="font12">{t("2x Faster")}</GothamMedium>
        </div>
      </div>
      <div
        style={{
          border: `1px solid ${jackColors.neutral500}`,
          borderRadius: "8px",
          cursor: "pointer",
          position: "relative",
          zIndex: 2,
          backgroundColor: isDisabled ? jackColors.neutral400 : "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: 8,
          }}
        >
          {sortedBanks.map((item) => {
            const isHover = hoverValue === item;
            const { bankName } = item;
            const isActive = activeTransferBalance?.item?.bankName === bankName;
            return (
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  height: "56px",
                  padding: "16px 8px",
                  borderRadius: 8,
                  backgroundColor: isHover && jackColors.neutral400,
                }}
                onMouseEnter={() => setHoverValue(item)}
                onMouseLeave={() => setHoverValue(false)}
                onClick={() => {
                  if (!isDisabled) {
                    setActiveTransferBalance({ ...directPaymentValue, item });
                  }
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={bankIconDecider(bankName)}
                    style={{ width: 24, height: 24 }}
                  />
                  <GothamMedium className="font12" style={{ marginLeft: 16 }}>
                    {bankName}
                  </GothamMedium>
                </div>
                <JackIcons
                  style={{ width: 24, height: 24 }}
                  name={
                    isActive ? "checkmark-circle-2" : "checkbox-circle-empty"
                  }
                  fill={
                    isActive ? jackColors.neutral900 : jackColors.neutral500
                  }
                />
              </div>
            );
          })}
        </div>
        {bankList?.length > 2 && (
          <div
            style={{
              borderTop: `1px solid ${jackColors.neutral500}`,
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              backgroundColor: jackColors.neutral200,
              padding: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
            onClick={() => {
              setIsExpand(!isExpand);
            }}
          >
            <GothamMedium className="font12">
              {isExpand ? t("See less") : t("See all")}
            </GothamMedium>
            <JackIcons
              style={{ width: "20px", height: "20px" }}
              name={isExpand ? "chevron-up" : "chevron-down"}
              fill={jackColors.neutral900}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const DisabledComp = ({ icon, title }) => {
  return (
    <div
      style={{
        border: `1px solid ${jackColors.neutral500}`,
        borderRadius: "8px",
        padding: 12,
        cursor: "pointer",
        position: "relative",
        height: 64,
        zIndex: 2,
        backgroundColor: jackColors.neutral400,
      }}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="d-flex align-items-center" style={{ gap: 12 }}>
        <JackIcons
          name={icon}
          style={{ width: 24, height: 24 }}
          fill={jackColors.neutral600}
        />

        <div>
          <div className="d-flex">
            <GothamMedium
              className={"font12"}
              style={{ color: jackColors.neutral600 }}
            >
              {title}
            </GothamMedium>
          </div>
        </div>
      </div>
      <div>
        <JackIcons
          style={{ width: 24, height: 24 }}
          name={"checkbox-circle-empty"}
          fill={jackColors.neutral500}
        />
      </div>
    </div>
  );
};

export const CardSourceOfPayment = ({
  array,
  isUserJackUltimate,
  isChoosingSchedule,
  activeTransferBalance,
  setActiveTransferBalance = () => {},
  isMoveBalance,
  bankList,
  isInternationalTransfer,
  t = (item) => item,
}) => {
  const [hoverValue, setHoverValue] = useState(false);
  const isAccount = array.filter(({ type }) => type === "Account");
  const isAccountChoosingSchedule = array.filter(
    ({ value }) => value === "indonesian_wallet"
  );
  const isBankTf = array.filter(({ type }) => type === "bank_transfer");
  const isDirectPayment = array.filter(({ type }) => type === "direct_payment");
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
      {isChoosingSchedule ? (
        <>
          <ArrComp
            title={"Your Accounts"}
            array={isAccountChoosingSchedule}
            isMoveBalance={isMoveBalance}
            isUserJackUltimate={isUserJackUltimate}
            isChoosingSchedule={isChoosingSchedule}
            activeTransferBalance={activeTransferBalance}
            setActiveTransferBalance={setActiveTransferBalance}
            hoverValue={hoverValue}
            setHoverValue={setHoverValue}
            t={t}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              marginTop: 16,
            }}
          >
            <div className="d-flex" style={{ gap: 4, alignItems: "start" }}>
              <JackIcons
                name="alert-circle"
                fill="#FFCB00"
                style={{ width: 16, height: 16 }}
              />
              <GothamRegular
                className="font12"
                style={{ color: jackColors.neutral700 }}
              >
                {t(
                  "Only available for immediate payments, not scheduled ones."
                )}
              </GothamRegular>
            </div>
            {array
              .filter(({ value }) => value !== "indonesian_wallet")
              .map(({ title, icon }) => (
                <DisabledComp title={title} icon={icon} />
              ))}
          </div>
        </>
      ) : (
        <>
          <ArrComp
            title={"Your Accounts"}
            array={isAccount}
            isMoveBalance={isMoveBalance}
            isUserJackUltimate={isUserJackUltimate}
            isChoosingSchedule={isChoosingSchedule}
            activeTransferBalance={activeTransferBalance}
            setActiveTransferBalance={setActiveTransferBalance}
            hoverValue={hoverValue}
            setHoverValue={setHoverValue}
            t={t}
          />
          <DirectComp
            arr={isDirectPayment}
            bankList={bankList}
            title={"Virtual Account"}
            desc={"Verification is always on, 24/7."}
            setActiveTransferBalance={setActiveTransferBalance}
            activeTransferBalance={activeTransferBalance}
            setHoverValue={setHoverValue}
            hoverValue={hoverValue}
            t={t}
          />
          <ArrComp
            title={"Bank Transfer "}
            desc={"Verification is available at 08:00-18:00 on workdays."}
            array={isBankTf}
            isMoveBalance={isMoveBalance}
            isUserJackUltimate={isUserJackUltimate}
            isChoosingSchedule={isChoosingSchedule}
            activeTransferBalance={activeTransferBalance}
            setActiveTransferBalance={setActiveTransferBalance}
            hoverValue={hoverValue}
            setHoverValue={setHoverValue}
            t={t}
          />
        </>
      )}
    </div>
  );
};

export const CardPayment = ({
  title = "title",
  desc = false,
  emptyDesc = "",
  emptyIcon = "more-horizontal",
  icon: iconName = "bank",
  styleContainer,
  isWarningDesc = false,
  onClick = () => {},
  customBanner = false,
  isMoveBalance,
  id = "",
  t,
}) => {
  const isValueEmpty = !desc;
  const icon = isValueEmpty ? emptyIcon : iconName;
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  return (
    <div
      style={{
        position: "relative",
        border: `${isWarningDesc ? "1px solid #FEACAA" : "none"}`,
        backgroundColor: `${isWarningDesc && "rgba(254, 172, 170, 0.3)"}  `,
        borderRadius: 8,
      }}
    >
      <div
        id={id}
        className="d-flex justify-content-between align-items-center"
        style={{
          position: "relative",
          zIndex: 5,
          padding: "12px 12px",
          border: isWarningDesc ? "none" : `1px solid ${jackColors.neutral500}`,
          borderBottom: isWarningDesc
            ? "1px solid #FEACAA"
            : `1px solid ${jackColors.neutral500}`,
          borderRadius: 8,
          cursor: "pointer",
          backgroundColor: "white",
          ...styleContainer,
        }}
        onClick={onClick}
      >
        <div className="d-flex align-items-center">
          <div
            style={{
              padding: 6,
              backgroundColor: jackColors.neutral400,
              borderRadius: "100%",
              width: 32,
              height: 32,
              marginRight: 12,
            }}
          >
            <JackIcons
              name={icon}
              fill={jackColors.neutral900}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div>
            <GothamMedium className="font12 mb-1">{title}</GothamMedium>
            <GothamRegular
              className="font12"
              style={{
                color:
                  isValueEmpty || (isWarningDesc && !isMoveBalance)
                    ? jackColors.redE7
                    : jackColors.neutral900,
              }}
            >
              {isValueEmpty ? emptyDesc : desc}
            </GothamRegular>
          </div>
        </div>
        {isValueEmpty ? (
          <JackIcons
            name="chevron-right-outline"
            fill={jackColors.neutral900}
            style={{ height: 20, width: 20 }}
          />
        ) : (
          <div
            style={{
              padding: 8,
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <JackIcons
              name="edit-outline"
              fill={jackColors.neutral900}
              style={{ height: 16, width: 16 }}
            />
          </div>
        )}
      </div>
      {isWarningDesc && (
        <div style={{ marginTop: "-30px", padding: "6px 12px" }}>
          <GothamRegular
            style={{ color: jackColors.neutral800 }}
            className="font12"
          >
            {tLocalTransfer(
              "Please top up your balance or select another payment method."
            )}
          </GothamRegular>
        </div>
      )}

      {!!customBanner && (
        <div
          style={{
            position: "relative",
            bottom: 0,
            left: 0,
            zIndex: 1,
            padding: "16px 16px 8px 16px",
            backgroundColor: jackColors.neutral400,
            width: "100%",
            borderRadius: "0px 0px 8px 8px",
            marginTop: -8,
          }}
        >
          {customBanner}
        </div>
      )}
    </div>
  );
};

export const AddFundsButton = ({ onClick, style, t }) => {
  return (
    <div
      style={{
        padding: 6,
        border: `1px solid ${jackColors.neutral500}`,
        borderRadius: 4,
        gap: 8,
        ...style,
      }}
      className="d-flex align-items-center hover-400"
      onClick={onClick}
    >
      <JackIcons
        name="plus-circle-outline"
        fill={jackColors.neutral900}
        style={{ width: 20, height: 20 }}
      />
      <GothamMedium>{t("Add Funds")}</GothamMedium>
    </div>
  );
};

const LoadingCardOfPayment = () => (
  <div>
    <Skeleton
      width={"100%"}
      height={60}
      className="mb-2"
      style={{ borderRadius: 8 }}
    />
    <Skeleton width={"100%"} height={60} style={{ borderRadius: 8 }} />
  </div>
);

export const CustomBannerBanking = ({
  msg = "message",
  type = "warning",
  title,
  style,
  ...props
}) => {
  return (
    <Banner
      title={title}
      msg={msg}
      type={type}
      style={{ borderRadius: 8, marginBottom: 32, ...style }}
      iconStyle={{ marginBottom: -4 }}
      {...props}
    />
  );
};
