import { useGetAuth } from "../../contexts/AuthContext";
import { typeArrayKYB } from "./details/constants";

export const useFormOfBusinessDecider = () => {
  const { user, userLoading } = useGetAuth();

  const { form_of_business } = user?.partner || {};

  const isPT = (
    typeArrayKYB.find(({ value }) => value == "pt parent")?.obj?.array || []
  )
    .map(({ value }) => value)
    .includes(form_of_business);

  const isNoBO = ["Public Company", "Government Body"].includes(
    form_of_business
  );

  const isIndividualPT = form_of_business === "Individual Limited Company";

  const isCV = form_of_business == "Partnership";

  const isPD = form_of_business == "Sole Trader";
  const isCooperatives = form_of_business == "Cooperatives";
  const isAssociation = form_of_business == "Association";
  const isFoundation = form_of_business == "Foundation";

  const isCVOrPD = isCV || isPD;

  const isAssociationOrCooperatives = isCooperatives || isAssociation;

  const isChairman = isFoundation || isAssociationOrCooperatives;

  return {
    isPT,
    isFoundation,
    isCVOrPD,
    isAssociationOrCooperatives,
    userLoading,
    isChairman,
    isNoBO,
    isIndividualPT,
  };
};
