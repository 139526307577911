import { getTime, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../../components/Buttons";
import {
  BackHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../../components/CardModal";
import {
  formatCurrencyNoDecimal,
  FullDateFormatter,
} from "../../../../components/tools";
import {
  CountDownTopUpModal,
  ProcedureInstruction,
  WrapperModalButtons,
} from "../../../../pageComponents/homeComponents/topup/components";
import { ActivityDetailsHeader } from "../../activityModalComponents";
import { usePaymentMethod } from "../paymentMethodModal/logic";
import { BankDetailsSection } from "./components";
import { SmartUploadReceiptModal } from "../../../../modals/smartUploadReceiptModal/parent";
import { useModalHook } from "../../../../components/Modals";
import { bankLists } from "../../../../assets/data";
import { RightModal } from "../../../../components/Modals/RightModal/parent";
import { GothamBold, GothamMedium } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { useTranslation } from "react-i18next";
import { jackColors } from "assets/colors";
import bankIconDecider from "constants/bankIconDecider";
import { GothamRegular } from "components/typescript/Text";
import { JackIcons } from "assets/jackIcons/parent";
import { Tooltip } from "reactstrap";
import { ToasterHook } from "contexts/ToasterContext";
import { useBooleanDanamonProduct } from "../DanamonReleasePayment/boolean";

export const timeFormatter = (created_at) => {
  const createdAtTime = getTime(parseISO(created_at || 0)) || 0;
  // const oneHour = 60 * 60 * 1000;
  // const addedTime = oneHour * 3;
  // const time = createdAtTime + addedTime;
  const time = createdAtTime;
  return time;
};

export const DirectPaymentInstructionModal = ({
  isOpen,
  toggle,
  close,
  data,
}) => {
  const { payment_method_detail: paymentMethodDetail, transaction_type } =
    data || {};
  const isSingleCrossBorder = transaction_type == "Single";

  const { successToaster } = ToasterHook();
  const {
    bank: bankName,
    display_name: displayName,
    amount,
    number,
    expiration_date,
  } = paymentMethodDetail || {};

  const bankTitleVa = (bankName) => {
    return bankName.replace("Bank ", "");
  };

  const { t } = useTranslation("international/details");
  const [bank, selectedBank] = useState(bankLists[0]);

  const { isOpen: isOpenUpload, toggle: toggleUpload } = useModalHook();

  const { payment_expiry_at, id } = data || {};

  const urlData = { type: "single_payment", id };

  const time = timeFormatter(payment_expiry_at || expiration_date);
  const expiredIn = FullDateFormatter(
    payment_expiry_at || expiration_date || ""
  );
  const dataForCountDown = { expiredIn };

  const VaComponent = ({ title, desc, t }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <GothamRegular className="font12">{t(title)}</GothamRegular>
          <GothamMedium className="font14">{t(desc)}</GothamMedium>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            gap: "4px",
            height: "24px",
            borderRadius: "32px",
            border: `1px solid ${jackColors.neutral500}`,
            padding: "16px 12px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          className="d-flex"
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(desc);
            successToaster({
              msg: t("Copied successfully!"),
            });
          }}
        >
          <JackIcons
            name="file_copy"
            fill={jackColors.neutral800}
            style={{ width: 20, height: 20 }}
          />
          <GothamMedium className="font12">{t("Copy")}</GothamMedium>
        </div>
      </div>
    );
  };

  const onClick = (val) => {
    const isOpenModal = val === "open_modal";
    const isBackUpload = val === "back_upload";

    if (isOpenModal) return toggleUpload();
    if (isBackUpload) return toggleUpload();
  };

  const components = !paymentMethodDetail
    ? [
        <WrapperModalButtons
          childrenButton={
            <ButtonJack
              style={{ width: "100%", marginRight: 16 }}
              onClick={() => {
                onClick("open_modal");
              }}
            >
              {`${t("I have paid")}`}
            </ButtonJack>
          }
        >
          <GothamMedium className="font24" style={{ marginBottom: 32 }}>
            {t("Pay via Bank Transfer")}
          </GothamMedium>
          <CountDownTopUpModal time={time} data={dataForCountDown} />
          <BankDetailsSection
            data={data}
            bank={bank}
            selectedBank={selectedBank}
          />
        </WrapperModalButtons>,
      ]
    : [
        <WrapperModalButtons
          childrenButton={
            <ButtonJack
              style={{
                width: "100%",
                marginRight: 16,
                backgroundColor: "white",
                border: `1px solid ${jackColors.neutral500}`,
              }}
              onClick={toggle}
            >
              {t("Got it!")}
            </ButtonJack>
          }
        >
          <GothamMedium className="font24" style={{ marginBottom: 32 }}>
            {t("Payment Instruction")}
          </GothamMedium>
          <div style={{ marginBottom: "30px" }}>
            <CountDownTopUpModal time={time} data={dataForCountDown} />
          </div>
          <div
            style={{
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: "8px",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                borderBottom: `1px solid ${jackColors.neutral500}`,
                padding: "16px",
              }}
              className="d-flex align-items-center"
            >
              <img
                src={bankIconDecider(bankName)}
                style={{ width: 32, height: 32 }}
              />
              <GothamMedium style={{ marginLeft: 16 }}>
                {bankTitleVa(bankName)} Virtual Account
              </GothamMedium>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
              }}
            >
              <VaComponent title={"Account Name"} desc={displayName} t={t} />
              <VaComponent
                title={"Virtual Account Number"}
                desc={number}
                t={t}
              />
              {isSingleCrossBorder && (
                <VaComponent
                  title={"Total Payment"}
                  desc={`IDR ${formatCurrencyNoDecimal(amount)}`}
                  t={t}
                />
              )}
            </div>
          </div>
          <ProcedureInstruction bankName={bankName} />
        </WrapperModalButtons>,
      ];

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        width={[420]}
      />
      <SmartUploadReceiptModal
        isOpen={isOpenUpload}
        toggle={() => onClick("back_upload")}
        bank={bank?.bank}
        urlData={urlData}
      />
    </>
  );
};
