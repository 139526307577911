import { useLanguage } from "public/locales/translationFunctions";

export const GlobalTitleTransaction = (parentData) => {
  //The purpose of this function is to ensure that the description
  //in the transaction details (trx details) is the same as in the list table.

  const { isBahasa } = useLanguage();

  const { description, description_idn, type } = parentData || {}; // /activity_search
  const { activity_description, activity_description_idn } =
    parentData?.transaction_detail || {}; // /approval_tasks/index_requested

  //special for invoice
  const isInvoice = type == "invoice";
  if (isInvoice) {
    if (isBahasa) return description_idn || activity_description_idn || "";
    return activity_description;
  }

  if (isBahasa) return description_idn || activity_description_idn || "";
  return description || activity_description || "";
};
