import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "@tools";
import { Modal } from "reactstrap";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { SimpleTextAreaJack } from "../../../components/inputs/simpleTextArea";
import { useModalHook } from "../../../components/Modals";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import CustomTooltip from "../../../components/Tooltip";
import { useActiveModules, useGetAuth } from "../../../contexts/AuthContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useGdrivehook } from "../../../contexts/GoogleDrivePreviewContext/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { CloseButton } from "../../../pageComponents/invoiceComponents/createPage/uploadPage";
import {
  getTotalAmountSummary,
  idrStringFormatter,
  pluralize,
} from "../../../pageComponents/invoiceComponents/data/formatter";
import { useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { useCrossBorderSingleWorkflow } from "../crossBorderSingleModal/hooks/workflow";
import {
  useCrossBorderSingleWorkflowApproverLogics,
  useDetailsExtractor,
} from "../crossBorderSingleModal/logics";
import { PayrollRejectModal } from "../payroll/rejectModal";
import { ReimbursementSummaryModal } from "./modal";
import { ReimbursementExpenseReview } from "./modals";
import { CancelReceiptModal } from "./modals";
import { MaskingBankingPayload } from "../crossBorderSingleModal/DanamonReleasePayment/logic";
import { ArrayAfterReleaseBanking } from "../crossBorderSingleModal/DanamonReleasePayment/arraySummary";
import { useTranslation } from "react-i18next";

const ModalButton = ({
  onClick,
  buttonText,
  children,
  isCancelButton = false,
  isDisabled = false,
}) => {
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, marginTop: 16 }}
    >
      <ButtonJack
        disabled={isDisabled}
        style={{
          width: "100%",
          backgroundColor: isCancelButton && "transparent",
          height: 40,
          fontSize: 14,
        }}
        onClick={onClick}
        type={isCancelButton ? "outline" : "filled"}
        leftIcon={
          isCancelButton && (
            <JackIcons
              name="close_circle"
              fill="#343434"
              style={{ height: 17, width: 17 }}
            />
          )
        }
      >
        {buttonText}
      </ButtonJack>
      {children}
    </div>
  );
};

export const reimbursementBatchWorkflow = ({
  transaction,
  refetch,
  setTransaction,
  dataParent,
  allData,
  listener,
}) => {
  const { push } = useRouter();
  const {
    id: batchId,
    approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    workflow_approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    rejection_note,
    state,
    reimbursement_child,
    is_there_any_saved_approval_reviews,
  } = transaction || {};
  const childLength = reimbursement_child?.length;
  const isCanceled = state == "cancelled";
  const isWaitingConfirmation = state == "waiting_for_confirmation";
  const isFailed = state == "declined";
  const { t } = useTranslation("reimbursement/reimbursement");
  const isID = localStorage.getItem("locale") == "id";
  const { canApprove, taskId, hideCancelButton } =
    useCrossBorderSingleWorkflowApproverLogics(transaction);
  const { isDisabledReleaseDecider } = useActiveModules();
  const workflowObjs = useCrossBorderSingleWorkflow(transaction);
  const {
    isDrafter,
    isOnProgress,
    isCompleted,
    approverLineArray,
    isCancelled,
  } = workflowObjs;

  const isLastApprover =
    approverLineArray[approverLineArray?.length - 1]?.isActive;

  const { isOpen: isOpenReviewModal, toggle: toggleReviewModal } =
    useModalHook();
  const { isOpen: openSummary, toggle: toggleSummary } = useModalHook();
  const [rejection, setRejection] = useState(false);
  const toggleRejection = () => setRejection(false);
  const detailData = useDetailsExtractor({ transactionDetails: transaction });

  const { loading: loadingCancel, mutation: cancel } = useMutation({
    url: `/reimbursements/${batchId}/change_state`,
    handleError: (err) => console.log(err),
    afterSuccess: (res) => {
      eventsTracker("reimbursement_cancel_batch", res);
      push(`success?type=reimbursement&btcid=${batchId}&pageType=cancel`);
    },
    // afterSuccess: () => afterSuccess("Transaction successfully cancelled"),
  });

  const { loading: loadingApprove, mutation: updateTask } = useMutation({
    url: `/approval_tasks/${taskId}/update_approval_items`,
    afterSuccess: (res) => {
      eventsTracker(
        `reimbursement_${isLastApprover ? "approve_and_pay" : "approve"}`,
        res
      );
      push(
        `success?type=reimbursement&btcid=${batchId}&pageType=${
          isLastApprover ? "pay" : "approve"
        }&taskId=${taskId}`
      ); //toggle();
    },
  });
  const { loading: rejectLoading, mutation: bulkReject } = useMutation({
    url: `/approval_tasks/${taskId}/update_approval_items`,
    afterSuccess: (res) => {
      eventsTracker(`reimbursement_reject_batch`, res);
      push(
        `success?type=reimbursement&btcid=${batchId}&pageType=reject&taskId=${taskId}`
      ); //toggle();
    },
  });

  const onSubmitWorkflow = (payload, type) => {
    const { reason: rejection_note, pin } = payload || {};
    const isCancel = type === "cancel";
    const isApprove = type === "approve";
    const isReject = type === "reject";

    if (isApprove) return updateTask(payload);
    if (isReject) return bulkReject(payload);
    if (isCancel) return cancel({ state: "cancel", rejection_note, pin });
  };

  const loadingButtons = loadingCancel;
  const submitLoading = loadingApprove || rejectLoading;

  const dataDecider = () => {
    const { array, ...rest } = detailData;
    if (canApprove && isLastApprover) return detailData;

    const nonReleaserData = array?.filter(
      ({ title }) => title == "Number of expense" || title == "Total amount"
    );
    return { ...rest, array: [{ spacer: true }, ...nonReleaserData] };
  };

  const cancelTextDecider = () => {
    if (childLength == 1)
      return t("You're about to cancel an expense you've requested.");
    return isID
      ? `${childLength} pengeluaran yang anda ajukan akan dibatalkan.`
      : `You're about to cancel all ${childLength} expenses you've requested.`;
  };
  const rejectModal = () => {
    return (
      <PayrollRejectModal
        text={rejection}
        loading={loadingButtons}
        isOpen={Boolean(rejection)}
        toggle={toggleRejection}
        onSubmit={onSubmitWorkflow}
        detailData={dataDecider()}
        customButtonText={t("Cancel Request")}
        customSubtitle={cancelTextDecider()}
        hideCancelButton={hideCancelButton}
      />
    );
  };

  const hideButtons =
    isCompleted ||
    isOnProgress ||
    isFailed ||
    isWaitingConfirmation ||
    Boolean(rejection_note) ||
    isCanceled;

  const buttons = () => {
    if (hideButtons) return null;

    const handleClick = (action) => {
      const review = action == "review";
      const cancel = action == "cancel";
      if (review) return toggleReviewModal();
      if (cancel) return setRejection("cancelation");
    };

    const isDisabledRelease =
      isDisabledReleaseDecider({ module: transaction?.type }) && isLastApprover;

    if (canApprove) {
      return (
        <ModalButton
          isDisabled={isDisabledRelease}
          buttonText={
            is_there_any_saved_approval_reviews
              ? t("Continue Review Expense")
              : t("Start Review Expense")
          }
          onClick={() => {
            eventsTracker("reimbursement_review_receipt", {
              reimbursement_id: batchId,
            });
            handleClick("review");
          }}
        >
          <ReimbursementExpenseReview
            isOpen={isOpenReviewModal}
            toggle={toggleReviewModal}
            data={transaction}
            setData={setTransaction}
            taskId={taskId}
            submitLoading={submitLoading}
            onSubmit={onSubmitWorkflow}
            isPay={isLastApprover}
            refetch={refetch}
            dataParent={dataParent}
            allData={allData}
            listener={listener}
          />
        </ModalButton>
      );
    }
    if (isDrafter && !hideCancelButton)
      return (
        <ModalButton
          buttonText={t("Cancel Request")}
          isCancelButton={true}
          onClick={() => handleClick("cancel")}
        />
      );
  };

  const handleTableRowClick = (defaultClick) => {
    // if (isDrafter && !canApprove) return defaultClick;
    // return () => toggleReviewModal();

    if (!hideButtons && canApprove) return () => toggleReviewModal();
    return defaultClick;
  };

  return {
    isDrafter,
    canApprove,
    buttons,
    rejectModal,
    isRejection: Boolean(rejection),
    hideButtons,
    workflowObjs,
    isLastApprover,
    isApprover: canApprove,
    handleTableRowClick,
  };
};

export const cancelReimbursementReceipt = ({
  receipt,
  refetch: refetchmain,
  isLasttoCancel,
  batchId,
}) => {
  const {
    user_id,
    rejection_note,
    state,
    id: receiptId,
    merchant,
    amountIDR,
    rejected_by_id,
  } = receipt || {};

  const isCompleted = state == "completed";
  const isCanceled = state == "cancelled";
  const { user } = useGetAuth();
  const { push } = useRouter();
  const { id } = user;
  const isDrafter = user_id == id;
  const { successSnackBar } = ToasterHook();
  const { loading: loadingCancel, mutation: cancel } = useMutation({
    url: `/reimbursement_receipts/${receiptId}/change_state`,
    afterSuccess: ({ data }) => {
      if (isLasttoCancel)
        return push(
          `success?type=reimbursement&btcid=${batchId}&pageType=cancel`
        );
      refetchmain();
      const { merchant } = data;
      toggleCancelModal();
      eventsTracker("reimbursement_cancel_receipt", { receipt });
      return successSnackBar({
        msg: (
          <>
            Expense <TextInlineMedium>{merchant}</TextInlineMedium> has been
            canceled.
          </>
        ),
        showClose: true,
      });
    },
  });

  const onSubmit = (values) => {
    const { rejection_note } = values;
    return cancel({ rejection_note, state: "cancel" });
  };

  const { isOpen: openCancelModal, toggle: toggleCancelModal } = useModalHook();
  const cancelComponent = () => {
    if (isCompleted) return null;
    if (isCanceled) return null;
    if (Boolean(rejection_note)) return null;
    // if (state == "cancelled") {
    // //   return (
    // //     <CancelledBox
    // //       isDrafter={isDrafter}
    // //       rejection_note={rejection_note}
    // //       user_id={user_id}
    // //     />
    // //   );
    // // }

    if (isDrafter)
      return (
        <div
          className="d-flex w-100 justify-content-center"
          style={{
            height: 40,
            border: "1px solid #e6e6e8",
            alignItems: "center",
            borderRadius: 4,
          }}
        >
          <GothamRegular className="font12 mr-1">
            Choosing not to submit?
          </GothamRegular>{" "}
          <GothamMedium
            className="font12"
            style={{
              color: jackColors.redDC,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => toggleCancelModal()}
          >
            Cancel expense
          </GothamMedium>
          <CancelReceiptModal
            merchant={merchant}
            amount={amountIDR}
            isOpen={openCancelModal}
            toggle={toggleCancelModal}
            loading={loadingCancel}
            onSubmit={onSubmit}
          />
        </div>
      );
  };
  return { cancelComponent };
};

export const CancelledBox = ({ user_id, isDrafter, rejection_note }) => {
  const { users } = useConstants();
  const userName = users?.find(({ id }) => user_id == id)?.name;
  return (
    <div style={{ border: "1px solid #e6e8e8", borderRadius: 4 }}>
      <div
        style={{
          padding: "8px 10px",
          display: "flex",
          alignItems: "center",
          gap: 8,
          backgroundColor: "#FCFCFC",
          borderBottom: "1px solid #E6E6E8",
        }}
      >
        <JackIcons name="close_circle" fill="#E73126" />
        <GothamMedium className="font12" style={{ color: "#E73126" }}>
          Cancelled by {isDrafter ? "You" : userName}
        </GothamMedium>
      </div>
      <div style={{ padding: 16, justifyContent: "start" }}>
        <GothamRegular
          className="font12"
          style={{ color: "#6c6c71", textAlign: "start" }}
        >
          {rejection_note}
        </GothamRegular>
      </div>
    </div>
  );
};

export const reimbursementStatus = (
  state,
  rejectedById,
  isReceipt = true,
  check_is_complete = false,
  isMissingRequiredCategory = false
) => {
  const isWaitingForPayment = state == "waiting_for_payment";
  const isCanceled = state == "cancelled";
  const isOnProgress = state == "on_progress";
  const isMarkAsApproved = state == "approved";
  const isMarkAsRejected = state == "rejected";
  const isCompleted = state == "completed";
  const isFailed = state == "declined";
  const isDrafted = state == "drafted";
  const isCreated = state == "created";
  const isScheduled = state == "scheduled";
  const isPaymentDelayed = state == "payment_delayed";
  if (isCreated)
    return check_is_complete && !isMissingRequiredCategory
      ? "Ready to submit"
      : "Missing details";
  if (isWaitingForPayment) return "Waiting For Payment";
  if (isDrafted) return "Draft";
  if (isFailed) return "Failed";
  if (isCompleted) return "Success";
  if (isCanceled && rejectedById) return "Rejected";
  if (isCanceled) return "Canceled";
  if (isMarkAsApproved) return "Approved";
  if (isMarkAsRejected) return "Rejected";
  if (isOnProgress && isReceipt) return "Approved";
  if (isOnProgress) return "In progress";
  if (isScheduled || isPaymentDelayed) {
    if (isReceipt) return isPaymentDelayed ? "Payment Delayed" : "Scheduled";

    return state;
  }
  if (isReceipt) return "Pending Review";
  return "Pending Approval";
};

const ApproveButton = ({
  iconName,
  fill,
  onClick,
  disabled = false,
  toolTipText,
}) => {
  const scaleDecider = (iconName) => {
    if (iconName == "checkmark_circle_2_outline") return 1.07991;
    return 1.0797;
  };

  const scale = scaleDecider(iconName);
  return (
    <CustomTooltip
      placement="top"
      innerClassName="duplicateTooltip"
      popperClassName="white-arrow mb-2"
      text={
        <div
          style={{
            width: "auto",
            height: "auto",
            backgroundColor: "white",
            zIndex: 55,
            padding: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "start",
          }}
        >
          <GothamRegular
            className="text-grey6c font12"
            style={{ color: colors.grey6c }}
          >
            {toolTipText}
          </GothamRegular>
        </div>
      }
    >
      <button
        style={{
          width: "100%",
          height: 32,
          border: "1px solid #BBBBC0",
          borderRadius: 4,
          backgroundColor: "transparent",
          display: "grid",
          placeItems: "center",
        }}
        onClick={() => {
          if (disabled) return;
          return onClick();
        }}
      >
        <JackIcons
          name={iconName}
          fill={fill}
          style={{ width: 20, height: 20, transform: `scale(${scale})` }}
        />
      </button>
    </CustomTooltip>
  );
};
// mark as app => scale 1.08
//other 1.19

const TextSnackbar = ({ type, merchant }) => {
  const { t } = useTranslation("reimbursement/reimbursement");
  const copyDecider = () => {
    if (type == "approve") return "marked as approved.";
    if (type == "reject") return "marked as rejected.";
    return "";
  };
  const copy = copyDecider();
  return (
    <>
      {t("Expense")} <TextInlineMedium>{merchant}</TextInlineMedium> {t(copy)}
    </>
  );
};

const toolTipTextDecider = (disableButton, marked, type) => {
  const isApproveButton = type == "approve";
  const isID = localStorage.getItem("locale") == "id";
  const undoApprovalText = isID ? "Batal setujui" : "Undo approval";
  const undoRejectionText = isID ? "Batal tolak" : "Undo rejection";
  const approveText = isID ? "Setujui" : "Approve";
  const rejectText = isID ? "Tolak" : "Reject";

  if (disableButton) return "Rejected";
  if (marked) return isApproveButton ? undoApprovalText : undoRejectionText;
  return isApproveButton ? approveText : rejectText;
};

export const useApproveRejectReceipt = ({
  receipt,
  setState,
  defaultValue,
  disableButton = false,
}) => {
  const { approval_task_child, merchant, amountIDR, id, amount } = receipt;

  const taskId = approval_task_child?.id;
  const { isOpen: openReject, toggle: toggleReject } = useModalHook();
  const [status, setStatus] = useState("");
  const markAsApproved = status == "approved";
  const markAsRejected = status == "rejected";
  const { successSnackBar } = ToasterHook();

  const {
    iconName: iconNameReject,
    fill: fillReject,
    toolTipText: toolTipReject,
  } = useMemo(() => {
    const iconName = markAsRejected ? "close_circle" : "close_circle_outline";
    const fill = jackColors.redE7;

    const toolTipText = toolTipTextDecider(
      disableButton,
      markAsRejected,
      "reject"
    );

    return {
      iconName,
      fill,
      toolTipText,
    };
  }, [status]);

  const {
    iconName: iconNameApprove,
    fill: fillApprove,
    toolTipText: toolTipApprove,
  } = useMemo(() => {
    const iconName = markAsApproved
      ? "checkmark_circle_2"
      : "checkmark_circle_2_outline";
    const fill = jackColors.green06;

    const toolTipText = toolTipTextDecider(
      disableButton,
      markAsApproved,
      "approve"
    );

    return {
      iconName,
      fill,
      toolTipText,
    };
  }, [status]);

  useEffect(() => {
    if (!defaultValue) return setStatus("");
    if (!status && defaultValue) return setStatus(defaultValue);
  }, [defaultValue]);

  const { user } = useGetAuth();
  const currentUserId = user?.id;
  const buttons = () => {
    if (disableButton || !taskId) return <div>-</div>;
    return (
      <div className="d-flex justify-content-between w-100 ">
        <div style={{ width: "47.2%" }}>
          <ApproveButton
            iconName={iconNameReject}
            fill={fillReject}
            onClick={() => {
              if (markAsRejected) {
                setStatus("");
                //before save Progress
                // return setState((p) => p?.filter(({ id }) => id != taskId));
                //after saveProgress
                return setState((p) => {
                  const newPayload = p?.map((item) => {
                    const { id, ...rest } = item;
                    if (id == taskId) {
                      return { ...item, state: null };
                    }
                    return item;
                  });
                  return newPayload;
                });
              }
              toggleReject();
            }}
            disabled={disableButton}
            toolTipText={toolTipReject}
          />
        </div>
        <div style={{ width: "47.2%" }}>
          <ApproveButton
            iconName={iconNameApprove}
            disabled={disableButton}
            fill={fillApprove}
            onClick={() => {
              setState((p) => {
                const filteredP = p?.filter(({ id }) => id != taskId);
                if (markAsApproved)
                  return [
                    {
                      receiptId: id,
                      id: taskId,
                      state: null,
                      merchant,
                      amount,
                      amountIDR,
                      rejection_note: null,
                      originator_id: id,
                    },
                    ...filteredP,
                  ];
                return [
                  ...filteredP,
                  {
                    receiptId: id,
                    id: taskId,
                    state: "approved",
                    merchant,
                    amount,
                    amountIDR,
                    rejection_note: null,
                    originator_id: id,
                    action_by_user: currentUserId,
                  },
                ];
              });
              eventsTracker("reimbursement_receipt_mark_as_approved", {
                receipt,
                taskId,
              });
              {
                !markAsApproved &&
                  successSnackBar({
                    msg: <TextSnackbar type="approve" merchant={merchant} />,
                    showClose: true,
                  });
              }
              setStatus((p) => (p == "approved" ? "" : "approved"));
            }}
            toolTipText={toolTipApprove}
          />
        </div>
        <CancelReceiptModal
          isReject={true}
          merchant={merchant}
          amount={amountIDR}
          isOpen={openReject}
          toggle={toggleReject}
          onSubmit={({ rejection_note }) => {
            setState((p) => {
              const filteredP = p?.filter(({ id }) => id != taskId);

              return [
                ...filteredP,
                {
                  id: taskId,
                  receiptId: id,
                  rejection_note,
                  state: "rejected",
                  merchant,
                  amount,
                  amountIDR,
                  originator_id: id,
                  action_by_user: currentUserId,
                },
              ];
            });
            eventsTracker("reimbursement_receipt_mark_as_rejected", {
              receipt,
              taskId,
              rejection_note,
            });
            successSnackBar({
              msg: <TextSnackbar type="reject" merchant={merchant} />,
              showClose: true,
            });
            setStatus("rejected");
            toggleReject();
          }}
        />
      </div>
    );
  };
  return {
    buttons,
  };
};

export const expenseAmountDecider = (transaction) => {
  const { initial_amount, amount } = transaction ?? {};
  const fullCancel = amount == 0;
  const rejectedAmount = initial_amount - amount;

  //CASE FULL CANCEL
  if (fullCancel)
    return {
      totalAmount: initial_amount,
      rejectedAmount: initial_amount,
      finalAmount: 0,
    };

  //CASE PARTIAL APPROVE OR NORMAL
  return { totalAmount: initial_amount, rejectedAmount, finalAmount: amount };
};

export const totalExpenseDetails = (
  transaction,
  isLastApprover = false,
  isRetry = false
) => {
  const { reimbursement_child, fee, amount, initial_amount, state } =
    transaction ?? {};
  const isDrafted = state == "drafted";
  const fullCancel = amount == 0;
  const partiallyCanceled = amount != initial_amount && !fullCancel;

  const { totalAmount, rejectedAmount, finalAmount } =
    expenseAmountDecider(transaction);

  const initialExpenseLength = reimbursement_child?.length;
  const currentExpenseLength = reimbursement_child?.filter(
    ({ state }) => state != ("cancelled" || "cancelled")
  )?.length;

  const defaultArray = [
    {
      title: "Number of expense",
      value: `${initialExpenseLength} ${pluralize(
        initialExpenseLength,
        "Expense"
      )}`,
    },
    {
      title: "Total amount",
      value: `IDR ${formatCurrencyNoDecimal(totalAmount)}`,
    },
  ];

  const extendedArray = [
    {
      title: "Canceled expense(s)",
      value: `IDR ${formatCurrencyNoDecimal(rejectedAmount)}`,
      customIcon: "minus_circle",
    },
    { spacer: true },
    {
      title: "Final amount",
      value: `IDR ${formatCurrencyNoDecimal(Number(finalAmount))}`,
    },
  ];

  const feeArray = [
    {
      title: "Handling fee",
      value: `IDR ${formatCurrencyNoDecimal(fee)} x ${currentExpenseLength}`,
      customIcon: "plus_circle",
    },
    { spacer: true },
    {
      title: "Total payment",
      value: `IDR ${formatCurrencyNoDecimal(
        Number(fee * currentExpenseLength) + Number(amount)
      )}`,
    },
  ];

  if (isDrafted) return defaultArray;

  if (isRetry) return [...defaultArray, ...feeArray];

  if (isLastApprover && !fullCancel) {
    if (partiallyCanceled)
      return [...defaultArray, ...extendedArray, ...feeArray];
    return [...defaultArray, ...feeArray];
  }

  if (partiallyCanceled && !fullCancel)
    return [...defaultArray, ...extendedArray];

  return defaultArray;
};

export const recipientDetails = (transaction) => {
  const { local_recipient_data, reference_number, created_at } =
    transaction ?? {};
  const { localTransferBanks } = useConstants();
  const { t } = useTranslation("reimbursement/reimbursement");
  const { registered_name, payer_id, account_number } =
    local_recipient_data || {};

  const bankObj = localTransferBanks.filter(
    ({ value }) => value == payer_id
  )[0];
  const bank = bankObj?.name.toUpperCase();

  const { transfer_service, payment_method } =
    MaskingBankingPayload(transaction);
  const { stateAfterPayments } = ArrayAfterReleaseBanking({
    data: transaction,
  });

  return [
    { title: t("Created at"), value: customDateFormatter(created_at || "") },
    ...(stateAfterPayments
      ? [
          {
            title: "Transfer service",
            value: transfer_service,
          },
          {
            title: "Source of account",
            value: payment_method,
          },
        ]
      : []),
    {
      title: t("Transaction ID"),
      value: reference_number,
    },
    {
      title: t("Recipient"),
      value: (
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-2">
            <GothamRegular className="font12">{registered_name}</GothamRegular>
            <GothamRegular
              className="font10"
              style={{ color: jackColors.neutral700 }}
            >
              {bank} - {account_number}
            </GothamRegular>
          </div>
          <div style={{ width: 24 }}>
            <Avatar name={registered_name} size="small" />
          </div>
        </div>
      ),
    },
  ];
};

export const totalAmountCalc = (expenses, fee = 0) => {
  if (isEmpty(expenses))
    return { totalAmountApproved: 0, totalAmountRejected: 0, totalFee: 0 };

  const rejectedArr = expenses?.filter(({ state }) => state == "rejected");
  const approvedArr = expenses?.filter(({ state }) => state == "approved");

  const { totalAmount: totalAmountRejected } = getTotalAmountSummary(
    rejectedArr,
    "amount"
  );

  const { totalAmount: totalAmountApproved } = getTotalAmountSummary(
    approvedArr,
    "total_amount"
  );

  const { totalAmount: totalFee } = getTotalAmountSummary(approvedArr, "fee");

  return { totalAmountApproved, totalAmountRejected, totalFee };
};

export const releaserSummaryDetail = (expenses = [], fee = 0) => {
  if (!expenses) return [];

  const { totalAmountString } = getTotalAmountSummary(expenses, "amount");

  const { totalAmountApproved, totalAmountRejected, totalFee } =
    totalAmountCalc(expenses, fee);
  const feeIDR = idrStringFormatter(totalFee);
  const totalPaymentIDR = idrStringFormatter(
    Number(totalAmountApproved) + Number(totalFee)
  );

  const totalAmountObj = { title: "Total amount", value: totalAmountString };
  const feeObj = {
    title: "Handling fee",
    value: feeIDR,
    customIcon: "plus_circle",
  };
  const spacer = { spacer: true };
  const totalPaymentoBj = { title: "Total payment", value: totalPaymentIDR };
  const rejectedObj = {
    title: "Rejected expense(s)",
    value: `IDR ${formatCurrencyNoDecimal(totalAmountRejected)}`,
    customIcon: "minus_circle",
  };
  const finalAmountObj = {
    title: "Final amount",
    value: `IDR ${formatCurrencyNoDecimal(totalAmountApproved)}`,
  };

  if (totalAmountRejected)
    return [
      totalAmountObj,
      rejectedObj,
      spacer,
      finalAmountObj,
      feeObj,
      spacer,
      totalPaymentoBj,
    ];

  return [totalAmountObj, feeObj, spacer, totalPaymentoBj];
};

export const isLastToCancelCheck = (data, detailId) => {
  const nonCancelledData = data?.filter(({ state }) => state != "cancelled");
  const nonCancellength = nonCancelledData?.length;

  if (nonCancellength !== 1) return false;

  const nonCancelDataId = nonCancelledData?.[0]?.id;

  return nonCancelDataId == detailId;
};

export const supportingdocHook = (files) => {
  if (!files) return { hide: true, title: "", onClickSupportingDoc: () => {} };

  const suppDocLength = files?.length;
  const noSupportingDoc = suppDocLength == 0;
  const title = `${suppDocLength} Supporting ${pluralize(
    suppDocLength,
    "Document",
    "s"
  )}`;

  const formattedSD = files?.map((item) => {
    const {
      content_type: type,
      filename: name,
      byte_size: size,
      file_url: url,
    } = item;
    return { type, name, size, url };
  });
  const { show } = useGdrivehook();
  const onClick = () => {
    if (!suppDocLength) return;
    show({ files: formattedSD ?? [] });
  };

  return { hide: noSupportingDoc, title, onClickSupportingDoc: onClick };
};

export const extractPayloadDefaultValue = (array) =>
  array
    ?.map(({ approval_task_child, amount, amountIDR, merchant }) => ({
      amount,
      amountIDR,
      ...approval_task_child?.saved_reviews,
      id: approval_task_child?.id,
      merchant,
    }))
    ?.filter(({ originator_id }) => Boolean(originator_id))
    ?.map(
      ({
        amountIDR,
        amount,
        id,
        merchant,
        originator_id,
        rejection_note,
        state,
        user_id,
      }) => ({
        amountIDR,
        amount,
        id,
        merchant,
        originator_id,
        rejection_note,
        state,
        receiptId: originator_id,
        action_by_user: user_id,
      })
    );

export const formatSummaryModalData = (array, payload) => {
  if (isEmpty(array)) return { sortedData: [], summaryData: {} };
  const sortedData = array
    ?.map((receipt) => {
      const { id } = receipt;
      const childPayload = payload?.find(
        ({ originator_id }) => originator_id == id
      );
      if (!isEmpty(childPayload)) return { ...receipt, ...childPayload };
      return receipt;
    })
    ?.sort(({ state }) => (state == "approved" ? -1 : 1));

  const approvedData = sortedData?.filter(({ state }) => {
    if (state == "cancelled") return false;
    if (state == "rejected") return false;
    return true;
  });

  const amount = getTotalAmountSummary(
    approvedData,
    "total_amount"
  )?.totalAmount;

  const summaryData = {
    reimbursement_child: approvedData,
    amount,
    state: "review",
    initial_amount: 0,
  };

  return { sortedData, summaryData };
};
