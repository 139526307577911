import { useState } from "react";
import { useAlertDecider } from "../../../../pageComponents/crossBorderSingleConfirmation";
import { createtaxDescription } from "../../../../pageComponents/invoiceComponents/createPage/modals/formLogic";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { useCrossBorderSingleWorkflowApproverLogics } from "../logics";
import { useRouter } from "next/router";
import { fetch, useMutation } from "../../../../tools/api";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { jackColors } from "../../../../assets/colors";
import { useBooleanDanamonProduct } from "./boolean";
import CustomTooltip from "../../../../components/Tooltip";
import { useSubscriptionUsage } from "../../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { isRetriedBatchDecider } from "../../retry-resubmit/helpers";
import { get } from "lodash";
import { FreeComponents } from "../transferServiceModal/option";

export const useHandlingFee = () => {
  const [data, setData] = useState({});

  const { mutation: calculateFee, loading: loadingCalculateFee } = useMutation({
    url: `/subscription_module/calculate_handling_fee`,
    afterSuccess: (res) => setData(res?.data?.data),
  });

  return { calculateFee, loadingCalculateFee, data };
};

export const useBankingHooks = (transaction = {}) => {
  const { type } = transaction || {};
  const { isUltimate: isUserJackUltimate, loadingSubscriptionUsage } =
    useSubscriptionUsage();

  const { isInternationalTransfer } = useBooleanDanamonProduct(type);

  const [activeTransferBalance, setActiveTransferBalance] = useState({});
  const [activeTransferService, setActiveTransferService] = useState({});
  const [transferServiceRules, setTransferServiceRules] = useState({});
  const { all_to_danamon } = transferServiceRules?.transaction_flag || {};

  const isCollectionPocket =
    activeTransferBalance?.value === "indonesian_wallet";
  const isVaTransfer = activeTransferBalance?.value === "va_transfer";
  const showDetailsFee =
    activeTransferService?.title || isCollectionPocket || isVaTransfer;

  const transferServiceDecider = () => {
    if (isUserJackUltimate && all_to_danamon && !isCollectionPocket)
      return "intrabank";
    if (isCollectionPocket || isInternationalTransfer) return "optimized_route";
    return activeTransferService?.value;
  };

  const payloadBanking = {
    payment_method: activeTransferBalance?.value,
    transfer_service: transferServiceDecider(),
    bank: activeTransferBalance?.item?.bankName,
  };

  const payloadHandlingFee = {
    payment_method: activeTransferBalance?.value ?? "indonesian_wallet",
    service:
      isCollectionPocket ||
      isInternationalTransfer ||
      !activeTransferService?.value
        ? "optimized_route"
        : activeTransferService?.value,
  };

  const canCaluclateHandlingFee =
    (activeTransferBalance?.value && activeTransferService?.value) ||
    isCollectionPocket;

  const propsTransferService = {
    showDetailsFee,
    isUserJackUltimate,
    activeTransferService,
    activeTransferBalance,
    setActiveTransferBalance,
    setActiveService: setActiveTransferService,
    setTransferServiceRules,
    transferServiceRules,
    loadingSubscriptionUsage,
  };

  return {
    showDetailsFee,
    isUserJackUltimate,
    activeTransferBalance,
    activeTransferService,
    setActiveTransferBalance,
    setActiveTransferService,
    propsTransferService,
    payloadBanking,
    payloadHandlingFee,
    canCaluclateHandlingFee,
    loadingSubscriptionUsage,
    transferServiceRules,
    setTransferServiceRules,
  };
};

export const useArraySummaryLogic = ({
  transaction,
  showFirstArray,
  showSecondArray,
  propsTransferService,
  dataHandlingFee,
  isReleased,
}) => {
  const { isUserJackUltimate, showDetailsFee } = propsTransferService || {};
  console.log("showDetailsFee:", showDetailsFee);

  const {
    batch,
    total_amount,
    payment_channel,
    local_transaction,
    transaction_items,
    transaction_taxes,
    formatted_reimbursement_child,
    reimbursement_child,
    vat,
  } = transaction || {};

  // VAT: disini kalo mau nandain kena VAT atau tidak dari transaksi existing atau transaksi baru
  const trxBeforeVat =
    vat === null || local_transaction?.vat === null || batch?.vat === null;
  const trxFreeVat = vat === 0 || local_transaction?.vat === 0;

  const { all_transactions_total_fee, fee_removed, total_vat_to_be_paid } =
    dataHandlingFee || {};
  const feeToBePaid = all_transactions_total_fee - fee_removed || 0;

  const VATtoBePaid = trxBeforeVat ? 0 : total_vat_to_be_paid;

  const { total_amount: total_amount_batch } = batch || {};
  const paymentChannel = useAlertDecider({ data: payment_channel });

  // const isShowTotalFee = showDetailsFee || !isUserJackUltimate;
  const isShowTotalFee = showDetailsFee;

  // reimbursement
  const reimbursementChildArray =
    formatted_reimbursement_child || reimbursement_child || [];
  const reimbursementFiltered = ReimbursementChildApproveOnly(
    reimbursementChildArray
  );

  const sumAmountReimbursement = reimbursementFiltered.reduce(
    (acc, obj) => acc + +obj.amount,
    0
  );
  const reimbursementBatchLength = reimbursementFiltered?.length;
  // const feePerTrxReimbursement = isUserJackUltimate
  //   ? +feeToBePaid
  //   : +fee / reimbursementBatchLength; // fee update setiap reject / approve
  // const totalFeeReimbursement =
  //   feePerTrxReimbursement * reimbursementBatchLength;
  const totalAmountReimbursement = isShowTotalFee
    ? +sumAmountReimbursement + +feeToBePaid + +VATtoBePaid
    : +sumAmountReimbursement;

  // bill payment
  const totalAmountBillPayment = isShowTotalFee
    ? +total_amount + +feeToBePaid + +VATtoBePaid
    : +total_amount;

  // local transfer
  const totalAmountLocal = isShowTotalFee
    ? +total_amount + +feeToBePaid + +VATtoBePaid
    : +total_amount;

  // payroll
  const totalAmountPayroll = isShowTotalFee
    ? +total_amount_batch + +feeToBePaid + +VATtoBePaid
    : +total_amount_batch;

  const { feesTaxesBillPayment, itemsBillPayment } =
    PayInvoiceBreakdownDetailsModal({
      transaction,
      showFirst: showFirstArray,
      showSecond: showSecondArray,
    });

  const listReimbursementTrx = reimbursementChildArray.map((item, index) => {
    const {
      merchant,
      amountIDR,
      amount: amountRaw,
      activity_state,
      state,
    } = item || {};
    const isLast = reimbursementChildArray.length - 1 === index;

    // state == "rejected" untuk trx yg baru di reject tapi blm ke BE
    const isCancelled = activity_state == "cancelled" || state == "rejected";

    const isRejected = !!item?.rejected_by_id || state == "rejected";
    const { users } = useConstants();

    const userCancel = users?.find(({ id }) => id == item?.user_id)?.name;
    const userReject = users?.find(
      ({ id }) => id == item?.rejected_by_id || id == item?.action_by_user
    )?.name;
    const action = isRejected ? "Rejected" : "Canceled";
    const user = isRejected ? userReject : userCancel;

    const amount = amountIDR || `IDR ${formatCurrencyNoDecimal(+amountRaw)}`;

    return {
      title: merchant,
      title2: isCancelled && `(${action} by ${user})`,
      value: amount,
      showAccordion: showFirstArray,
      descAccordion: true,
      isLastAccordion: isLast,
      isLineThrough: isCancelled,
      styleValue: { color: jackColors.neutral700 },
    };
  });

  return {
    paymentChannel,
    reimbursementBatchLength,
    // totalFeeReimbursement,
    sumAmountReimbursement,
    // feePerTrxReimbursement,
    totalAmountReimbursement,
    totalAmountBillPayment,
    // feePerTrxLocal,
    // totalFeeLocal,
    totalAmountLocal,
    // feePerTrxPayroll,
    // totalFeePayroll,
    totalAmountPayroll,
    itemsBillPayment,
    feesTaxesBillPayment,
    listReimbursementTrx,
  };
};

export const chooseSchedulePaymentDecider = (schedulePaymentState) =>
  get(schedulePaymentState, "[0].payload.is_scheduled", false);

export const handlingFeeBreakdownSingle = ({
  dataHandlingFee,
  transaction,
  showFee,
  t = (item) => item,
  isSelectedService,
  isSelectedBalance,
  isUserJackUltimate,
}) => {
  const {
    batch,
    type,
    local_transactions,
    local_transaction,
    is_single_trx = false,
    transfer_service,
    vat,
  } = transaction || {};

  const trxBeforeVat =
    vat === null || local_transaction?.vat === null || batch?.vat === null;
  const isVaPocketBalance = isSelectedBalance === "indonesian_wallet";
  const isOptimizedRouteRelease = isSelectedService === "optimized_route";
  const isOptimizedRouteTrxDetails =
    transfer_service === "optimized_route" ||
    batch?.transfer_service === "optimized_route";
  const isOptimizedRoute =
    isVaPocketBalance || isOptimizedRouteRelease || isOptimizedRouteTrxDetails;
  const { isLocalTransfer, isPayroll, isReimbursement, isBillPayment } =
    useBooleanDanamonProduct(type);
  const isLocalTransferSingle = is_single_trx;

  const isRetried = isRetriedBatchDecider(local_transactions);

  const keyDecider = () => {
    if (isLocalTransfer) return "local";
    if (isPayroll) return "payroll";
    if (isReimbursement) return "reimbursement";
    if (isBillPayment) return "invoice";
  };

  // const titleProductDecider = () => {
  // return "Handling";
  // if (isLocalTransfer) return "Local transfer";
  // if (isPayroll) return "Payroll";
  // if (isReimbursement) return "Reimbursement";
  // if (isBillPayment) return "Bill payment";
  // };

  const key = keyDecider();
  // const showIncludeFee = showFee && !isOptimizedRoute;
  // const showVatDetails = showFee && isOptimizedRoute && !trxBeforeVat;
  const showVatDetails = showFee && !trxBeforeVat;

  // const title = t(`Handling fee ${showIncludeFee ? `(include VAT)` : ``}`);
  const title = t(`Handling fee`);

  const { to_wallet, to_danamon } = dataHandlingFee || {};
  const isIncEwallet = !!to_wallet?.total_transactions;
  const isIncDanamon = !!to_danamon?.total_transactions;

  const objHandlingFee =
    dataHandlingFee === undefined ? {} : dataHandlingFee[key];

  const array = [
    isIncEwallet ? { type: "ewallet", ...to_wallet } : null,
    isIncDanamon ? { type: "danamon", ...to_danamon } : null,
    objHandlingFee,
  ];

  const filteredArray = (array || []).filter(
    (item) => item && item?.total_transactions
  );

  const formattedArray = (filteredArray || []).map((item, index) => {
    const { fee, total_fee, total_transactions, type } = item || {};
    const isLast = filteredArray.length - 1 === index;

    const titleTypeDecider = () => {
      if (type == "ewallet")
        return (
          <div className="d-flex align-items-center">
            {total_transactions}x {t("Transfer to e-wallet fee")}{" "}
            <CustomTooltip
              text={t("For transactions that is sent to e-Wallet.")}
              // popperClassName="white-arrow"
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                backgroundColor: jackColors.neutral900,
              }}
            >
              <JackIcons
                name="info-outline"
                fill={jackColors.neutral700}
                style={{ width: 16, height: 16, marginLeft: 4 }}
              />
            </CustomTooltip>
          </div>
        );
      if (type == "danamon")
        return `${total_transactions}x ${t("Transfer to Danamon fee")}`;
      return title;
    };

    const valueTypeDecider = () => {
      if (type == "danamon") return t("Free");
      if (!showFee) return "--";
      return <FreeComponents dataHandlingFee={dataHandlingFee} />;
      // return `IDR ${formatCurrencyNoDecimal(total_fee)}`;
    };

    const title2Decider = () => {
      if (showFee && (isLocalTransferSingle || isBillPayment)) return false;
      if (!showFee) {
        if (isSelectedBalance) return t("Please select your transfer service");
        return t("Please select a payment method");
      }
      return `(${total_transactions} x IDR ${formatCurrencyNoDecimal(fee)})`;
    };

    const output = {
      title: titleTypeDecider(),
      title2: title2Decider(),
      value: valueTypeDecider(),
      type,
      styleMainDiv: { gridTemplateColumns: "50% 50%" },
    };

    if ((isLocalTransfer || isPayroll) && isRetried) {
      delete output.title2;
    }

    return output;
  });

  const dataVAT = {
    title: t("VAT on handling fee"),
    value: `IDR ${formatCurrencyNoDecimal(
      dataHandlingFee?.total_vat_to_be_paid
    )}`,
  };

  // VAT: disini kalo mau nandain munculin dataVAT atau tidak
  if (showVatDetails) return [...formattedArray, dataVAT];
  return formattedArray;
};

export const useMyCompanyBalance = () => {
  const {
    data: dataRaw,
    refetch,
    loading: loadingRaw,
  } = fetch({ url: "/my_company_balance" });
  const { isUltimate: isUserJackUltimate } = useSubscriptionUsage();
  const data = dataRaw?.data || {};

  const balanceDanamonRaw =
    +data?.danamon_account_details?.balance?.shadow_value;

  const { balance: balanceVaPocket, frozen_amount } =
    (data?.disbursement_balance || [])
      ?.filter(({ name }) => name == "MAIN_WALLET")
      ?.pop() || {};

  const balancePocketRaw = +balanceVaPocket - +(frozen_amount || 0);

  const isDanamonFailedFetch =
    isUserJackUltimate && _.isNil(data?.danamon_account_details);
  const isNanBalanceDanamon = _.isNaN(balanceDanamonRaw);
  const isNanBalancePocket = _.isNaN(balancePocketRaw);
  const balanceDanamon = Math.floor(balanceDanamonRaw) || 0;
  const balancePocket = Math.floor(balancePocketRaw) || 0;
  const isNullBalanceDanamon = balanceDanamon == 0;
  const isNullBalancePocket = balancePocket == 0;

  const loading = loadingRaw || (isNanBalanceDanamon && isNanBalancePocket);
  const lastFourDigitsDanamonAccount =
    data?.danamon_account_details?.account?.account_number?.slice(-4);

  return {
    isDanamonFailedFetch,
    balanceDanamon,
    balancePocket,
    isNullBalanceDanamon,
    isNullBalancePocket,
    refetchBalance: refetch,
    loadingBalance: loading,
    accountData: data,
    lastFourDigitsDanamonAccount,
  };
};

export const useReleaseDanamonMutation = ({
  data,
  afterApprove,
  afterSuccessApproveLocalDisbursment,
  afterSuccessUpdatePaymentInternational,
  afterSuccessApprovePayroll,
  afterSuccessFetchVaTransfer,
  afterSuccessApproveInvoice,
}) => {
  const { push } = useRouter();
  const { id } = data || {};
  const { taskId } = useCrossBorderSingleWorkflowApproverLogics(data);

  const { loading: loadingApprove, mutation: approve } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: (res) => afterApprove(res),
  });

  const {
    loading: loadingUpdatePaymentInternational,
    mutation: updatePaymentInternational,
  } = useMutation({
    url: `/update_single_transaction/${id}`,
    afterSuccess: (res) => {
      if (afterSuccessUpdatePaymentInternational)
        return afterSuccessUpdatePaymentInternational(res?.data?.data);
      const { id } = res?.data?.data;
      push(`/success/?type=single_transaction&id=${id}`);
    },
  });

  const { data: dataPayroll, refetch: refetchDataPayroll } = fetch({
    url: `/payrolls/${id}`,
    woInit: true,
    type: "v1",
    afterSuccess: (res) => {
      if (afterSuccessApprovePayroll)
        return afterSuccessApprovePayroll(res?.data);
    },
  });

  const { data: dataLocalDisbursment, refetch: refetchDataLocalDisbursment } =
    fetch({
      url: `/local_transaction_batches/${id}`,
      woInit: true,
      type: "v1",
      afterSuccess: (res) => {
        if (afterSuccessApproveLocalDisbursment)
          return afterSuccessApproveLocalDisbursment(res?.data);
      },
    });
  const { data: dataVaTransfer, refetch: refetchDataVaTransfer } = fetch({
    url: `/business_transactions/${id}`,
    woInit: true,
    type: "v2",
    afterSuccess: (res) => {
      if (afterSuccessFetchVaTransfer) {
        return afterSuccessFetchVaTransfer(res?.data);
      }
    },
  });
  const { data: dataInvoice, refetch: refetchDataInvoice } = fetch({
    url: `/invoice_transactions/${id}`,
    woInit: true,
    type: "v1",
    afterSuccess: (res) => {
      if (afterSuccessFetchVaTransfer) {
        return afterSuccessApproveInvoice(res?.data);
      }
    },
  });

  const loading = loadingApprove || loadingUpdatePaymentInternational;

  return {
    loading,
    approve,
    updatePaymentInternational,
    dataVaTransfer,
    refetchDataVaTransfer,
    refetchDataLocalDisbursment,
    refetchDataPayroll,
    refetchDataInvoice,
  };
};

export const ReimbursementChildApproveOnly = (array) => {
  const reimbursementFiltered = (array || []).filter(
    ({ status, state }) =>
      status !== "cancelled" &&
      status !== "rejected" &&
      state !== "cancelled" &&
      state !== "rejected"
  );

  return reimbursementFiltered;
};

export const MaskingBankingPayload = (data) => {
  const {
    payment_method: methodRaw,
    transfer_service: serviceRaw,
    payment_channel: channelRaw,
  } = data || {};
  const method = methodRaw?.toLowerCase() || {};
  const service = serviceRaw?.toLowerCase() || {};
  const channel = channelRaw?.toLowerCase() || {};

  const PaymentMethodDecider = () => {
    if (method == "danamon_balance") return "Primary Bank Account";
    if (method == "bank_transfer") return "Bank Transfer";
    if (method == "va_transfer") return "VA Transfer";
    return "VA Pocket";
  };

  const TransferServiceDecider = () => {
    if (service == "bifast") return "BI Fast";
    if (service == "online") return "Online Payment";
    if (service == "skn") return "SKN";
    if (service == "rtgs") return "RTGS";
    return "Optimized Route";
  };

  const PaymentChannelDecider = () => {
    if (channel == "swift_our" || channel == "swift_sha") return "SWIFT";
    return "Local Bank Network";
  };

  return {
    payment_method: PaymentMethodDecider(),
    transfer_service: TransferServiceDecider(),
    payment_channel: PaymentChannelDecider(),
  };
};

export const PayInvoiceBreakdownDetailsModal = ({
  transaction,
  showFirst = () => {},
  showSecond = () => {},
}) => {
  const { transaction_items, transaction_taxes } = transaction || {};
  const itemsBillPayment = (transaction_items || []).map((item, index) => {
    const { item_name, item_quantity, price_per_item, total_price } =
      item || {};
    const isLast = transaction_items.length - 1 === index;
    return {
      title: item_name,
      title2: `(${+item_quantity} x IDR ${formatCurrencyNoDecimal(
        price_per_item
      )})`,
      value: `IDR ${formatCurrencyNoDecimal(total_price)}`,
      showAccordion: showFirst,
      descAccordion: true,
      isLastAccordion: isLast,
    };
  });

  const feesTaxesBillPayment = (transaction_taxes || []).map((item, index) => {
    const { fee_tax_name, add_or_deduct, amount } = item || {};
    const tax_description = createtaxDescription(item);
    const isAdd = add_or_deduct == "add";
    const isLast = transaction_taxes.length - 1 === index;

    return {
      title: fee_tax_name,
      title2: tax_description,
      value: (
        <div className="d-flex justify-content-end">
          <JackIcons
            name={isAdd ? "plus" : "minus"}
            // fill={isAdd ? "#238730" : "#DC2F44"}
            fill={jackColors.neutral700}
            style={{ width: 12, height: 12, marginRight: 2 }}
          />
          IDR {formatCurrencyNoDecimal(amount)}
        </div>
      ),
      showAccordion: showSecond,
      descAccordion: true,
      isLastAccordion: isLast,
    };
  });

  return { itemsBillPayment, feesTaxesBillPayment };
};
