import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { TopUpModal, useModalHook } from "../../../components/Modals";
import PINModal from "../../../components/PINModal/parent";
import { booleanState } from "../../../components/Status";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { useActiveModules } from "../../../contexts/AuthContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { LittleDot } from "../../../pageComponents/crossBorderSingleConfirmation";
import { invoicePageTypeDecider } from "../../../pageComponents/invoiceComponents/data/formatter";
import PreventionModal from "../../../pageComponents/subscriptionsJackComponents/general-components/PreventionModal/parent";
import { useSubscriptionUsage } from "../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { fetch, useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { PayrollRejectModal } from "../payroll/rejectModal";
import {
  useBankingHooks,
  useHandlingFee,
  useReleaseDanamonMutation,
} from "./DanamonReleasePayment/logic";
import { DirectPaymentInstructionModal } from "./DirectPaymentInstructionModal";
import { useCrossBorderSingleWorkflow } from "./hooks/workflow";
import {
  useCrossBorderSingleWorkflowApproverLogics,
  useDetailsExtractor,
} from "./logics";
import { ReleasePaymentModalJack } from "./ReleasePaymentModal";
import { useBooleanDanamonProduct } from "./DanamonReleasePayment/boolean";

export const ApproveRejectButtons = ({
  loading,
  children,
  onClickLeft,
  onClickRight,
  buttonLeftText = "Reject",
  buttonRightText = "Approve",
  style,
  disableRightButton = false,
  disableLeftButton = false,
  rightButtonIcon = (
    <JackIcons
      name="checkmark-circle-2"
      fill={disableRightButton ? jackColors.greyBB : jackColors.neutral900}
    />
  ),
  leftButtonIcon = (
    <JackIcons
      name="close-circle"
      fill={disableLeftButton ? jackColors.greyBB : jackColors.neutral900}
    />
  ),
  buttonLeftType = "submit",
  buttonRightType = "submit",
}) => {
  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, marginTop: 16, ...style }}
    >
      <ButtonJack
        buttonType={buttonLeftType}
        disabled={disableLeftButton}
        style={{ width: "50%" }}
        isLoading={loading}
        type="outline"
        leftIcon={leftButtonIcon}
        onClick={() => onClickLeft && onClickLeft()}
      >
        {buttonLeftText}
      </ButtonJack>
      <ButtonJack
        buttonType={buttonRightType}
        disabled={disableRightButton}
        style={{ width: "50%" }}
        isLoading={loading}
        leftIcon={rightButtonIcon}
        onClick={() => onClickRight && onClickRight()}
      >
        {buttonRightText}
      </ButtonJack>
      {children}
    </div>
  );
};

export const crossBorderSingleWorkflowComponents = ({
  transaction,
  cancelUrlFunc = (id) =>
    `/business_transactions/${id}/cancel_single_transaction`,
  modelName = "transaction",
  bankTransfer = false,
  setIsHideMainModal = () => {},
  workflowButtonsContainerStyle = {},
  afterSuccess: afterSuccessProps,
  afterSuccessWithMoreData,
}) => {
  const {
    id,
    approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    workflow_approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    rejection_note,
    state,
    transaction_type, // need attention
    batch, // payroll
    payroll_workflow_summary, // payroll
    reimbursement_workflow_summary, // reimbursement
    invoice_file: isInvoice, // invoice
    payment_method, // crossborder single
  } = transaction || {};
  const { transaction_type: transactionTypeBatch } = batch || {};
  const isReimbursement = reimbursement_workflow_summary;
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { t: tCommon } = useTranslation("common");

  const { canApprove, taskId, hideCancelButton } =
    useCrossBorderSingleWorkflowApproverLogics(transaction);

  const [rejection, setRejection] = useState(false);

  const {
    isDrafter,
    isWorkflow,
    isOnProgress,
    isCompleted,
    isRefunded,
    approverLineArray,
  } = useCrossBorderSingleWorkflow(transaction);

  const { isFailed, isDeclined, isValidatingPayment } = booleanState(
    transaction?.state
  );
  const { isJackTransfer, isWithdraw } = booleanState(transaction?.type);

  const { push, pathname } = useRouter();
  const isCanceled = state === "canceled" || state === "cancelled";

  const lastApproverLineArray = approverLineArray[approverLineArray.length - 1];
  const releasers = lastApproverLineArray?.data;
  const isLastApprover = lastApproverLineArray?.isActive;

  const toggleRejection = () => setRejection(false);

  const { successSnackBar } = ToasterHook();

  // Jack ultimate || Danamon
  const { isUserJackUltimate } = useBankingHooks();

  const isSingleCrossBorder = transaction_type == "Single";
  const isLocalTransfer = transaction_type == "transfer";
  const isPayroll = transactionTypeBatch === "payroll";
  const showingSnackBar = isSingleCrossBorder || isPayroll || isLocalTransfer;
  const isBankTransfer = payment_method == "bank_transfer" || bankTransfer;
  const isUndecidedPayment = payment_method == "undecided";

  const transactionTypeDecider = () => {
    const isPayroll = payroll_workflow_summary;
    const isReimbursement = reimbursement_workflow_summary;

    if (isReimbursement) return "reimbursement";
    if (isSingleCrossBorder) return "single_transaction";
    if (isLocalTransfer) return "local_transfer";
    if (isPayroll) return "payroll";
    return "invoice";
  };

  const transactionType = transactionTypeDecider();

  const afterSuccess = async ({ res, payload, msg, type }) => {
    if (afterSuccessWithMoreData) {
      afterSuccessWithMoreData({ res, payload, type });
      return;
    }

    if (isInvoice) {
      const pageType = invoicePageTypeDecider(rejection, isLastApprover);
      await push(`success?type=invoice&id=${id}&pageType=${pageType}`);
    } else {
      // await push(
      //   `/history/success-workflow/?transaction_type=${transactionType}&ids=${id}`
      // );
      await push(
        `/success/?type=${transactionType}&id=${id}&pageSource=${pathname}`
      );
    }
    toggleRejection();
    if (!showingSnackBar) {
      successSnackBar({ msg });
    }
  };

  const { loading: loadingCancel, mutation: cancel } = useMutation({
    url: cancelUrlFunc(id),
    afterSuccess: (res, payload) =>
      afterSuccess({
        res,
        payload,
        msg: tCommon("Transaction successfully cancelled"),
        type: "cancel",
      }),
  });

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const { isOpen, toggle } = useModalHook();
  const {
    isOpen: isOpenReleasePaymentModal,
    toggle: toggleReleasePaymentModal,
  } = useModalHook();
  const { isOpen: isOpenPaymentMethod, toggle: togglePaymentMethod } =
    useModalHook();
  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();

  const { loading: loadingApprove, mutation: approve } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: (res, payload) => {
      afterSuccess({
        res,
        payload,
        type: isLastApprover ? "release" : "approve",
        msg: tCommon("Transaction successfully approved"),
      });
      toggle();
      eventsTracker(`${modelName}_approve`, res);
    },
  });

  const { loading: loadingReject, mutation: reject } = useMutation({
    url: `/approval_tasks/${taskId}/reject`,
    afterSuccess: (res, payload) => {
      afterSuccess({
        res,
        payload,
        type: "reject",
        msg: tCommon("Transaction successfully rejected"),
      });
      eventsTracker(`${modelName}_reject`, res);
    },
  });

  const onSubmitWorkflow = (values, type) => {
    const { reason: rejection_note, pin, additionalPayload } = values || {};

    const isCancel = type === "cancel";
    const isReject = type === "reject";
    const isApprove = type == "approve";

    const value = { rejection_note };

    const payload = { pin, ...additionalPayload };

    if (isApprove) return approve(payload);

    if (isCancel) {
      const state =
        isInvoice || isReimbursement || isJackTransfer || isWithdraw
          ? "cancel"
          : "cancelled";
      return cancel({
        rejection_note,
        state,
        pin,
      });
    }

    if (isReject) return reject({ rejection_note, pin });
  };

  const loadingButtons = loadingApprove || loadingReject || loadingCancel;

  const detailData = useDetailsExtractor({
    transactionDetails: transaction,
    isReleaser: isLastApprover,
    canApprove,
    isUserJackUltimate,
    t: tLocalTransfer,
  });

  const rejectModal = () => (
    <PayrollRejectModal
      text={rejection}
      loading={loadingButtons}
      isOpen={Boolean(rejection)}
      toggle={toggleRejection}
      onSubmit={onSubmitWorkflow}
      detailData={detailData}
    />
  );

  const buttons = (disableRightButton = false, style = {}) => {
    if (disableRightButton) return null;
    if (isCompleted) return null;
    if (isOnProgress && isInvoice) return null;
    if (isOnProgress && isReimbursement) return null;
    if (!isWorkflow) return null;
    if (rejection_note) return null;
    if (isCanceled) return null;
    if (isRefunded) return null;
    if ((isFailed || isDeclined) && isInvoice) return null;

    const defaultStyle = {
      height: 40,
      fontSize: 14,
    };

    const handleClick = (purpose = "approve") => {
      const isApproving = purpose === "approve";
      const isRejecting = purpose === "reject";
      const isCanceling = purpose === "cancel";

      if (isApproving) {
        if (isSuspended) return toggleSuspendModal();

        toggle();
        if (isSingleCrossBorder && isUndecidedPayment) {
          return togglePaymentMethod();
        }
        if (isBankTransfer) return togglePinModal();

        toggleReleasePaymentModal();
      }

      if (isRejecting) {
        if (isSuspended) return toggleSuspendModal();

        setRejection("rejection");
      }

      if (isCanceling) {
        if (isSuspended) return toggleSuspendModal();

        setRejection("cancelation");
      }
    };

    if (canApprove) {
      if (isLastApprover)
        return (
          <ReleaserButtons
            disableRightButton={disableRightButton}
            isSuspended={isSuspended}
            loadingButtons={loadingButtons}
            loadingSubscriptionUsage={loadingSubscriptionUsage}
            transaction={transaction}
            setRejection={setRejection}
            bankTransfer={bankTransfer}
            onSubmitWorkflow={onSubmitWorkflow}
            setIsHideMainModal={setIsHideMainModal}
            transactionType={transactionType}
            afterSuccessRelease={afterSuccess}
          />
        );

      return (
        <ApproveRejectButtons
          style={style}
          onClickLeft={() => handleClick("reject")}
          onClickRight={() => handleClick("approve")}
          loading={loadingButtons || loadingSubscriptionUsage}
          buttonRightText={t("Approve")}
          buttonLeftText={t("Reject")}
        >
          <PINModal
            isLoading={loadingButtons}
            isOpen={isOpen}
            toggle={toggle}
            onBack={toggle}
            onSubmit={({ pin }) => onSubmitWorkflow({ pin }, "approve")}
          />
          <PreventionModal
            type="suspended-approve"
            isOpen={isOpenSuspendModal}
            toggle={toggleSuspendModal}
            onClick={toggleSuspendModal}
          />
        </ApproveRejectButtons>
      );
    }

    if (isDrafter && !isValidatingPayment && !hideCancelButton) {
      // if (isPayroll) return null;
      return (
        <div
          className="d-flex justify-content-between w-100"
          style={{ gap: 12, marginTop: 16 }}
        >
          <ButtonJack
            style={{
              width: "100%",
              backgroundColor: "transparent",
              ...defaultStyle,
            }}
            type="outline"
            onClick={() => handleClick("cancel")}
            isLoading={loadingSubscriptionUsage}
            leftIcon={
              <JackIcons
                name="close_circle"
                fill="#343434"
                style={{ height: 17, width: 17 }}
              />
            }
          >
            {t("Cancel Request")}
          </ButtonJack>
          <PreventionModal
            type="suspended-approve"
            isOpen={isOpenSuspendModal}
            toggle={toggleSuspendModal}
            onClick={toggleSuspendModal}
          />
        </div>
      );
    }

    return null;
  };

  return {
    rejectModal,
    buttons,
    isRejection: Boolean(rejection),
    isWorkflow,
    openPinModal: isOpen,
    isApprover: canApprove,
    isReleaser: isLastApprover,
    releasers,
  };
};

export const useBankingStuffDanamon = ({
  transaction,
  taskId,
  isRetry,
  scheduleData,
}) => {
  const isSchedulePayment = scheduleData?.type !== "default";
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const {
    isUserJackUltimate,
    activeTransferBalance,
    activeTransferService,
    propsTransferService: propsBanking,
    payloadBanking,
    payloadHandlingFee,
    canCaluclateHandlingFee,
  } = useBankingHooks(transaction);

  const isBankTransferBalance = activeTransferBalance?.value == "bank_transfer";
  const isDirectPaymentVA = activeTransferBalance?.value === "va_transfer";

  const {
    data: dataHandlingFee,
    calculateFee,
    loadingCalculateFee,
  } = useHandlingFee();

  useEffect(() => {
    const payload = {
      ...payloadHandlingFee,
      task_ids: [taskId],
    };
    if (isRetry) payload.is_retry = true;
    calculateFee(payload);
  }, [activeTransferBalance.value, activeTransferService.value]);

  const propsTransferService = {
    taskId: [taskId],
    dataHandlingFee,
    ...propsBanking,
  };
  // last line of Banking Stuff Danamon

  const detailData = useDetailsExtractor({
    transactionDetails: transaction,
    isReleaser: true,
    canApprove: true,
    isUserJackUltimate,
    propsTransferService,
    loadingCalculateFee,
    isSchedulePayment,
    t: tLocalTransfer,
  });

  return {
    detailData,
    propsTransferService,
    isUserJackUltimate,
    activeTransferBalance,
    activeTransferService,
    propsBanking,
    payloadBanking,
    payloadHandlingFee,
    canCaluclateHandlingFee,
    dataHandlingFee,
    loadingCalculateFee,
    calculateFee,
    isBankTransferBalance,
    isDirectPaymentVA,
  };
};

const ReleaserButtons = ({
  transaction,
  bankTransfer = false,
  setRejection,
  onSubmitWorkflow,
  loadingButtons,
  loadingSubscriptionUsage,
  isSuspended,
  transactionType,
  setIsHideMainModal,
  afterSuccessRelease,
  disableRightButton = false,
}) => {
  const { type } = transaction || {};
  const { isInternationalTransfer, isPayroll, isInvoice } =
    useBooleanDanamonProduct(type);

  const { taskId } = useCrossBorderSingleWorkflowApproverLogics(transaction);
  // const [isExpired, setisExpired] = useState();
  const [payment, setPayment] = useState({ type: "default" });

  const [dataPaymentInstruction, setDataPaymentInstruction] = useState(false);

  const { isDisabledReleaseDecider } = useActiveModules();

  const { t } = useTranslation("payroll/create");

  const isDisabledRelease = (() => {
    if (disableRightButton) return true;

    return isDisabledReleaseDecider({
      module: transaction?.type,
    });
  })();

  const {
    detailData,
    propsTransferService,
    isBankTransferBalance,
    isDirectPaymentVA,
    payloadBanking,
    activeTransferBalance,
  } = useBankingStuffDanamon({
    transaction,
    taskId,
    scheduleData: payment,
  });

  // Modal Hooks
  const {
    isOpen: isOpenReleasePaymentModal,
    toggle: toggleReleasePaymentModal,
    close: closeReleasePaymentModal,
  } = useModalHook();
  const { isOpen: isOpenPinModal, toggle: togglePinModal } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();
  const { isOpen: isOpenDirectInstruction, toggle: toggleDirectInstruction } =
    useModalHook();

  useEffect(() => {
    // if (!isBankTransferBalance) {
    setIsHideMainModal(
      isOpenReleasePaymentModal || isOpenPinModal,
      dataPaymentInstruction
    );
    // }
  }, [isOpenReleasePaymentModal]);

  // mutation approve danamon
  const afterSuccessUpdateInternational = async (res) => {
    toggleReleasePaymentModal();
    await setDataPaymentInstruction(res);
    toggleDirectInstruction();
    eventsTracker("open_modal_crossborder_payment_instruction");
  };

  const afterApprove = (res) => {
    const { status } = res || {};
    const isSuccess = status === 200 || status === 204;

    if (isSuccess) {
      const { sent_amount, country_details, fee } = transaction || {};
      const { from_country, to_country } = country_details || {};
      const { currency: from_currency, iso_3: from_iso_3 } = from_country || {};
      const { currency: to_currency, iso_3: to_iso_3 } = to_country || {};

      const eventsPayload = {
        sent_amount,
        from_currency,
        from_iso_3,
        to_currency,
        to_iso_3,
        fee,
        product_category: "crossborder_single",
        transaction_type: transactionType,
      };
      eventsTracker("transaction_released", eventsPayload);
      togglePinModal();

      if (isDirectPaymentVA) {
        toggleReleasePaymentModal();
        if (isInternationalTransfer) return refetchDataVaTransfer();
        if (isPayroll) return refetchDataPayroll();
        if (isInvoice) return refetchDataInvoice();
        else return refetchDataLocalDisbursment();
      }
      if (isBankTransferBalance) {
        return handleClick({ type: "update_international" });
      }

      afterSuccessRelease({ msg: "Transaction successfully approved" });
    }
  };

  const {
    loading: loadingRelease,
    approve,
    updatePaymentInternational,
    refetchDataVaTransfer,
    refetchDataLocalDisbursment,
    refetchDataPayroll,
    refetchDataInvoice,
  } = useReleaseDanamonMutation({
    data: transaction,
    afterApprove,
    afterSuccessUpdatePaymentInternational:
      isBankTransferBalance && afterSuccessUpdateInternational,
    afterSuccessFetchVaTransfer: (res) => {
      if (isDirectPaymentVA) {
        setDataPaymentInstruction(res);
        toggleDirectInstruction();
      }
    },
    afterSuccessApproveLocalDisbursment: (res) => {
      if (isDirectPaymentVA) {
        setDataPaymentInstruction(res);
        toggleDirectInstruction();
      }
    },
    afterSuccessApprovePayroll: (res) => {
      if (isDirectPaymentVA) {
        setDataPaymentInstruction(res);
        toggleDirectInstruction();
      }
    },
    afterSuccessApproveInvoice: (res) => {
      if (isDirectPaymentVA) {
        setDataPaymentInstruction(res);
        toggleDirectInstruction();
      }
    },
  });

  // onClick
  const handleClick = ({ value, type = "approve" }) => {
    const isApproving = type === "approve";
    const isRejecting = type === "reject";
    const isCanceling = type === "cancel";
    const isSubmitting = type === "submit";
    const isUpdateInternational = type === "update_international";

    const payloadUpdateInternational = {
      payment_method: activeTransferBalance?.value,
    };

    if (isUpdateInternational) {
      eventsTracker("choose_payment_method_crossborder", {
        payment_method: payloadUpdateInternational,
      });
      return updatePaymentInternational(payloadUpdateInternational);
    }

    if (isSubmitting) {
      if (isSuspended) return toggleSuspendModal();
      eventsTracker(`release_payment_${transactionType}`);
      return approve({
        pin: value,
        ...payment?.payload,
        ...payloadBanking,
        ...payloadUpdateInternational,
      });
    }

    if (isApproving) {
      if (isSuspended) return toggleSuspendModal();
      toggleReleasePaymentModal();
    }

    if (isRejecting || isCanceling) {
      if (isSuspended) return toggleSuspendModal();
      setRejection(isRejecting ? "rejection" : "cancelation");
    }
  };

  const ModalPaymentInstruction = () => (
    <DirectPaymentInstructionModal
      data={dataPaymentInstruction}
      isOpen={isOpenDirectInstruction}
      toggle={toggleDirectInstruction}
    />
  );

  if (dataPaymentInstruction)
    return (
      <>
        <ButtonJack
          style={{ width: "100%" }}
          onClick={toggleDirectInstruction}
          // isLoading={loadingSubscriptionUsage}
        >
          {isDirectPaymentVA
            ? `${t("See payment instruction")}`
            : `${t("Proceed to Payment")}`}
        </ButtonJack>
        {ModalPaymentInstruction()}
      </>
    );

  return (
    <>
      {/* {Boolean(isExpired) && <SimpleModalExpired />} */}
      <ApproveRejectButtons
        buttonRightText={t("Approve & Pay")}
        buttonLeftText={t("Reject")}
        loading={loadingButtons || loadingSubscriptionUsage}
        onClickLeft={() => handleClick({ type: "reject" })}
        onClickRight={() => handleClick({ type: "approve" })} // disini bikin kondisi handleclick based on crossborder single trx
        style={{
          // maxWidth: "calc(100% - 20px)",
          width: "calc(100% - 64px)",
          marginTop: "12px",
        }}
        disableRightButton={isDisabledRelease || dataPaymentInstruction}
        disableLeftButton={dataPaymentInstruction}
      >
        <ReleasePaymentModalJack
          loading={loadingButtons}
          isOpen={isOpenReleasePaymentModal}
          toggle={() => {
            toggleReleasePaymentModal();
            // toggle();
          }}
          onClick={() => {
            // toggleReleasePaymentModal();
            togglePinModal();
          }}
          onClickTopUp={toggleTopUpModal}
          data={transaction}
          detailData={detailData}
          schedulePaymentState={[payment, setPayment]}
          {...propsTransferService}
        />
        <PINModal
          isLoading={loadingButtons || loadingRelease}
          isOpen={isOpenPinModal}
          toggle={togglePinModal}
          onBack={togglePinModal}
          onSubmit={({ pin }) => handleClick({ type: "submit", value: pin })}
        />
        <TopUpModal
          modal={isOpenTopUpModal}
          toggle={toggleTopUpModal}
          dummyPaidButton
          paid
        />
        <PreventionModal
          type="suspended-approve"
          isOpen={isOpenSuspendModal}
          toggle={toggleSuspendModal}
          onClick={toggleSuspendModal}
        />
        {ModalPaymentInstruction()}
      </ApproveRejectButtons>
    </>
  );
};

export const HeaderModal = ({ data }) => {
  const {
    title,
    icon,
    value = "IDR 10,000",
    details,
    status = "completed",
  } = data || {};

  return (
    <div style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 32 }}>{icon}</div>
      <GothamRegular
        className="mb-2"
        style={{
          width: "100%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {title}
      </GothamRegular>
      <GothamMedium
        className="mb-2"
        style={{ fontSize: 24, lineHeight: "32px" }}
      >
        {value}
      </GothamMedium>
      <div className="d-flex justify-content-between align-items-center">
        {details ? (
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.neutral700,
            }}
          >
            {details}
          </GothamRegular>
        ) : (
          <div></div>
        )}
        <StatusLabelJack status={status} isOutline />
      </div>
    </div>
  );
};

export const DetailsModal = ({ array, containerStyle }) => {
  const { t } = useTranslation("reimbursement/reimbursement");
  return (
    <div
      className="borderE6"
      style={{
        padding: 12,
        ...containerStyle,
      }}
    >
      {array?.map((item, index) => {
        const {
          title,
          title2,
          value,
          value2,
          spacerNotFull,
          spacer,
          littleDot,
          customIcon,
          leftBold,
          rightBold,
          showAccordion,
          descAccordion,
          isLastAccordion,
          isLineThrough,
          styleTitle,
          styleValue,
          styleValue2,
          styleMainDiv,
          styleCustomIcon,
          styleSpacer,
          fillCustomIcon,
          dummy,
          loading,
          isFullWidth = false,
          customComponents = false,
        } = item || {};
        const isRedText = (title2 || "").includes("Please");
        const isLast = array.length - 1 === index;
        const isTransferNotes =
          title === "Transfer note" || title === "Catatan transfer";

        const alignCenter = isTransferNotes
          ? "align-items-start"
          : "align-items-center";

        if (loading) return <Skeleton width={"100%"} height={20} />;
        if ((descAccordion && !showAccordion) || dummy) return <></>;
        // const notesFormattingValue = () => {
        //   if (isTransferNotes) return `"${value}"`;
        //   return value;
        // };
        if (customComponents)
          return <Fragment key={index}>{customComponents}</Fragment>;

        if (spacer || spacerNotFull)
          return (
            <hr
              key={index}
              style={{
                borderTop: `1px solid ${jackColors.neutral500}`,
                marginLeft: spacerNotFull && 24,
                ...styleSpacer,
              }}
            />
          );
        return (
          <div
            key={index}
            style={{
              marginBottom: descAccordion
                ? isLastAccordion
                  ? 12
                  : 0
                : !isLast && 12,
              display: isFullWidth ? "flex" : "grid",
              flexDirection: "column",
              gap: isFullWidth ? 8 : 0,
              gridTemplateColumns: isTransferNotes ? "1fr" : "40% 60%",
              borderLeft: descAccordion && `4px solid ${jackColors.neutral500}`,
              marginLeft: descAccordion && "6px",
              paddingLeft: descAccordion && "10px",
              paddingBottom: descAccordion && !isLastAccordion && "12px",
              ...(isTransferNotes && {
                borderTop: `1px solid ${jackColors.neutral500}`,
                margin: "0px -12px",
              }),
              ...styleMainDiv,
            }}
          >
            {littleDot ? (
              <div>
                <div className="d-flex align-items-center" style={{ gap: 8 }}>
                  <LittleDot
                    style={{ backgroundColor: jackColors.neutral500 }}
                  />
                  <GothamRegular
                    className="font12"
                    style={{ color: jackColors.neutral700, ...styleTitle }}
                  >
                    {title}
                  </GothamRegular>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={`d-flex ${alignCenter}`}
                  style={{
                    gap: 8,
                    flexDirection: isTransferNotes ? "column" : "row",
                    ...(isTransferNotes && {
                      margin: "12px 12px 0 12px",
                    }),
                  }}
                >
                  {customIcon && (
                    <JackIcons
                      name={customIcon}
                      fill={
                        fillCustomIcon ? fillCustomIcon : jackColors.neutral500
                      }
                      style={{ width: 16, height: 16, ...styleCustomIcon }}
                    />
                  )}
                  <GothamRegular
                    className="font12"
                    style={{
                      color: leftBold
                        ? jackColors.neutral900
                        : jackColors.neutral700,
                      fontFamily: leftBold && "GothamMedium",
                      textDecoration: isLineThrough && "line-through",
                      ...styleTitle,
                    }}
                  >
                    {typeof title === "string" ? t(title) : title}
                  </GothamRegular>
                </div>
                {title2 && (
                  <GothamRegular
                    className="font10 mt-1"
                    style={{
                      color: isRedText
                        ? jackColors.redE7
                        : jackColors.neutral700,
                    }}
                  >
                    {title2}
                  </GothamRegular>
                )}
              </div>
            )}
            <div
              className={
                isTransferNotes
                  ? "d-flex flex-column align-items-start justify-content-start"
                  : "d-flex flex-column align-items-end justify-content-start"
              }
              style={isTransferNotes ? { margin: "8px 12px 0px 12px" } : {}}
            >
              <GothamRegular
                className="font12"
                style={{
                  textAlign: "right",
                  wordWrap: "break-word",
                  color: isTransferNotes
                    ? jackColors.grey6C
                    : jackColors.black34,
                  fontFamily: rightBold && "GothamMedium",
                  textDecoration: isLineThrough && "line-through",
                  ...styleValue,
                }}
              >
                {value}
              </GothamRegular>
              {value2 && (
                <GothamRegular
                  className="font10 text-right"
                  style={{
                    color: jackColors.neutral700,
                    ...styleValue2,
                  }}
                >
                  {value2}
                </GothamRegular>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
