import { format } from "date-fns";
import { GothamRegular } from "../../../components/Text";
import { SubTextTableComponent } from "../../../components/Table/components";
import { TransitionTr } from "../../../components/Transition";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { jackColors } from "../../../assets/colors";
import { fetch, useMutation } from "../../../tools/api";
import { StatusLabelJack } from "../../../components/StatusLabel";
import CustomTooltip from "../../../components/Tooltip";
import { useEffect, useState } from "react";
import { ToasterHook } from "../../../contexts/ToasterContext";
import Styles from "../va.module.css";
import {
  LogoBankVA,
  MenuDropdownLayout,
  TextWithIcon,
  bankNameMask,
  font12,
  font14,
  icon16,
  icon20,
  iconCustom,
  vaFeeWithTaxFunc,
} from "./utils";
import { isEmpty } from "lodash";
import {
  digitDateFormatterReversedLocal,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { EditVaModal } from "../details/parents";
import { useModalHook } from "../../../components/Modals";
import { useBankDetails } from "./hooks";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { eventsTracker } from "../../../universalFunctions/events";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

export const OverflowTd = ({
  children,
  tdStyle,
  style,
  aboveComponent,
  belowComponent,
  useOwnComponent = false,
  subTextComponent = null,
}) => {
  const styleWithSub = !!subTextComponent
    ? {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        margin: 0,
        justifyContent: "center",
      }
    : {};
  return (
    <td style={styleWithSub}>
      <div style={tdStyle}>
        {aboveComponent}
        {useOwnComponent ? (
          children
        ) : (
          <GothamRegular
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              ...style,
            }}
          >
            {children}
          </GothamRegular>
        )}
        {belowComponent}
      </div>
      {!!subTextComponent && subTextComponent}
    </td>
  );
};

export const expiryDateDecider = (type, rawData, locale) => {
  if (type === "never") return locale === "id" ? "Tidak Ada" : "Never Expires";
  return time(rawData);
};

export const TypeLabel = ({ isOpen, isOutline, customContainerStyle }) => {
  const textLabel = isOpen ? "Open Amount" : "Closed Amount";
  const icon = (
    <JackIcons
      name={isOpen ? "wifi-protected-setup" : "lock"}
      fill={jackColors.neutral700}
      style={{ height: 12, width: 12 }}
    />
  );

  const containerStyle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    padding: "6px 8px",
    border: isOutline ? `1px solid ${jackColors.neutral500}` : "none",
    borderRadius: "4px",
    width: "fit-content",
    ...customContainerStyle,
  };

  const textStyle = {
    color: jackColors.neutral900,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  return (
    <div style={containerStyle}>
      {icon}
      <GothamRegular style={textStyle} className={"font10"}>
        {textLabel}
      </GothamRegular>
    </div>
  );
};

export const time = (rawDate = new Date(), type) => {
  const date = format(new Date(rawDate), "d/MM/yyyy");
  const hour = format(new Date(rawDate), "HH:mm");
  if (type === "dateOnly") return date;
  return (
    <SubTextTableComponent
      text={date}
      subText={`${hour} WIB`}
      subTextStyleAux={font12}
    />
  );
};

export const VATableItem = ({
  item,
  rawItem = {},
  onClick = () => {},
  index,
  isLast,
  isArchived,
  isDetails,
  isAPI = false,
  isTrxPage = false,
  refetch = () => {},
  setDeactivateId = () => {},
}) => {
  const {
    expiryDate,
    referenceId,
    displayName,
    vaNumber,
    bankName,
    status,
    isOpen,
    // TRANSACTION DATA
    amount,
    fee: adminFee,
    bank_name,
    created_at,
    reference_id,
    state,
    description,
    vat,
    total_fee,
  } = item;

  const [hovered, setHovered] = useState();
  const { successSnackBar } = ToasterHook();

  const expiryType = !!Date.parse(expiryDate) ? "date" : "never";
  const feeWithTax = vaFeeWithTaxFunc(item);
  const amountReceived = amount - feeWithTax || 0;
  const vaId = item?.id;
  const isDeactivated = status?.toLowerCase() === "deactivated";
  const { t } = useTranslation("va/va");
  const { locale } = useRouter();

  const { isOpen: isEditModalOpen, toggle } = useModalHook();
  const { icon, iconSize } = useBankDetails(bankName);

  const handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // const bankNameOnly = bankName.split(" ")[1];
    navigator.clipboard.writeText(vaNumber);
    eventsTracker("copy_va_number", { va_id: vaId });
    successSnackBar({ msg: "VA Number copied" });
  };

  const hideEdit = status === "Expired";

  const moreButtons = [
    !hideEdit && {
      label: t("Edit"),
      logo: "edit-outline",
      logoFill: jackColors.neutral900,
      event: "open_va_edit_modal",
      onClickFunction: () => {
        toggle();
      },
    },
    {
      label: t("Archive"),
      logo: "archive-outline",
      logoFill: "#E73126",
      event: "open_va_archive_confirmation_modal",
      onClickFunction: () => {
        setDeactivateId(vaId);
      },
    },
  ];

  const moreButtonsComponent = moreButtons.map((button) => {
    if (!button) return null;
    return (
      <ButtonJack
        style={{ padding: 0, width: "100%", justifyContent: "flex-start" }}
        type="borderless"
        leftIcon={
          <JackIcons name={button.logo} fill={button.logoFill} style={icon20} />
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          eventsTracker(button.event, { va_id: vaId });
          button.onClickFunction();
        }}
      >
        {button.label}
      </ButtonJack>
    );
  });

  if (isDetails)
    return (
      <>
        <TransitionTr
          counter={index}
          isQuick
          perPage={15}
          key={index}
          onClick={() => onClick({ ...item, category: "va" })}
          style={{ borderWidth: isLast ? 0 : 1, height: 65 }}
        >
          <td>{digitDateFormatterReversedLocal(created_at, _, true)}</td>
          <OverflowTd>{reference_id}</OverflowTd>
          <OverflowTd>
            <StatusLabelJack status={state} isOutline woTextFormatter />
          </OverflowTd>
          <OverflowTd
            style={{ textAlign: "left" }}
          >{`IDR ${formatCurrencyNoDecimal(amount)}`}</OverflowTd>
          <OverflowTd
            style={{ textAlign: "left" }}
          >{`IDR ${formatCurrencyNoDecimal(feeWithTax)}`}</OverflowTd>
          <OverflowTd
            style={{ textAlign: "right" }}
          >{`IDR ${formatCurrencyNoDecimal(amountReceived)}`}</OverflowTd>
        </TransitionTr>
      </>
    );

  if (isTrxPage)
    return (
      <>
        <TransitionTr
          counter={index}
          isQuick
          perPage={15}
          key={index}
          onClick={() => onClick({ ...item, category: "va" })}
          style={{ borderWidth: isLast ? 0 : 1, height: 65 }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div />
          <td>{digitDateFormatterReversedLocal(created_at)}</td>
          <td>
            <div className="d-flex align-items-center" style={{ gap: 12 }}>
              <StatusAccountIconJack
                state={"collection"}
                source={"virtual_account"}
              />
              <GothamRegular
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% - 36px)",
                }}
              >
                {`${t("Payment received on VA:")} ${displayName}`}
              </GothamRegular>
            </div>
          </td>
          <OverflowTd tdStyle={{ width: 134 }}>
            <StatusLabelJack status={status} isOutline woTextFormatter />
          </OverflowTd>
          <td>Virtual Account</td>
          <OverflowTd
            style={{ textAlign: "right" }}
          >{`IDR ${formatCurrencyNoDecimal(amount)}`}</OverflowTd>
        </TransitionTr>
        <EditVaModal
          rawData={rawItem}
          modalControl={{ isOpen: isEditModalOpen, toggle }}
          refetch={refetch}
        />
      </>
    );

  return (
    <>
      <TransitionTr
        counter={index}
        isQuick
        perPage={15}
        key={index}
        onClick={() => onClick({ ...item, category: "va" })}
        style={{ borderWidth: isLast ? 0 : 1, height: 65 }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {!isAPI && (
          <OverflowTd tdStyle={{ width: 104 }}>
            {expiryDateDecider(expiryType, expiryDate, locale)}
          </OverflowTd>
        )}
        {/* <OverflowTd tdStyle={{ width: 104 }}>{referenceId}</OverflowTd> */}
        <OverflowTd tdStyle={{ width: 185 }}>{displayName}</OverflowTd>
        <OverflowTd tdStyle={{ width: 308 }}>{description || "-"}</OverflowTd>
        <OverflowTd
          tdStyle={{
            display: "flex",
            columnGap: 8,
            width: 300,
          }}
          style={{ width: "fit-content" }}
          hovered={hovered == index}
          setHovered={setHovered}
          subTextComponent={
            !!vaNumber && (
              <TextWithIcon
                topIcon={
                  <img
                    src={icon}
                    alt="bank-icon"
                    style={{ ...iconSize, scale: "0.75" }}
                  />
                }
                text={bankName}
                textStyle={{
                  color: jackColors.neutral700,
                  marginLeft: 4,
                  fontSize: 12,
                }}
                divStyle={{ alignItems: "center" }}
              />
            )
          }
          belowComponent={
            !!vaNumber &&
            hovered && (
              <HoverComponent
                index={index}
                hoverText={t(`Copy to Clipboard`)}
                isBlack
                hoverComponent={
                  <div
                    onClick={handleCopy}
                    className={Styles["copy-icon"]}
                    // style={{ backgroundColor: "blue" }}
                  >
                    <JackIcons
                      name="file-copy"
                      fill={jackColors.neutral700}
                      style={{ width: 18, height: 18 }}
                    />
                  </div>
                }
              />
            )
          }
        >
          {vaNumber ? vaNumber : "-"}
        </OverflowTd>
        {/* <OverflowTd
          aboveComponent={<img src={icon} alt="bank-icon" style={iconSize} />}
          tdStyle={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            width: 160,
          }}
        >
          {bankName}
        </OverflowTd> */}
        <OverflowTd tdStyle={{ width: 144 }}>
          <StatusLabelJack status={status} isOutline woTextFormatter />
        </OverflowTd>
        {/* <OverflowTd tdStyle={{ width: 119 }}>
          <TypeLabel isOpen={isOpen} isOutline />
        </OverflowTd> */}
        {!isArchived && !isAPI && (
          <OverflowTd
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MenuDropdownLayout
              content={<>{moreButtonsComponent}</>}
              contentDivStyle={{ rowGap: 0, padding: "0px 0px" }}
              moreDropdownStyle={{ cursor: "default" }}
              clickComponent={
                <JackIcons
                  className={Styles["more-icon"]}
                  name="more-horizontal"
                  fill={jackColors.neutral700}
                />
              }
            />
          </OverflowTd>
        )}
      </TransitionTr>
      <EditVaModal
        rawData={rawItem}
        modalControl={{ isOpen: isEditModalOpen, toggle }}
        refetch={refetch}
      />
    </>
  );
};

export const HoverComponent = ({
  hoverComponent = <></>,
  hoverText = "Hovered!",
  hoverPlacement = "top",
  textAlign = "center",
  textStyle = jackColors.neutral800,
  index,
  style,
  isBlack = false,
  ...props
}) => {
  const styleObj = {
    innerClassName: isBlack ? "ledgerTooltip" : "editorTooltip",
    popperClassName: isBlack ? "black-shadowed-arrow" : "white-arrow",
    textStyle: isBlack ? "white" : textStyle,
  };

  const isString = typeof hoverText === "string";
  return (
    <CustomTooltip
      customId={`tooltip-${index}`}
      innerClassName={styleObj.innerClassName}
      popperClassName={styleObj.popperClassName}
      placement={hoverPlacement}
      text={
        <div
          style={{
            width: "fit-content",
            height: "auto",
            backgroundColor: "rgba(255,255,255,1) !important",
            opacity: "1 !important",
            zIndex: 200000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "center",
            padding: 0,
            ...style,
          }}
        >
          {isString ? (
            <GothamRegular
              className="font12"
              style={{
                color: styleObj.textStyle,
                lineHeight: "16px",
                textAlign: textAlign,
              }}
              id={`tooltip-${index}`}
            >
              {hoverText}
            </GothamRegular>
          ) : (
            hoverText
          )}
        </div>
      }
      {...props}
    >
      {hoverComponent}
    </CustomTooltip>
  );
};
