import { format, parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { isID, useForm } from "@tools";
import Skeleton from "react-loading-skeleton";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import {
  GothamBold,
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { fetch } from "../../../tools/api";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import {
  changeHour,
  createPCPayload,
  isDateExpired,
  pcDescription,
  spesificDateFormat,
} from "../formatter";
import {
  ChangeButton,
  DefaultOption,
  DefaultTag,
  OptionWithButton,
} from "./components";
import { optionsFormatter } from "./formatter";
import {
  AddPaymentCycleModal,
  PaymentOptionRightModal,
  SpesificDateModal,
} from "./modals";
import styles from "./style.module.css";
import emptyPC from "../../../assets/images/schedule_dashboard_empty.svg";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import { eventsTracker } from "../../../universalFunctions/events";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { enUS, id } from "date-fns/locale";
const formatNextDate = (date) => {
  const { locale } = useRouter();
  const isID = locale == "id";

  if (isID)
    return format(
      parseISO(date),
      "'Terdekat : 'cccc, d MMMM yyyy '('HH:mm')'",
      {
        locale: id,
      }
    );

  return format(parseISO(date), "'Next : 'cccc, d MMMM yyyy 'at' HH:mm", {
    locale: enUS,
  });
};

export const SpesificDateComponent = ({
  label,
  handleSave,
  defaultValue,
  children,
  onClick,
  subTitle = "Set payment date based on your preference.",
}) => {
  const { t } = useTranslation("schedule-payment/multi");
  const isValidDate = defaultValue?.payload?.date_and_time instanceof Date;
  const labelDecider = isValidDate
    ? spesificDateFormat(defaultValue?.payload?.date_and_time)
    : label;

  return (
    <div
      className={styles.option}
      onClick={() => {
        if (isValidDate) return;
        onClick();
      }}
      style={{
        backgroundColor: isValidDate && "#e6e6e8",
        // height: isValidDate && 44,
        height: 58,
        justifyContent: "space-between",
      }}
    >
      {/* <GothamRegular
        style={{ color: isValidDate ? "#343434" : "#6C6C71" }}
        className="font12"
      >
        {labelDecider}
      </GothamRegular> */}
      <div className="d-flex" style={{ gap: 12 }}>
        <JackIcons
          name="magic_wand"
          fill="#343434"
          style={{ width: 20, height: 20 }}
        />

        <div className="d-flex-column">
          <GothamMedium className="font12" style={{ color: "#343434" }}>
            {label}
          </GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {t(subTitle)}
          </GothamRegular>
        </div>
      </div>
      <JackIcons
        name="arrow_forward"
        fill="#343434"
        style={{ width: 20, height: 20 }}
      />
      {/* {isValidDate && <ChangeButton onClick={onClick} />} */}
      {/* <SpesificDateModal
        isOpen={open == "open"}
        toggle={() => set("close")}
        useFormObj={useFormObj}
        onSubmit={handleSave}
      /> */}
      {children}
    </div>
  );
};

export const PaymentCycleComponent = ({ label, payment, toggle }) => {
  const { t } = useTranslation("schedule-payment/multi");
  const isPaymentCycle = payment?.type == "payment_cycle";
  const paymentCycleValue = payment?.payload;
  const description = payment?.description;
  const nearestDate = paymentCycleValue?.date_and_time;
  // const newLabel = formatNextDate(paymentCycleValue?.nearest_date_from_today);
  const labelDecider = isPaymentCycle ? formatNextDate(nearestDate) : label;

  if (isPaymentCycle) {
    const { date_and_time, is_default } = paymentCycleValue;
    const formattedNextDate = formatNextDate(date_and_time);
    return (
      <DefaultOption
        label={description}
        subTitle={formattedNextDate}
        iconName="payment_cycle_2"
        flag={true}
      >
        <ChangeButton onClick={toggle} />
      </DefaultOption>
    );
  }

  return (
    <DefaultOption
      onClick={toggle}
      flag={isPaymentCycle}
      label={labelDecider}
      subTitle={t("Select payment cycle created by admin.")}
      iconName="payment_cycle_2"
      woChevronIcon={false}
    />
  );
};

export const PaymentCycleList = ({
  toggleComponent,
  product,
  setPayment,
  loading,
  list,
  refetch,
  canCreate = false,
  isOpenAdd,
  toggleAdd,
  woBack = false,
  containerStyle,
}) => {
  const { t } = useTranslation("schedule-payment/release");
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        position: "absolute",
        top: 50,
        left: 0,
        borderRadius: 8,
        padding: 8,
        boxShadow:
          "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
        zIndex: 10,
        ...containerStyle,
      }}
    >
      {!woBack && (
        <div
          style={{
            display: "flex",
            padding: "0px 8px",
            alignItems: "center",
            height: 40,
            borderRadius: 4,
          }}
          onClick={toggleComponent}
        >
          <JackIcons
            name="arrow_back_outline"
            fill="#6C6C71"
            style={{
              width: 16,
              height: 16,
              marginRight: 8,
              cursor: "pointer",
            }}
          />
          <GothamRegular className="font12" style={{ color: "#6C6C71" }}>
            Back
          </GothamRegular>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          height: "auto",
          maxHeight: 140,
          overflow: "auto",
        }}
      >
        {isEmpty(list) ? (
          <div
            style={{
              padding: 16,
              height: 136,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={emptyPC} style={{ height: 80, width: 100 }} />
            <GothamMedium>{t("There's no payment cycle")}</GothamMedium>
          </div>
        ) : (
          list?.map((data) => {
            const { nearest_date_from_today, is_default, id } = data;

            const formattedNextDate = formatNextDate(nearest_date_from_today);
            const isExpired = isDateExpired(nearest_date_from_today);

            const payment = createPCPayload(data);

            if (isExpired) return null;
            return (
              <div
                style={{
                  minHeight: 44,
                  height: "auto",
                  width: "100%",
                  padding: "1px 8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={styles.option}
                onClick={(e) => {
                  e?.stopPropagation();
                  setPayment(payment);
                }}
              >
                <div className="d-flex-column font12">
                  <GothamBold style={{ fontSize: 12, color: "#343434" }}>
                    {pcDescription(data)}
                  </GothamBold>
                  <GothamRegular className="font12">
                    {formattedNextDate}
                  </GothamRegular>
                </div>
                {is_default && <DefaultTag />}
              </div>
            );
          })
        )}
        {loading && <Skeleton height={44} width="100%" />}
      </div>
      {canCreate && (
        <ButtonJack
          leftIcon={
            <JackIcons
              name="plus_outline"
              fill="#6C6C71"
              style={{ width: 20, height: 20, marginRight: 8 }}
            />
          }
          style={{ width: "100%", backgroundColor: "#F1F1F1", marginTop: 4 }}
          onClick={(e) => {
            e?.stopPropagation();
            toggleAdd();
          }}
        >
          {t("Add Payment Cycle")}
        </ButtonJack>
      )}
      <AddPaymentCycleModal
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        product={product}
        refetch={refetch}
        setPayment={setPayment}
      />
    </div>
  );
};

export const ScheduledPaymentOptions = ({
  product,
  setPayment,
  payment,
  invoiceDueDate,
  woSpacing = false,
  isUserJackUltimate = false,
  isChooseDanamonBalance = false,
  data,
}) => {
  const paymentType = payment?.type;
  const isDefault = paymentType == "default";

  const selectedDateAndTime = payment?.payload?.date_and_time;
  const labelDate = spesificDateFormat(
    selectedDateAndTime,
    false,
    isID ? "id" : "default"
  );

  const handleChangeOption = (property, value) => {
    if (!property) return;

    eventsTracker("select_schedule_type", { property, value });

    if (property == "type") return setPayment({ type: value });

    return setPayment(value);
  };

  const {
    data: predefinedSchedules,
    loading,
    refetch: refetchList,
  } = fetch({
    url: `/predefined_schedules?q[transaction_type_eq]=${product}`,
    formatter: ({ data: rawData }) => optionsFormatter(rawData, invoiceDueDate),
    afterSuccess: ({ data: rawData }) => {
      const data = optionsFormatter(rawData, invoiceDueDate);
      const defaultPaymentCycle = data
        ?.filter(({ is_default }) => is_default)
        ?.pop();
      if (!isEmpty(defaultPaymentCycle)) {
        const { nearest_date_from_today, id } = defaultPaymentCycle;
        const expired = isDateExpired(nearest_date_from_today);
        if (expired) return;
        const payload = {
          is_scheduled: true,
          date_and_time: nearest_date_from_today,
          predefined_schedule_id: id,
        };
        const description = pcDescription(defaultPaymentCycle);

        const isSetPredefinedScheduled = isDefault && !isUserJackUltimate;
        // if user is Jack Ultimate, just fetch without set the default payment cycle.
        // So user don't have to remove schedule if they want to pay with Giro Danamon

        isSetPredefinedScheduled &&
          setPayment({ type: "payment_cycle", payload, description });
      }
    },
  });

  const { isOpen: isOpenRM, toggle: toggleRM } = useModalHook();

  const openOption = () => {
    eventsTracker("open_schedule_options");
    toggleRM();
  };

  return (
    <>
      <ScheduleOptionCard
        type={isDefault ? "normal" : "schedule"}
        subtitle={labelDate}
        onClick={openOption}
        woSpacing={woSpacing}
      />
      <PaymentOptionRightModal
        data={data}
        isOpen={isOpenRM}
        toggle={toggleRM}
        handleChangeOption={handleChangeOption}
        currentPayment={payment}
        predefinedSchedules={predefinedSchedules}
        refetchList={refetchList}
        loadingFetchList={loading}
        product={product}
        isChooseDanamonBalance={isChooseDanamonBalance}
      />
    </>
  );
};

const ScheduleOptionCard = ({
  type = "normal",
  subtitle,
  onClick,
  woSpacing = false,
}) => {
  const { t } = useTranslation("schedule-payment/release");

  const isSchedule = type == "schedule";
  const iconName = isSchedule ? "calendar" : "icon_paper_plane";
  const iconColor = isSchedule ? "#6087e7" : "#343434";
  const iconBackgroundColor = isSchedule
    ? "rgba(96, 135, 231, 0.15)"
    : "#f1f1f1";

  const componentOnclick = (e) => {
    e?.stopPropagation();
    onClick();
  };
  return (
    <div
      style={{
        height: isSchedule ? 92 : 60,
        margin: woSpacing ? "" : "32px 0px",
        backgroundColor: "#e6e6e8",
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
      }}
      onClick={componentOnclick}
    >
      <div
        style={{
          height: 60,
          width: "100%",
          backgroundColor: "white",
          padding: "12px 12px",
          display: "flex",
          justifyContent: "space-between",
          // backgroundColor: isBlue && "#F4F9FF",
          alignItems: "center",
          position: "relative",
          // zIndex: 10,
          cursor: "pointer",
          borderRadius: 8,
          border: "1px solid #e6e6e8",
        }}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   toggle();
        // }}
      >
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          <div
            style={{
              width: 32,
              height: 32,
              borderRadius: 32,
              backgroundColor: iconBackgroundColor,
              display: "grid",
              placeItems: "center",
            }}
          >
            <JackIcons
              name={iconName}
              fill={iconColor}
              style={{ width: 20, height: 20 }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <GothamMedium className="font12">
              {t("Payment Schedule")}
            </GothamMedium>
            <GothamRegular className="font12">
              {subtitle || t("Pay now")}
            </GothamRegular>
          </div>
        </div>
        <div
          style={{
            width: 32,
            height: 32,
            borderRadius: 8,
            border: "1px solid #e6e6e8",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
          onClick={componentOnclick}
        >
          <JackIcons
            name="edit_outline"
            fill="#343434"
            style={{ width: 16, height: 16 }}
          />
        </div>
      </div>
      {isSchedule && (
        <div
          style={{
            height: 32,
            width: "100%",
            padding: "8px 12px",
          }}
        >
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {t("We’ll deduct the funds later, on the scheduled payment date.")}
          </GothamRegular>
        </div>
      )}
    </div>
  );
};
