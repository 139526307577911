export const typeArrayKYB = [
  {
    // label: "PT (Limited Liability Company)",
    label: "PT (PERSEROAN TERBATAS)",
    value: "pt parent",
    obj: {
      title: "Type of Shares",
      array: [
        {
          label: "STATE-OWNED ENTERPRISES (BUMN/PERSERO/PERUM)",
          value: "Government Body",
          help: "Shares owned by the state",
        },
        {
          label: "PUBLIC COMPANY (TBK)",
          value: "Public Company",
          help: "Shares listed on the stock exchange",
        },
        {
          label: "PRIVATE (PT)",
          value: "Private Limited Company",
          help: "Shares owned by individuals and / or private entities",
        },
        {
          // label: "Individual Limited Company",
          label: "PERSEROAN PERORANGAN (INDIVIDUAL LIMITED COMPANY)",
          value: "Individual Limited Company",
          help: "Shares are fully owned by one individual.",
        },
      ],
    },
  },

  {
    // label: "CV (Commanditaire Vennootschap)",
    label: "CV (PERSEKUTUAN KOMANDITER)",
    value: "Partnership",
  },
  {
    // label: "PD/UD (Sole Proprietorship)",
    label: "PU/UD (PERUSAHAAN DAGANG/USAHA DAGANG)",
    value: "Sole Trader",
  },
  {
    // label: "Cooperatives",
    label: "KOPERASI",
    value: "Cooperatives",
  },
  {
    // label: "Association",
    label: "PERUSAHAAN ASOSIASI",
    value: "Association",
  },
  {
    // label: "Foundation",
    label: "YAYASAN",
    value: "Foundation",
  },
];

export const companySizes = (t) => [
  // { label: `1-49 ${t("employees")}`, value: "Less than or equal 49" },
  // { label: `50 - 249 ${t("employees")}`, value: "Between 50 to 249" },
  // { label: t(`More than 250 employees`), value: '"More than or equal 250"' },
  { label: `1 - 4 ${t("employees")}`, value: "1 - 4 employee" },
  { label: `5 - 19 ${t("employees")}`, value: "5 - 19 employee" },
  { label: `20 - 49 ${t("employees")}`, value: "20 - 49 employee" },
  { label: `50 - 200 ${t("employees")}`, value: "50 - 200 employee" },
  {
    label: `${t("More than")} 200 ${t("employees")}`,
    value: "More than 200 employee",
  },
];

export const monthlyTurnover = (t) => [
  { label: `< IDR 1 ${t("million")}`, value: "< IDR 1 million" },
  { label: `IDR 1 - 10 ${t("million")}`, value: "IDR 1 - 10 million" },
  { label: `> IDR 10 - 25 ${t("million")}`, value: "> IDR 10 - 25 million" },
  { label: `> IDR 25 - 50 ${t("million")}`, value: "> IDR 25 - 50 million" },
  {
    label: `> IDR 50 - 100 ${t("million")}`,
    value: "> IDR 50 - 100 million",
  },
  {
    label: `> IDR 100 - 500 ${t("million")}`,
    value: "> IDR 100 - 500 million",
  },
  {
    label: `> IDR 500 ${t("million")} - 1 ${t("billion")}`,
    value: "> IDR 500 million - 1 billion",
  },
  { label: `> IDR 1 - 5 ${t("billion")}`, value: "> IDR 1 - 5 billion" },
  { label: `> IDR 5 ${t("billion")}`, value: "> IDR 5 billion" },
];

export const annualTurnover = (t) => [
  { label: `< IDR 300 ${t("million")}`, value: "IDR 1 - 300 million" },
  {
    label: `IDR 300 ${t("million")} - 6 ${t("billion")}`,
    value: "IDR 300 million - 6 billion",
  },
  {
    label: `IDR 6 ${t("billion")} - 50 ${t("billion")}`,
    value: "IDR 6 billion - 50 billion",
  },
  {
    label: `IDR 50 ${t("billion")} - 350 ${t("billion")}`,
    value: "IDR 50 billion - 350 billion",
  },

  { label: `> IDR 350 ${t("billion")}`, value: "> IDR 350 billion" },
];
