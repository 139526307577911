import { noCase } from "change-case";
import React, { useEffect } from "react";
import { booleanState } from "../../components/Status";
import CardModal from "../../pageComponents/cardPageComponents/components/CardModal";
import { eventsTracker } from "../../universalFunctions/events";
import dynamic from "next/dynamic";
const RepaymentModal = dynamic(() =>
  import("../../pageComponents/cardPageComponents/components/RepaymentModal")
);
const AllocateModal = dynamic(() => import("./AllocateModal"));
const IncomingPaymentModal = dynamic(() => import("./IncomingPaymentModal"));
const ReAllocateModal = dynamic(() => import("./ReAllocateModal"));
const ApiDetailsModal = dynamic(() => import("./apiDetailsModal/parent"));
const CardTransactionModal = dynamic(() => import("./cardTransactionModal"));
const CrossBorderBatchModal = dynamic(() =>
  import("./crossBorderBatchModal/parent")
);
const CrossBorderSingleDetailsModal = dynamic(() =>
  import("./crossBorderSingleModal/parent")
);
const EWalletAllocationModal = dynamic(() =>
  import("./eWallet/allocation/parent")
);
const EWalletActivityModal = dynamic(() => import("./eWallet/default/parent"));
const EWalletReturnModal = dynamic(() => import("./eWallet/return/parent"));
const InvoicePaymentModal = dynamic(() => import("./invoicePayment/parent"));
const LocalDisbursementBatch = dynamic(() =>
  import("./localDisbursementBatchModal/parent")
);
const LocalTransferSingleModal = dynamic(() =>
  import("./localTransferSingleModal/parent")
);
const PayrollModal = dynamic(() => import("./payroll/parent"));
const RefundDetailsModal = dynamic(() => import("./refundDetailsModal"));
const ReimbursementModal = dynamic(() => import("./reimbursementModal"));
const TopupDetailsModal = dynamic(() => import("./topup/parent"));
const WithdrawDetailsModal = dynamic(() => import("./withdraw"));
const CardMoveBalanceModal = dynamic(() => import("./cardMoveBalanceModal"));
import { VaTransactionModal } from "../../pageComponents/virtualAccountComponents/details/parents";
import { MoveBalanceTransactionModal } from "../../pageComponents/moveBalanceComponents/components/detailModal";
import { GlobalTitleTransaction } from "./logic";
const JackTransferModal = dynamic(() => import("./jackTransfer"));
const JackDanamonInfo = dynamic(() => import("./jackDanamon"));

export const SmartActivityModal = ({
  isOpen,
  toggle,
  data: dataRaw,
  handleRefund = () => {}, // use this only from bank transfer OR local disbursementSingle
  ...props
}) => {
  if (!isOpen) return null;
  useEffect(() => {
    const { category, id } = data || {};
    eventsTracker("open_transaction_activities_modal", { category, id });
  }, []);

  const {
    type,
    category,
    description,
    originator_type,
    isCardRepayment,
    originator_id,
    source_account,
  } = dataRaw;

  const globalTitle = GlobalTitleTransaction(dataRaw);
  const data = { ...dataRaw, global_title: globalTitle };

  const isBatch =
    (type === "payroll" && originator_type === "Job") ||
    originator_type === "Job";
  const {
    isTopUp,
    isSingleTransaction,
    isBatchTransaction,
    isApiTransaction,
    isRefund,
    isIncomingPayment,
    isWithdraw,
    isAllocate,
    isCardTransaction: isCardTransactionRaw,
    isCardBillPayment,
    isReAllocate,
    isLocalDisbursement,
    isLocalDisbursementBatch,
    isPayroll,
    isWalletAllocation,
    isWalletReturn,
    isWalletHistory,
    isAllocationRequest,
    isReturnRequest,
    isInvoicePayment,
    isEWallet,
    isReimbursement,
    isLocalTransferSingle,
    isCard,
    isCollection,
    isCardMoveBalance: isCardMoveBalanceRaw,
    isCardReveal,
    isCardCreditTopUp: isCardCreditTopUpRaw,
    isJackTransfer,
    isJackDanamon,
    isDanamonMoveBalance,
    // isUser
  } = booleanState(type || category);
  const isJournalEntry = originator_type == "Hub::JournalEntry";
  const isCardTransaction =
    (isCardTransactionRaw || isCardMoveBalanceRaw || isCardCreditTopUpRaw) &&
    (source_account == "card_balance" ||
      source_account === "card" ||
      source_account === "main_balance" ||
      source_account === "danamon_balance" ||
      source_account === "danamon");
  const isCardMoveBalance =
    (isCardMoveBalanceRaw || isCardCreditTopUpRaw) && isJournalEntry;
  const defaultProps = { toggle, isOpen, data, ...props };
  const propsDecider = () => {
    if (isSingleTransaction || isLocalDisbursementBatch)
      return { ...defaultProps, handleRefund };
    if (isCardReveal)
      return {
        cardDetail: data,
        isReveal: true,
        ...defaultProps,
      };

    if (isCard) return { cardDetail: data, ...defaultProps };

    if (isCardBillPayment)
      return {
        transaction: isCardRepayment ? null : data,
        billId: isCardRepayment ? data?.id : null,
        ...defaultProps,
      };
    // if (isUser) {
    //   return {
    //     ...defaultProps,
    //     close: toggle,
    //   };
    // }
    if (isDanamonMoveBalance)
      return {
        ...defaultProps,
        fromTransactionPage: true,
        id: originator_id,
      };

    return defaultProps;
  };

  const getProps = propsDecider();

  if (isCollection)
    return (
      <VaTransactionModal
        modalControl={{ isOpen, toggle }}
        originatorId={originator_id}
        vaId={data?.details?.va_id}
        rawData={data}
      />
    );
  if (isAllocate) return <AllocateModal {...getProps} />;
  if (isReAllocate) return <ReAllocateModal {...getProps} />;
  if (isSingleTransaction)
    return <CrossBorderSingleDetailsModal {...getProps} />;
  if (isBatchTransaction || isBatch)
    return <CrossBorderBatchModal {...getProps} />;
  if (isTopUp) return <TopupDetailsModal {...getProps} />;
  if (isRefund) return <RefundDetailsModal {...getProps} />;
  if (isIncomingPayment) return <IncomingPaymentModal {...getProps} />; //Top Up VA
  if (isWithdraw) return <WithdrawDetailsModal {...getProps} />;
  if (isCard || isCardReveal) return <CardModal {...getProps} />;
  if (isCardMoveBalance) return <CardMoveBalanceModal {...getProps} />;
  if (isCardTransaction) return <CardTransactionModal {...getProps} />;
  if (isCardBillPayment) return <RepaymentModal {...getProps} />;
  if (isApiTransaction) return <ApiDetailsModal {...getProps} />;
  if (isLocalDisbursement) return <ApiDetailsModal notApi {...getProps} />;

  if (isLocalDisbursementBatch) return <LocalDisbursementBatch {...getProps} />;
  if (isLocalTransferSingle)
    return <LocalTransferSingleModal {...defaultProps} />;
  if (isPayroll) return <PayrollModal {...defaultProps} />;
  if (isDanamonMoveBalance)
    return <MoveBalanceTransactionModal {...getProps} />;
  // e-wallet
  const noCaseDescription = (string) =>
    noCase(description).includes(string) && isEWallet;
  const isBalanceAllocation = noCaseDescription("balance allocation");
  const isReturnBalance = noCaseDescription("return balance");
  if (isWalletHistory) return <EWalletActivityModal {...getProps} />;
  if (isWalletAllocation || isAllocationRequest || isBalanceAllocation)
    return <EWalletAllocationModal {...getProps} />;
  if (isWalletReturn || isReturnRequest || isReturnBalance)
    return <EWalletReturnModal {...getProps} />;
  if (isReimbursement) return <ReimbursementModal {...getProps} />;
  if (isInvoicePayment) return <InvoicePaymentModal {...getProps} />;
  // e-wallet
  if (isJackTransfer) return <JackTransferModal {...getProps} />;
  if (isJackDanamon) return <JackDanamonInfo {...getProps} />;
  toggle();
  return null;
};
