import { useLanguage } from "public/locales/translationFunctions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { booleanState } from "../../../../components/Status";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../../components/tools";
import { idrFormatterNegative } from "../../../../pageComponents/invoiceComponents/createPage/modals/formComponents/component";
import { pluralize } from "../../../../pageComponents/invoiceComponents/data/formatter";
import { useFetchHandlingFee } from "../../../../pageComponents/successComponents/logics/handlingFee";
import {
  isRetriedBatchDecider,
  retriedTrxCountDecider,
} from "../../retry-resubmit/helpers";
import {
  useCrossBorderSingleWorkflowApproverLogics,
  useDataInternationalSingle,
} from "../logics";
import { FreeComponents } from "../transferServiceModal/option";
import {
  handlingFeeBreakdownSingle,
  useArraySummaryLogic,
  useBankingHooks,
} from "./logic";
import { RecipientCardSmall } from "pageComponents/localTransferEnhancement/typescript/create/singleTransferComponents/trf-detail/components";
import { useAlertDecider } from "pageComponents/crossBorderSingleConfirmation";
import { useRouter } from "next/router";
import { isEmpty } from "lodash";

export const IconJackSize16 = ({
  style,
  icon = "jack-spade",
  fill = jackColors.neutral700,
}) => {
  return (
    <JackIcons
      name={icon}
      fill={fill}
      style={{ width: 16, height: 16, marginRight: 4, ...style }}
    />
  );
};

export const ReleaseAccordionHeader = ({
  onClick,
  show,
  icon,
  text = "Handling fee",
  styleIcon,
  styleText,
}) => {
  return (
    <div
      className="d-flex align-items-center"
      onClick={() => onClick((p) => !p)}
      style={{ cursor: "pointer" }}
    >
      {icon && <IconJackSize16 icon={icon} style={styleIcon} />}
      <span style={{ ...styleText }}>{text}</span>
      <IconJackSize16
        icon={show ? "chevron-up" : "chevron-down"}
        style={{ margin: "0px 0px 0px 4px" }}
        fill={jackColors.neutral700}
      />
    </div>
  );
};

export const ArrayReleaseDanamon = ({
  transaction,
  propsTransferService,
  isReleased = false,
  firstSpacer = true,
  loadingCalculateFee = false,
}) => {
  const { pathname } = useRouter();
  const isSuccessScreen = pathname.includes("/success");
  const { t } = useTranslation("payroll/create");
  const { t: tInter } = useTranslation("international/details");
  const { isBahasa } = useLanguage();
  // SUMMARY RELEASE TRANSAKSI KHUSUS UNTUK DANAMON
  const [showFirstArray, setShowFirstArray] = useState(false);
  const [showSecondArray, setShowSecondArray] = useState(false);
  const [showFee, setShowFee] = useState(false);
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const {
    showDetailsFee,
    dataHandlingFee,
    transferServiceRules,
    activeTransferBalance,
    activeTransferService,
    isUserJackUltimate,
  } = propsTransferService || {};
  const { t: tReim } = useTranslation("reimbursement/reimbursement");
  const { isBahasa: isID } = useLanguage();
  const isSelectedService = activeTransferService?.value;
  const isSelectedBalance = activeTransferBalance?.value;
  const { transaction_flag } = transferServiceRules || {};
  const { all_non_direct, all_to_danamon, all_to_ewallet } =
    transaction_flag || {};
  const hideServices = all_to_danamon || all_to_ewallet || all_non_direct;
  const isUserChooseService =
    showDetailsFee ||
    hideServices ||
    !isEmpty(activeTransferBalance) ||
    (!isUserJackUltimate && isSelectedBalance);

  // handling fee
  const arrayBreakDownFee = handlingFeeBreakdownSingle({
    dataHandlingFee,
    showFee,
    transaction,
    t: tLocalTransfer,
    isSelectedService,
    isSelectedBalance,
  });

  useEffect(() => {
    if (isUserChooseService || isReleased) {
      setShowFee(true);
    } else {
      setShowFee(false);
    }
  }, [isUserChooseService]);

  const {
    tax,
    vat,
    fee,
    rate,
    batch,
    amount,
    bank_fee,
    total_fee,
    sent_amount,
    total_amount,
    payment_channel,
    source_currency,
    total_trx_amount,
    destination_amount,
    local_transactions,
    destination_currency,
    destination_business,
    is_single_trx = false,
    id,
  } = transaction || {};

  const { name: businessName } = destination_business || {};
  const {
    local_transactions: local_transactions_batch,
    total_amount: total_amount_batch,
    total_fee: total_fee_batch,
  } = batch || {};

  const {
    paymentChannel,
    reimbursementBatchLength,
    totalAmountReimbursement,
    totalAmountBillPayment,
    totalAmountLocal,
    totalAmountPayroll,
    itemsBillPayment,
    feesTaxesBillPayment,
    listReimbursementTrx,
    sumAmountReimbursement,
  } = useArraySummaryLogic({
    transaction,
    propsTransferService,
    showFirstArray,
    showSecondArray,
    dataHandlingFee,
    isReleased,
  });

  const HandlingFeeComponent = () => {
    if (loadingCalculateFee)
      return [
        { loading: loadingCalculateFee },
        { loading: loadingCalculateFee },
      ];

    return arrayBreakDownFee;
  };

  const isRetriedLocal = isRetriedBatchDecider(local_transactions);
  const localRetriedTrxCount = retriedTrxCountDecider(local_transactions);

  const localTrxsCount = isRetriedLocal
    ? localRetriedTrxCount
    : local_transactions?.length;

  // const isSingleTransfer = local_transactions?.length === 1;
  const isSingleTransfer = is_single_trx;
  const transfezFee = Number(fee) - Number(bank_fee);
  const singleTransferRecipient =
    local_transactions?.[0]?.local_recipient || {};
  const singleTransferRecipientName = singleTransferRecipient?.name || "";
  const singleTransferRecipientBankName =
    singleTransferRecipient?.bank_name || "";
  const singleTransferRecipientAccNumber =
    singleTransferRecipient?.account_number || "";

  const arrayLocal = [
    {
      [firstSpacer ? "spacer" : "dummy"]: true,
    },
    ...(isSingleTransfer
      ? [
          {
            title: "Recipient",
            value: singleTransferRecipientName,
            value2: `${singleTransferRecipientBankName} - ${singleTransferRecipientAccNumber}`,
          },
        ]
      : []),
    ...(!isSingleTransfer
      ? [
          {
            title: tLocalTransfer("Number of transaction"),
            value: `${localTrxsCount} ${tLocalTransfer(
              pluralize(localTrxsCount, "Transaction")
            )}`,
          },
        ]
      : []),
    {
      title: isSingleTransfer
        ? tLocalTransfer("Amount")
        : tLocalTransfer("Total amount"),
      value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
    },
    ...HandlingFeeComponent(),
    {
      spacer: true,
    },
    {
      title: isReleased
        ? tLocalTransfer(`Paid amount`)
        : tLocalTransfer(`Amount to be paid`),
      value: isUserChooseService
        ? `IDR ${formatCurrencyNoDecimal(totalAmountLocal)}`
        : "--",
      leftBold: true,
      rightBold: true,
    },
  ];

  const isRetriedPayroll = isRetriedBatchDecider(local_transactions_batch);
  const payrollRetriedTrxCount = retriedTrxCountDecider(
    local_transactions_batch
  );

  const payrollTrxsCount = isRetriedPayroll
    ? payrollRetriedTrxCount
    : local_transactions_batch?.length;

  const arrayPayroll = [
    {
      [firstSpacer ? "spacer" : "dummy"]: true,
    },
    {
      title: t("Number of transaction"),
      value: `${payrollTrxsCount} ${t(
        pluralize(payrollTrxsCount, "Transaction")
      )}`,
    },
    {
      title: t("Total amount"),
      value: `IDR ${formatCurrencyNoDecimal(total_amount_batch)}`,
    },
    ...HandlingFeeComponent(),
    {
      spacer: true,
    },
    {
      title: isReleased ? t("Paid amount") : t("Amount to be paid"),
      value: isUserChooseService
        ? `IDR ${formatCurrencyNoDecimal(totalAmountPayroll)}`
        : "--",
      leftBold: true,
      rightBold: true,
    },
  ];

  const arrayBillPayment = [
    {
      [firstSpacer ? "spacer" : "dummy"]: true,
    },
    {
      title: (
        <ReleaseAccordionHeader
          onClick={setShowFirstArray}
          show={showFirstArray}
          icon="shopping-cart-outline"
          text={tLocalTransfer("Items subtotal")}
        />
      ),
      value: `IDR ${formatCurrencyNoDecimal(+amount)}`,
    },
    ...itemsBillPayment,
    {
      title: (
        <ReleaseAccordionHeader
          onClick={setShowSecondArray}
          show={showSecondArray}
          icon="discount"
          text={tLocalTransfer("Fees & taxes")}
        />
      ),
      styleMainDiv: { gridTemplateColumns: "60% 40%" },
      value: idrFormatterNegative(+tax),
    },
    ...feesTaxesBillPayment,
    {
      spacer: true,
    },
    {
      title:
        isReleased && !isSuccessScreen
          ? tLocalTransfer(`Amount`)
          : tLocalTransfer(`Invoice amount`),
      value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
      rightBold: isReleased ? true : false,
    },
    ...HandlingFeeComponent(),
    {
      spacer: true,
    },
    {
      title: isReleased ? t(`Paid amount`) : t(`Amount to be paid`),
      value: isUserChooseService
        ? `IDR ${formatCurrencyNoDecimal(totalAmountBillPayment)}`
        : "--",
      leftBold: true,
      rightBold: true,
      styleMainDiv: { gridTemplateColumns: "60% 40%" },
    },
  ];

  const arrayReimbursement = [
    {
      [firstSpacer ? "spacer" : "dummy"]: true,
    },
    {
      title: "Number of expense",
      value: `${reimbursementBatchLength} ${
        isID ? "Pengeluaran" : pluralize(reimbursementBatchLength, "Expense")
      }`,
    },
    {
      title: (
        <ReleaseAccordionHeader
          onClick={setShowFirstArray}
          show={showFirstArray}
          text={tLocalTransfer("Total amount")}
        />
      ),
      value: `IDR ${formatCurrencyNoDecimal(sumAmountReimbursement)}`,
      rightBold: isReleased ? true : false,
    },
    ...listReimbursementTrx,
    ...HandlingFeeComponent(),
    { spacer: true },
    {
      title: isReleased ? tReim(`Paid amount`) : tReim(`Amount to be paid`),
      value: isUserChooseService
        ? `IDR ${formatCurrencyNoDecimal(totalAmountReimbursement)}`
        : "--",
      leftBold: true,
      rightBold: true,
      styleMainDiv: { gridTemplateColumns: "60% 40%" },
    },
  ];

  // const transfezFee = Number(fee) - Number(bank_fee);
  const totalFee = Number(fee) + Number(vat);
  const deliveryMethod = useAlertDecider({ data: payment_channel });

  const arrayInternational = [
    { spacer: true },
    {
      title: isReleased ? tInter("Paid amount") : tInter("Amount to be paid"),
      value: `${source_currency} ${formatCurrencyNoDecimal(
        isReleased
          ? Number(sent_amount) + Number(total_fee)
          : Number(total_trx_amount)
      )}`,
      leftBold: true,
      rightBold: true,
    },
    {
      title: tInter("Transfer service (international)"),
      value: deliveryMethod,
      styleMainDiv: { gridTemplateColumns: "60% 40%" },
    },
    {
      title: tInter("Total fee"),
      value: `${source_currency} ${formatCurrencyNoDecimal(totalFee)}`,
    },
    {
      title: tInter("Handling fee"),
      value: `${source_currency} ${formatCurrencyNoDecimal(transfezFee)}`,
      showAccordion: true,
      descAccordion: true,
      styleValue: { color: jackColors.neutral700 },
    },
    {
      title: tInter("Bank fee"),
      value: `${source_currency} ${formatCurrencyNoDecimal(bank_fee)}`,
      showAccordion: true,
      descAccordion: true,
      styleValue: { color: jackColors.neutral700 },
    },
    {
      title: tInter("VAT on fees"),
      value: `${source_currency} ${formatCurrencyNoDecimal(vat)}`,
      showAccordion: true,
      descAccordion: true,
      isLastAccordion: true,
      styleValue: { color: jackColors.neutral700 },
    },
    {
      title: tInter("Exchange rate"),
      value: `${destination_currency} 1 = ${source_currency} ${formatCurrency(
        rate
      )}`,
      customIcon: "divide-circle",
    },
    {
      spacer: true,
    },

    {
      title: tInter("Recipient gets"),
      value: `${destination_currency} ${formatCurrency(destination_amount)}`,
    },
  ];

  // const arrayInternational = [
  //   {
  //     [firstSpacer ? "spacer" : "dummy"]: true,
  //   },
  //   {
  //     title: tInter("Transfer service (international)"),
  //     value: paymentChannel,
  //   },
  //   {
  //     title: tInter("Paid amount"),
  //     value: `IDR ${formatCurrencyNoDecimal(total_trx_amount)}`,
  //     // value2: `Fee included`,
  //     leftBold: true,
  //     rightBold: true,
  //   },
  // ];

  const arrayBalanceTransfer = [
    {
      [firstSpacer ? "spacer" : "dummy"]: true,
    },
    {
      title: isBahasa ? "Kirim ke" : "Transfer to",
      value: businessName,
    },
    {
      title: isBahasa ? "Nominal transfer" : "Transfer amount",
      value: `IDR ${formatCurrencyNoDecimal(+amount || 0)}`,
      rightBold: true,
    },
    { spacer: true },
    {
      title: isReleased ? t(`Paid amount`) : t(`Amount to be paid`),
      value: `IDR ${formatCurrencyNoDecimal(+amount || 0)}`,
      leftBold: true,
      rightBold: true,
    },
  ];
  const arrayWithdrawal = [
    {
      [firstSpacer ? "spacer" : "dummy"]: true,
    },
    {
      title: isReleased ? t(`Paid amount`) : t(`Amount to be paid`),
      value: `IDR ${formatCurrencyNoDecimal(+amount || 0)}`,
      leftBold: true,
      rightBold: true,
    },
  ];

  return {
    arrayLocal,
    totalAmountLocal,
    arrayPayroll,
    totalAmountPayroll,
    arrayBillPayment,
    totalAmountBillPayment,
    arrayInternational,
    arrayReimbursement,
    totalAmountReimbursement,
    arrayBalanceTransfer,
    arrayWithdrawal,
  };
};

export const ArrayAfterReleaseBanking = ({
  data,
  type,
  isSuccessScreen = false,
  receiptId = [],
  isRetried,
  isSingleTransfer = false,
}) => {
  const { isUserJackUltimate } = useBankingHooks();
  const {
    state,
    payment_method: paymentMethod,
    transfer_service: transferService,
    batch,
  } = data || {};
  const {
    payment_method: paymentMethodBatch,
    transfer_service: transferServiceBatch,
  } = batch || {};

  const payment_method = paymentMethod || paymentMethodBatch;
  const transfer_service = transferService || transferServiceBatch;

  const {
    isComplete,
    isCompleted,
    isOnProgress,
    isFailed,
    isPartialFailed,
    isDeclined,
    isWaitingForConfirmation,
    isValidatingPayment,
  } = booleanState(state);

  const stateAfterPayments =
    // (isReleaser && isWaitingApprovalTask) ||
    isComplete ||
    isCompleted ||
    isOnProgress ||
    isFailed ||
    isPartialFailed ||
    isDeclined ||
    isWaitingForConfirmation ||
    isValidatingPayment;

  // BANKING ========
  const { releaserTaskId } = useCrossBorderSingleWorkflowApproverLogics(data);

  const { dataHandlingFee, loadingCalculateFee } = useFetchHandlingFee({
    taskId: releaserTaskId,
    payment_method,
    service: transfer_service,
    receiptId,
    isRetried,
  });

  const propsTransferService = {
    isUserJackUltimate,
    dataHandlingFee,
    showDetailsFee: true,
  };

  const { arrayBillPayment, arrayLocal, arrayPayroll, arrayReimbursement } =
    ArrayReleaseDanamon({
      transaction: { type, ...data },
      propsTransferService,
      isReleased: true,
      firstSpacer: isSuccessScreen ?? false,
      loadingCalculateFee,
    });

  const ArrayDecider = () => {
    if (type == "local_transfer") return arrayLocal;
    if (type == "payroll") return arrayPayroll;
    if (type == "reimbursement") return arrayReimbursement;
    if (type == "invoice") return arrayBillPayment;
  };

  return { array: ArrayDecider(), stateAfterPayments };
};
