import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { ProgressBarJack } from "../../components/Stepper";
import { localUserStatusBoolean, useGetAuth } from "../../contexts/AuthContext";
import { useHeader } from "../../contexts/Layout/parent";
import { fetch } from "../../tools/api";
import { isEmpty } from "lodash";
import { windowDimension } from "../../components/tools";
import { GothamMedium } from "../../components/Text";
import { useFormOfBusinessDecider } from "./formType";
import SwitchLocaleButton from "components/LocaleButton";
import { useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

export const useIsKYBReviewed = () => {
  const { user } = useGetAuth();

  const { partner } = user || {};
  const { all_flags } = partner || {};
  const isReview = (all_flags || []).includes("REVIEWED_KYB");

  return { isReview };
};

export const kybPathnames = () => {
  const onboardPathname = "/kyb-jack/onboard";
  const detailsPathname = "/kyb-jack/details";
  const bankPathname = "/kyb-jack/bank";
  const representativePathname = "/kyb-jack/representative";
  const structurePathname = "/kyb-jack/structure";
  const supportingPathname = "/kyb-jack/support";
  const reviewPathname = "/kyb-jack/review";
  const finishPathname = "/kyb-jack/finish";
  const onReviewPathname = "/kyb-jack/on-review";
  const onReviewPendingPathname = "/kyb-jack/on-review?isReview=true";

  return {
    onboardPathname,
    bankPathname,
    representativePathname,
    detailsPathname,
    structurePathname,
    supportingPathname,
    reviewPathname,
    finishPathname,
    onReviewPathname,
    onReviewPendingPathname,
  };
};

export const useKYBHeader = (activeIndexProps) => {
  const {
    bankPathname,
    detailsPathname,
    representativePathname,
    structurePathname,
    supportingPathname,
    onReviewPathname,
    reviewPathname,
    onboardPathname,
  } = kybPathnames();
  const { setHeader } = useHeader();

  const isNumber = typeof activeIndexProps == "number";

  const { pathname, push } = useRouter();
  const { isBahasa } = useLanguage();
  const {
    data: isSupportingFilled,
    refetch: refetchSupporting,
    loading: loadingSupporting,
  } = fetch({
    url: "/business_documents?q[shareholder_id_null]=true",
    formatter: (res) => {
      const isFilled = (res?.data || [])
        .filter(({ is_optional }) => !is_optional)
        .every(({ file_details }) => !isEmpty(file_details));
      return isFilled;
    },
  });

  const {
    data: isBankFilled,
    refetch: refetchBank,
    loading: loadingBank,
  } = fetch({
    url: "/partner_bank_accounts?q[main_acc_eq]=true",
    formatter: (res) => !!(res?.data || []).length,
    defaultValue: false,
  });

  const { isNoBO } = useFormOfBusinessDecider();

  const {
    data: isStructureFilledRaw,
    refetch: refetchStructure,
    loading: loadingStructure,
  } = fetch({
    url: "/shareholders",
    formatter: (res) => {
      if (isNoBO) return !!res.data.length;

      const boolean = !!(res?.data || []).find(
        ({ is_major_ubo, relation }) => is_major_ubo && relation
      );
      return boolean;
    },
    defaultValue: false,
    woInit: true,
  });

  useEffect(() => {
    if (isEmpty(user)) return;
    refetchStructure();
  }, [isEmpty(user)]);

  const { user, userLoading, refetchUser } = useGetAuth();
  const { agree_bo_declaration } = user?.partner || {};

  const isStructureFilled = isNoBO
    ? isStructureFilledRaw
    : isStructureFilledRaw && agree_bo_declaration;

  const loading =
    userLoading || loadingStructure || loadingBank || loadingSupporting;

  const chainedRefetch = () => {
    refetchBank();
    refetchUser();
    refetchStructure();
    refetchSupporting();
  };

  useEffect(chainedRefetch, [pathname]);

  const { isPending } = localUserStatusBoolean(user);

  const { partner, is_director } = user || {};
  const { director_name, form_of_business } = partner || {};

  const { isReview } = useIsKYBReviewed();

  const isRepresentativeFilled = !!director_name || is_director;

  const isDetailsFilled = !!form_of_business;

  const isSupporting = pathname == supportingPathname;

  useEffect(() => {
    if (loading) return;

    // const isBankOrRep =
    //   pathname == bankPathname || pathname == representativePathname;

    if (isPending) {
      if (isSupporting) return;
      if (pathname == onReviewPathname) return;

      return push(onReviewPathname);
    }

    // if (pathname == bankPathname || pathname == representativePathname) {
    //   if (isDetailsFilled) return;
    //   return push(detailsPathname);
    // }

    // if (pathname == structurePathname) {
    //   if (isRepresentativeFilled) return;
    //   return push(representativePathname);
    // }

    // if (isSupporting) {
    //   if (isStructureFilled) return;
    //   if (isReview) return;
    //   return push(structurePathname);
    // }
  }, [pathname, loading]);

  const activeIndexFromKYBState = () => {
    if (isReview) return 5;
    if (isStructureFilled) return 4;
    if (isRepresentativeFilled) return 3;
    if (isBankFilled) return 2;
    if (isDetailsFilled) return 1;
    return activeIndexProps;
  };

  const isActiveIndexDecider = () => {
    if (activeIndexProps > activeIndexFromKYBState()) return activeIndexProps;

    return activeIndexFromKYBState();
  };

  const activeIndex = isActiveIndexDecider();
  const { t } = useTranslation("kyb/kyb");

  const array = useMemo(
    () =>
      [
        { step: t("Business Details") },
        {
          step: t("Banking Details"),
          stillActive: !isBankFilled,
        },
        {
          step: t("Business Representatives"),
        },
        { step: t("Company Structure"), stillActive: !isStructureFilled },
        {
          step: t("Supporting Documents"),
          stillActive: !isSupportingFilled,
        },
        {},
      ]
        .map((item, index) => {
          const isActive = activeIndex === index;
          if (isActive) return { ...item, isActive };
          return item;
        })
        .map((item, index) => {
          const { stillActive } = item;
          if (!stillActive) return item;

          if (index > activeIndex) delete item["stillActive"];
          return item;
        }),
    [isBankFilled, isStructureFilled, isSupportingFilled, isBahasa, activeIndex]
  );

  const { isTabOrPhone } = windowDimension();

  useEffect(
    () =>
      setHeader({
        screen: isTabOrPhone ? (
          <KybHeaderMobile
            array={array}
            hide={!isNumber || pathname == reviewPathname}
          />
        ) : (
          <KYBHeader
            array={array}
            middleComponent={
              isNumber && (
                <ProgressBarJack
                  arrayStep={array}
                  customLineLength={200}
                  realActiveIndex={activeIndexProps}
                />
              )
            }
            style={onboardPathname && { marginBottom: 0 }}
          />
        ),
      }),
    [loading, isTabOrPhone, array]
  );

  return {
    array,
    loading,
    isSupportingFilled,
    isBankFilled,
    isStructureFilled,
    isReview,
    isRepresentativeFilled,
    isDetailsFilled,
  };
};

export const KYBHeader = ({ middleComponent, onClickLogout, style }) => {
  const { push } = useRouter();
  const { unauthorize } = useGetAuth();
  const { t: tCommon } = useTranslation("common");

  return (
    <div
      className="d-flex w-100 justify-content-between align-items-center"
      style={{
        height: 80,
        paddingLeft: 32,
        paddingRight: 32,
        marginBottom: 32,
        ...style,
      }}
    >
      <JackIcons name="Fullsize" />
      <div>{middleComponent}</div>
      <div style={{ display: "flex", gap: 20 }}>
        <SwitchLocaleButton />
        <ButtonJack
          onClick={() => {
            unauthorize();
            push("/login");
            onClickLogout && onClickLogout();
          }}
          type="outline"
          rightIcon={
            <JackIcons name="log-out-outline" fill={jackColors.black34} />
          }
        >
          {tCommon("Log out")}
        </ButtonJack>
      </div>
    </div>
  );
};

const KybHeaderMobile = ({ array: arrayProps, hide }) => {
  if (hide) return null;
  const sliced = arrayProps.slice(0, -1);

  const array = sliced.map((item, index) => {
    const isLast = index === sliced.length - 1;
    const { stillActive } = item;

    if (isLast) {
      if (stillActive) return { ...item, isActive: true };
      return item;
    }
    return item;
  });

  const { pathname } = useRouter();

  const stepDecider = () => {
    const getStep = (index) => array[index].step;
    const {
      bankPathname,
      representativePathname,
      structurePathname,
      supportingPathname,
    } = kybPathnames(pathname);

    const isSecond = pathname == bankPathname;
    const isThird = pathname == representativePathname;
    const isFourth = pathname == structurePathname;
    const isFifth = pathname == supportingPathname;

    if (isFifth) return getStep(4);

    if (isFourth) return getStep(3);
    if (isThird) return getStep(2);

    if (isSecond) return getStep(1);

    return getStep(0);
  };

  const activeStepName = stepDecider();

  const activeIndex = array
    .map(({ isActive }, index) => {
      if (isActive) return index;
    })
    .filter((item) => typeof item == "number")[0];

  const ref = useRef();
  const height = (ref?.current?.clientHeight || 64) + 16;

  return (
    <>
      <div
        className="d-flex align-items-center "
        style={{
          padding: 12,
          flexDirection: "column",
          gap: 18,
          position: "fixed",
          width: "100vw",
          backgroundColor: "white",
          zIndex: 100,
        }}
        ref={ref}
      >
        <GothamMedium>{activeStepName}</GothamMedium>
        <div className="d-flex" style={{ gap: 8, width: "100%" }}>
          {array.map((item, index) => {
            const { isActive, stillActive } = item;
            const isPassed = activeIndex >= index;
            return (
              <div
                style={{
                  width: "100%",
                  height: 2,
                  backgroundColor:
                    isActive || stillActive
                      ? jackColors.yellowFF
                      : isPassed
                      ? jackColors.black34
                      : jackColors.greyE6,
                  transition: "all 0.4s linear",
                }}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <div style={{ height }}></div>
    </>
  );
};
