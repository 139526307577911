import React, { useEffect } from "react";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import {
  BackButton,
  BannerQuotaTransferServices,
  CardsTransferService,
  MainCardTransferService,
  ModalInfoQuota,
  TransferServiceTitle,
} from "./components";
import { useOptionUltimateTransfer, OptionValueTransfer } from "./option";
import { useModalHook } from "../../../../components/Modals";
import { useBooleanDanamonProduct } from "../DanamonReleasePayment/boolean";
import { CustomBannerBanking } from "../DanamonReleasePayment/sourceOfPayment";
import { getTotalTrxCalculateFee } from "./logic";
import { eventsTracker } from "../../../../universalFunctions/events";
import { Trans, useTranslation } from "react-i18next";

export const TransferServiceModal = ({
  data,
  tasks = [],
  isMultiRelease,
  isOpen,
  toggle,
  optionRules,
  dataHandlingFee,
  activeTransferService,
  activeTransferBalance,
  setActiveService = () => {},
}) => {
  const { isCollectionPocket } = useBooleanDanamonProduct(
    activeTransferBalance?.value
  );
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  // Transfez Service Option
  const {
    option: optionUltimate,
    ObjOptimized,
    isPayroll,
    isVirtualAccount,
    objRulesBiFast,
    objRulesOptimized,
  } = useOptionUltimateTransfer({
    optionRules,
    isMultiRelease,
    data,
    t: tLocalTransfer,
  });

  // Modal stuff
  const { isOpen: isOpenInfoQuota, toggle: toggleInfoQuota } = useModalHook();
  const { toToggle, toListener } = useSmoothRightModal();

  const openInfoQuota = () => {
    eventsTracker("open_info_quota_release_payment");
    toggleInfoQuota();
  };

  const onSubmitChangeService = (service) => {
    eventsTracker("choose_transfer_service_release_payment");
    setActiveService(service);
    toToggle();
  };

  useEffect(() => {
    // change balance to pocket -> service automaticly optimized_route
    if (isCollectionPocket) {
      setActiveService(ObjOptimized);
    } else {
      setActiveService({});
    }
  }, [activeTransferBalance?.value]);

  const propsOption = {
    isPayroll,
    isVirtualAccount,
    array: optionUltimate,
    activeTransferService,
    setActive: onSubmitChangeService,
  };

  // quota & limit
  const { quota, remaining, used, outOfQuota, partialFree } =
    getTotalTrxCalculateFee({ dataHandlingFee });
  const propsLimitQuota = {
    quota,
    remaining,
    used,
    outOfQuota,
    partialFree,
  };

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        noPadding={true}
        width={[420]}
        listener={toListener}
        array={[
          <div style={{ padding: 16, position: "relative" }}>
            <BackButton onClick={() => toToggle()} />
            <TransferServiceTitle isPayroll={isPayroll} t={tLocalTransfer} />

            {partialFree || outOfQuota ? (
              <div onClick={openInfoQuota} style={{ cursor: "pointer" }}>
                <CustomBannerBanking
                  title={
                    partialFree ? (
                      // ? `Heads up! You only have ${remaining} free transfers left`
                      <Trans
                        i18nKey={tLocalTransfer("dynamic.dynamic_text_19", {
                          remaining,
                        })}
                      />
                    ) : (
                      tLocalTransfer("You ran out of free transfer quota")
                    )
                  }
                  msg={
                    partialFree
                      ? tLocalTransfer(
                          "Regular fees will apply after you exceed this number."
                        )
                      : tLocalTransfer(
                          "Regular fees will apply until the quota resets next month."
                        )
                  }
                />
              </div>
            ) : (
              <BannerQuotaTransferServices
                onClick={openInfoQuota}
                t={tLocalTransfer}
              />
            )}
            <div id="task-fourth">
              <MainCardTransferService {...propsOption} t={tLocalTransfer} />
            </div>
          </div>,
        ]}
      />
      <ModalInfoQuota
        toggle={toggleInfoQuota}
        isOpen={isOpenInfoQuota}
        propsLimitQuota={propsLimitQuota}
      />
    </>
  );
};
