import { jackColors } from "assets/colors";
import { JackIcons } from "assets/jackIcons/parent";
import { ButtonJack } from "components/ButtonsJack/parent";
import FileInputJack from "components/typescript/inputs/fileInputs/fileInput";
import { useModalWidth } from "components/Modals";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
import { windowDimension } from "@tools";
import { Banner } from "components/typescript/Banner";
import { TableJackSimple } from "components/typescript/Table/parent";
import { TransitionTr } from "components/typescript/Transition";
import { dataContent } from "modals/typescript/guideTemplateModalPayroll";
import {
  contentModalCustomPayroll,
  detailSecondStepModalLocalFunc,
} from "modals/typescript/guideTemplateModalPayroll/constant";
import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import ViewportList from "react-viewport-list";
import { Modal } from "reactstrap";
import { DataContentType } from "types/modals/guide_template_jack/type";
import { FormDataLocalTransfer } from "../typeLocalTransfer";

type ModalOptionalCreateTransfer = {
  toggle: any;
  isOpen: boolean;
  onClickDownload?: () => void;
  useFormObj: UseFormReturn<FormDataLocalTransfer>;
  woButton?: boolean;
  handleClick: (props: { type: string; values: string }) => void;
  selectedItem: string;
};
const ModalOptionalCreateTransfer: React.FC<ModalOptionalCreateTransfer> = ({
  toggle,
  isOpen,
  onClickDownload,
  useFormObj,
  woButton,
  handleClick,
  selectedItem,
}) => {
  const [loading, setLoading] = useState(false);
  const { watch } = useFormObj;
  const isHasValue = watch("upload_file");
  const modalWidth = useModalWidth(480);
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const arrayUploadDetails = [
    {
      title: tLocalTransfer("1. Download this template"),
      desc: tLocalTransfer(
        "Download and fill in the transfer details according to this template."
      ),
      actionTemplate: (
        <ButtonJack
          onClick={onClickDownload}
          className="w-100"
          type="outline"
          children="Download template"
          style={{ height: 32 }}
          rightIcon={
            <JackIcons
              style={{ width: 20, height: 20 }}
              name="download-outline"
              fill={jackColors.neutral900}
            />
          }
        />
      ),
    },
    {
      title: tLocalTransfer("2. Upload the filled template"),
      desc: tLocalTransfer(
        "Upload the filled Excel to start creating your transaction."
      ),
      actionTemplate: (
        <FileInputJack
          showLabel={false}
          fileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          useFormObj={useFormObj}
          name="upload_file"
          firstCopy={tLocalTransfer("Browse or drag files here")}
          multiple={false}
          customError={tLocalTransfer(
            "Please ensure you upload a supported file type (Excel)."
          )}
        />
      ),
    },
  ];
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
    >
      <div
        style={{
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            borderColor: jackColors.greyE6,
            alignItems: "center",
          }}
        >
          <GothamMedium className="font20">Upload Excel</GothamMedium>
          <div
            className="hover-600"
            style={{
              width: 40,
              height: 40,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 15,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        {arrayUploadDetails?.map((item, index) => {
          const { title, desc, actionTemplate } = item;
          return (
            <div key={index} style={{ padding: 20 }}>
              <div>
                <GothamMedium className="mb-1">{title}</GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral700, marginBottom: 16 }}
                >
                  {desc}
                </GothamRegular>
                {actionTemplate}
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ margin: "0px 20px" }}>
        <Banner
          msg={
            <Trans
              i18nKey={tLocalTransfer("dynamic.style_text_15")}
              components={[
                <span
                  style={{
                    fontFamily: "GothamMedium",
                    textDecoration: "underline",
                    textDecorationThickness: "1.5px",
                    textUnderlineOffset: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleClick({ type: "open_guide_modal", values: "" })
                  }
                />,
              ]}
            />
          }
          type="information"
        />
      </div>

      {!woButton && (
        <div
          className="d-flex justify-content-end"
          style={{
            gap: 16,
            marginTop: 32,
            padding: 20,
            borderTop: `1px solid ${jackColors.greyE6}`,
          }}
        >
          <ButtonJack
            type="outline"
            children={tLocalTransfer("Back")}
            style={{ minWidth: 65 }}
            onClick={() => handleClick({ type: "back", values: selectedItem })}
            leftIcon={
              <JackIcons
                name="arrow-back-outline"
                fill="black"
                style={{ width: 20, height: 20 }}
              />
            }
          />
          <ButtonJack
            disabled={!isHasValue || loading}
            isLoading={loading}
            type="filled"
            children={tLocalTransfer("Upload")}
            style={{ minWidth: 60 }}
            onClick={() => {
              setLoading(true);
              handleClick({ type: "next", values: isHasValue });
            }}
            rightIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="arrow-forward"
                fill={!isHasValue ? jackColors.neutral600 : "black"}
              />
            }
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalOptionalCreateTransfer;

interface ModalFailPassCheckerProps {
  isOpen: boolean;
  toggle: () => void;
  title?: string;
  buttonRightText?: string;
  buttonLeftText?: string;
  isShowAlert?: boolean;
  onClickRight?: () => void;
  data: any; // Update this type based on the structure of the `data` prop
}

type InvalidValueData = {
  invalidValue: string;
  row: string;
};

type TableHeader = {
  label: React.ReactNode;
  width: string;
};

export const useInvalidValueCSV = (data: InvalidValueData[]) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const tbodyRef = useRef<HTMLTableSectionElement | null>(null);

  const tableTotalWidth = 1136;
  const tableWidth = (number: number): string =>
    `${(number / tableTotalWidth) * 100}%`;

  const headerArr: TableHeader[] = [
    {
      label: (
        <GothamMedium key="style_text_31">
          <Trans
            i18nKey={tLocalTransfer(`dynamic.style_text_31`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.redDC,
                }}
              />,
            ]}
          />
        </GothamMedium>
      ),
      width: tableWidth(184),
    },
    {
      label: (
        <GothamMedium key="style_text_32">
          <Trans
            i18nKey={tLocalTransfer(`dynamic.style_text_32`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.redDC,
                }}
              />,
            ]}
          />
        </GothamMedium>
      ),
      width: tableWidth(547),
    },
  ];

  const bodyTable: InvalidValueData[] = data.map((item) => ({
    invalidValue: item.invalidValue,
    row: item.row,
  }));

  return { headerArr, tbodyRef, bodyTable };
};

export const ModalFailPassChecker: React.FC<ModalFailPassCheckerProps> = ({
  isOpen,
  toggle,
  title = "Upload Guide",
  buttonRightText = "Got it!",
  buttonLeftText = "Close",
  isShowAlert = false,
  onClickRight = () => {},
  data,
}) => {
  const [indexModal, setIndexModal] = useState<number>(0);
  const modalWidth = useModalWidth(650);
  const { height: heightWindow } = windowDimension();
  const { t } = useTranslation("local-transfer/local-transfer");
  const detailSecondStepModalLocal: (string | JSX.Element)[] =
    detailSecondStepModalLocalFunc({ t, type: "invalid_format_csv" });
  const onChange = (index: number) => {
    setIndexModal(index);
  };
  const descriptionGuidance: DataContentType[] = dataContent(t);
  const { locale } = useRouter();
  const isIndo = locale === "id";

  const selectedContent = contentModalCustomPayroll({
    onChange,
    t,
    isIndo,
    type: "modal_csv",
    data: descriptionGuidance,
  });

  const { headerArr, bodyTable, tbodyRef } = useInvalidValueCSV(data);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
          justifyContent: "start",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            alignItems: "center",
          }}
        >
          <GothamMedium className="font20">{t(title)}</GothamMedium>
          <div
            className="hover-600"
            style={{
              width: 40,
              height: 40,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 15,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        <div
          style={{
            margin: "0px 20px",
            overflowY: "auto", // Enable vertical scrolling
            maxHeight: "calc(80vh - 120px)", // Adjust to fit within the modal
          }}
        >
          <div style={{ marginTop: 20, marginBottom: 24 }}>
            <TableJackSimple
              headerTable={headerArr}
              tbodyRef={tbodyRef}
              bodyComponent={
                <>
                  <ViewportList viewportRef={tbodyRef} items={bodyTable}>
                    {({ row, invalidValue }, index) => (
                      <TransitionTr
                        isQuick
                        counter={index}
                        perPage={20}
                        key={index}
                      >
                        <td
                          key={index}
                          style={{
                            borderRight: "1px solid rgba(230, 230, 232, 1)",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              height: "100%",
                            }}
                          >
                            <GothamRegular
                              style={{
                                color: jackColors.black34,
                                textAlign: "left",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                              className={"font14"}
                            >
                              {invalidValue}
                            </GothamRegular>
                          </div>
                        </td>
                        <td key={index}>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              height: "100%",
                            }}
                          >
                            <GothamRegular
                              style={{
                                color: jackColors.black34,
                                textAlign: "left",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                              className={"font14"}
                            >
                              {row}
                            </GothamRegular>
                          </div>
                        </td>
                      </TransitionTr>
                    )}
                  </ViewportList>
                </>
              }
              thStyle={{
                backgroundColor: jackColors.neutral400,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                height: "100%",
                textAlign: "left",
              }}
              labelStyle={{
                textAlign: "left",
                fontFamily: "GothamMedium",
                color: jackColors.black34,
              }}
              style={{ overflow: "hidden" }}
              noHeaderPadding
              isWithVerticalLines
              classNameLabel="font14"
            />
          </div>
          {detailSecondStepModalLocal.map((element, index) => {
            const isLastIndex = detailSecondStepModalLocal.length - 1 == index;
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: isLastIndex ? 0 : 8,
                }}
              >
                {element}
              </div>
            );
          })}
          <div
            style={{
              borderBottomWidth: 1,
              borderColor: jackColors.greyE6,
              marginBottom: 32,
            }}
          />
          {selectedContent[1] && (
            <div style={{}}>
              <GothamMedium className="font20">
                {selectedContent[1].title}
              </GothamMedium>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                {selectedContent[1].img && (
                  <img
                    src={selectedContent[1].img}
                    height="100%"
                    width="100%"
                    alt="downloadtemplate"
                  />
                )}
              </div>
              {selectedContent[1].desctext}
            </div>
          )}
        </div>
        <div
          className="d-flex justify-content-between"
          style={{
            width: "100%",
            gap: 16,
            padding: 20,
            marginTop: 20,
            borderTop: `1px solid ${jackColors.greyE6}`,
          }}
        >
          <ButtonJack
            style={{ width: "100%" }}
            type="outline"
            onClick={() => toggle()}
          >
            {t(buttonLeftText)}
          </ButtonJack>
          <ButtonJack style={{ width: "100%" }} onClick={() => onClickRight()}>
            {t(buttonRightText)}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};
