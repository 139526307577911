import {
  useForm as useFormReal,
  UseFormProps,
  UseFormReturn,
  FieldValues,
  FieldError,
  Control,
} from "react-hook-form";
import { pickBy } from "lodash";

export type OverrideReturnType<TFieldValues extends FieldValues = FieldValues> = Omit<
  UseFormReturn<any>,
  "watch" | "register"
> & {
  watch: (field: string | string[], defaultValue?: unknown) => any;
  errors: Record<string, FieldError>;
  register: (name: string | { name: string }, options?: any) => any;
  control: Control<TFieldValues, any>;
};

export const useForm = <TFieldValues extends FieldValues = FieldValues>(
  props?: UseFormProps<TFieldValues>
): OverrideReturnType => {
  const defaultVals = useFormReal(props);

  const { formState, register, watch: watchForm } = defaultVals;

  const watch = (arrOrString: string | string[], defaultValue?: unknown) => {
    const isArray = Array.isArray(arrOrString);

    if (isArray) {
      return pickBy(watchForm(), (_, key) => arrOrString.includes(key));
    }

    // @ts-ignore
    return watchForm(arrOrString, defaultValue);
  };

  const registerOverride = (
    props1: string | { name: string },
    props2?: any
  ) => {
    if (typeof props1 === "object") {
      const { name, ...props } = props1;
      // @ts-ignore
      return register(name, props);
    }
    // @ts-ignore
    return register(props1, props2);
  };

  return {
    ...defaultVals,
    watch,
    // @ts-ignore
    errors: formState.errors || {},
    register: registerOverride,
  };
};
