import { formatCurrencyNoDecimal } from "@tools";
import {
  isRetriedBatchDecider,
  retriedTrxCountDecider,
} from "../retry-resubmit/helpers";
import { useTranslation } from "react-i18next";
import { pluralize } from "pageComponents/invoiceComponents/data/formatter";
import { useAlertDecider } from "pageComponents/crossBorderSingleConfirmation";
import { jackColors } from "assets/colors";
import { useState } from "react";
import { ReleaseAccordionHeader } from "./DanamonReleasePayment/arraySummary";
import { PayInvoiceBreakdownDetailsModal } from "./DanamonReleasePayment/logic";
import { idrFormatterNegative } from "pageComponents/invoiceComponents/createPage/modals/formComponents/component";

export const ArrayRejectFunction = ({ transaction, propsTransferService }) => {
  const [showFirstArray, setShowFirstArray] = useState(false);
  const [showSecondArray, setShowSecondArray] = useState(false);

  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { t: tInternational } = useTranslation("international/details");
  const { t: tPayroll } = useTranslation("payroll/create");

  const {
    tax,
    amount,
    is_single_trx,
    local_transactions,
    total_amount,
    payment_channel,
    total_trx_amount,
    batch,
  } = transaction || {};
  const local_transactions_batch = batch?.local_transactions || [];

  // local transfer
  const isRetriedLocal = isRetriedBatchDecider(local_transactions);
  const localRetriedTrxCount = retriedTrxCountDecider(local_transactions);
  const localTrxsCount = isRetriedLocal
    ? localRetriedTrxCount
    : local_transactions?.length;
  const isSingleTransfer = is_single_trx;
  const singleTransferRecipient =
    local_transactions?.[0]?.local_recipient || {};
  const singleTransferRecipientName = singleTransferRecipient?.name || "";
  const singleTransferRecipientBankName =
    singleTransferRecipient?.bank_name || "";
  const singleTransferRecipientAccNumber =
    singleTransferRecipient?.account_number || "";

  // international
  const deliveryMethod = useAlertDecider({ data: payment_channel });

  // payroll
  const isRetriedPayroll = isRetriedBatchDecider(local_transactions_batch);
  const payrollRetriedTrxCount = retriedTrxCountDecider(
    local_transactions_batch
  );
  const payrollTrxsCount = isRetriedPayroll
    ? payrollRetriedTrxCount
    : local_transactions_batch?.length;
  const totalAmountPayroll = batch?.total_amount || 0;

  // pay invoice
  const { feesTaxesBillPayment, itemsBillPayment } =
    PayInvoiceBreakdownDetailsModal({
      transaction,
      showFirst: showFirstArray,
      showSecond: showSecondArray,
    });

  const arrayRejectLocal = [
    {
      spacer: true,
    },
    ...(isSingleTransfer
      ? [
          {
            title: "Recipient",
            value: singleTransferRecipientName,
            value2: `${singleTransferRecipientBankName} - ${singleTransferRecipientAccNumber}`,
          },
        ]
      : []),
    ...(!isSingleTransfer
      ? [
          {
            title: tLocalTransfer("Number of transaction"),
            value: `${localTrxsCount} ${tLocalTransfer(
              pluralize(localTrxsCount, "Transaction")
            )}`,
          },
        ]
      : []),
    {
      title: isSingleTransfer
        ? tLocalTransfer("Amount")
        : tLocalTransfer("Total amount"),
      value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
      rightBold: true,
    },
  ];

  const arrayRejectInternational = [
    {
      spacer: true,
    },
    {
      title: tInternational("Transfer service (international)"),
      value: deliveryMethod,
      styleValue: { color: jackColors.neutral700 },
      styleMainDiv: { gridTemplateColumns: "60% 40%" },
    },
    {
      title: tInternational("Amount to be paid"),
      value: `IDR ${formatCurrencyNoDecimal(total_trx_amount)}`,
      rightBold: true,
    },
  ];

  const arrayRejectPayroll = [
    {
      spacer: true,
    },
    {
      title: tPayroll("Number of transaction"),
      value: `${payrollTrxsCount} ${tPayroll(
        pluralize(payrollTrxsCount, "Transaction")
      )}`,
    },
    {
      title: tPayroll("Total amount"),
      value: `IDR ${formatCurrencyNoDecimal(totalAmountPayroll)}`,
      rightBold: true,
    },
  ];

  const arrayRejectPayInvoice = [
    {
      spacer: true,
    },
    {
      title: (
        <ReleaseAccordionHeader
          onClick={setShowFirstArray}
          show={showFirstArray}
          icon="shopping-cart-outline"
          text="Items subtotal"
        />
      ),
      value: `IDR ${formatCurrencyNoDecimal(+amount)}`,
    },
    ...itemsBillPayment,
    {
      title: (
        <ReleaseAccordionHeader
          onClick={setShowSecondArray}
          show={showSecondArray}
          icon="discount"
          text="Fees & taxes"
        />
      ),
      value: idrFormatterNegative(+tax),
    },
    ...feesTaxesBillPayment,
    {
      spacer: true,
    },
    {
      title: tPayroll("Total amount"),
      value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
      rightBold: true,
    },
  ];

  return {
    arrayRejectLocal,
    arrayRejectPayroll,
    arrayRejectInternational,
    arrayRejectPayInvoice,
  };
};
