import { titleCase } from "change-case";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  containsOnlyAlphaNumeric,
  containsOnlyDigits,
  regexValidateEmail,
} from "../../../components/tools";
import { fetch, useMutation } from "../../../tools/api";
import { isEmailChecker } from "./table/inputs/email/chip";

export const CreateFromBgFunc = ({ idBgProcess, setIdBgProcess }) => {
  const { push } = useRouter();
  const [isError, setIsError] = useState(false);

  const { mutation: createFromBackground } = useMutation({
    url: "local_transaction_batches/request_background_create",
    method: "post",
    afterSuccess: (res) => {
      const { id } = res?.data?.data || {};
      setIdBgProcess(id);
    },
  });

  const {
    data: dataInterval,
    refetch: intervalChecker,
    loading: loadingInterval,
  } = fetch({
    url: `/local_transaction_batches/${idBgProcess}/show_background_progress`,
    woInit: true,
  });

  const { queued_at, error_message } = dataInterval?.batch || {};
  const isDoneCreateFromBg = queued_at === null; // Flagging from BE
  const isErrorCreate = !!error_message;

  useEffect(() => {
    if (isDoneCreateFromBg) {
      push(`/local-transfer/create/${idBgProcess}?state=validating`);
      setIdBgProcess(false);
    }
  }, [isDoneCreateFromBg]);

  useEffect(() => {
    if (!isDoneCreateFromBg && isErrorCreate) {
      setIsError(true);
    }
  }, [isDoneCreateFromBg, error_message]);

  useEffect(() => {
    if (idBgProcess && !loadingInterval) {
      setTimeout(() => {
        intervalChecker();
      }, 5000);
      // if (idBgProcess && !loadingInterval) {
      // Interval hit for loop checking
      // intervalChecker();
      // const intervalId = setInterval(() => {
      //   intervalChecker();
      // }, 5000);
      // return () => {
      //   clearInterval(intervalId);
      // };
      // }
    }
  }, [idBgProcess, loadingInterval]);

  return { createFromBackground, isError };
};

export const FormatterCreateFromBackground = ({
  name,
  category_id,
  data: dataRaw,
}) => {
  const mapData = dataRaw?.map((item) => {
    const { recipient_email, email, ...rest } = item || {};

    // create array email
    const emailString = email + "";
    const arrayEmail = emailString?.includes(",")
      ? emailString?.split(",")
      : [email];

    // eleminated all email that not pass regex
    const filteredEmail = arrayEmail?.filter((email) => isEmailChecker(email));
    const arrayEmailValid = filteredEmail?.length;

    // restructure email payload
    const primaryEmail = arrayEmailValid ? filteredEmail[0] : null;
    const additionalEmails =
      arrayEmailValid > 1 ? filteredEmail.slice(1).join(",") : null;

    return {
      email: primaryEmail,
      recipient_email: primaryEmail,
      additional_emails: additionalEmails,
      ...rest,
    };
  });

  const mutationData = { name, data: mapData };

  const hasCategory = !!category_id;
  if (hasCategory) mutationData.category_id = category_id;

  return mutationData;
};

export const CheckerValidation = ({ data: dataRaw, tLocalTransfer }) => {
  let counter = 0;
  let errorResult = [];

  const specialChecker = (item) => {
    const isBCA = item?.bank?.toString()?.includes("bca");
    if (isBCA) {
      return (
        item["account_number"] == null ||
        (containsOnlyDigits(item["account_number"].toString()) &&
          item["account_number"]?.toString()?.length >= 10)
      );
    } else {
      return (
        item["account_number"] == null ||
        containsOnlyDigits(item["account_number"].toString())
      );
    }
  };

  for (const [index, el] of dataRaw.entries()) {
    const isValidAccNumber = specialChecker(el);
    const isValidAmount =
      el["amount"] == null || containsOnlyDigits(el["amount"]);
    const isValidTransferDestination = !el["bank"] || el["payer_id"] !== null;
    const isNameOnlyNumber = el["name"]?.trim()
      ? containsOnlyAlphaNumeric(el["name"].trim())
      : false;
    const isValidEmail =
      el["email"] == null ||
      el["email"].trim() === "" ||
      regexValidateEmail(el["email"]);

    let invalidValues = [];
    if (!isValidAccNumber)
      invalidValues.push(titleCase(tLocalTransfer("account_number")));
    if (!isValidAmount) invalidValues.push(tLocalTransfer(titleCase("amount")));
    if (isNameOnlyNumber) invalidValues.push(tLocalTransfer(titleCase("name")));
    if (!isValidTransferDestination)
      invalidValues.push(tLocalTransfer(titleCase("transfer_destination")));
    if (!isValidEmail) invalidValues.push(tLocalTransfer(titleCase("Email")));

    if (invalidValues.length > 0) {
      invalidValues.forEach((invalidValue) => {
        // Find the existing error for this invalid value
        const existingError = errorResult.find(
          (item) => item.invalidValue === invalidValue
        );

        if (existingError) {
          // If error already exists, append the current row
          existingError.row += `, ${el["row"].toString()}`;
        } else {
          // If error doesn't exist, create a new entry
          errorResult.push({
            invalidValue: invalidValue,
            row: el["row"].toString(),
          });
        }
      });
      counter += 1;
    }
  }

  const isNotPass = counter > 0;
  return { isNotPass, errorResult };
};
